import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../elements/Button';
import Flex from '../../elements/Flex';
import { IEntityTable } from '../../hooks/useEntity';
import { useModal } from '../../hooks/useModal';
import Table from '../../libraries/table';
import UserRow from './components/UserRow';

const Container = styled.div`

    padding: 20px 60px;

`;


const UserGrid = ({ Entity }: { Entity: IEntityTable }) => {


    const modal = useModal();
    const navigate = useNavigate();


    const [searchParams] = useSearchParams();

    const get = async () => {
        if (Entity)
            Entity.GetMany();
    }

    const create = async () => {
        navigate("novo");
    }

    useEffect(() => {
        console.log("get");
        if (Entity.data.length == 0)
            get();
    }, [Entity])


    return <Container>

        <Flex style={{ padding: "20px 0px ", gap: 10 }}>
            <Button title="Novo Serviço" onClick={create} />
            <Button title="Atualizar" onClick={get} />
        </Flex>

        <Table
            data={Entity?.data.map((ent: any) => ent.data)}
            renderRow={UserRow}
            columns={[]}
            definition={[
                {
                    key: "name",
                    name: "Nome",
                    width: 7,
                    type: "text"
                    // format: "string"
                },
                {
                    key: "email",
                    name: "e-Mail",
                    width: 7,
                    type: "text",
                    // format: "email"
                }
            ]}
            actions={[

            ]}
        />


    </Container>;

}

export default UserGrid;