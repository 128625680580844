
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../elements/Icon';
import Text from '../elements/Text';
import { useAuth } from '../hooks/useAuth';
import PeopleCard from './PeopleCard';
import UserPicture from './user/UserPicture';

const Container = styled.div`

    border: 1px solid ${props => props.theme.borderTransparent};
    border-bottom: 3px solid ${attr => attr.theme.borderTransparent};
    /* box-shadow: 0px 0px 20px 0px ${attr => attr.theme.shadow}; */
    border-radius: 6px;
    padding: 8px  12px ;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    background-color: ${attr => attr.theme.backgroundPrimary};
    cursor: pointer;
    
    > .sender{
        flex: 2;
        display: flex;
        align-items: center;
        gap: 6px;
        

        > .details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0;
        }
    }

    > .to{
        flex: 2;
        display: flex;
        align-items: center;
        gap: 6px;
        

        > .details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0;
        }
    }

    > .content {
        flex: 5;
        display: flex;
        align-items: center;
        gap: 6px;

        > .mark {
            padding: 2px 6px;
            border-radius: 4px;
            background-color: ${attr => attr.theme.orange100};
        }

        > .separator{
            width: 12px;
            height: 2px;
            flex-shrink: 0;
            background-color: ${attr => attr.theme.contentSecondary};
        }

        > .receivers{
            display: flex;
            
            .receiver{
                width: 36px;
                position: relative;
                height: 36px;
                border-radius: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: ${attr => attr.theme.backgroundPrimary};

                &.team {
                    background-color: ${attr => attr.theme.purple100};
                    border: 4px solid   ${attr => attr.theme.backgroundPrimary};
                }

                &:not(:first-child){
                    margin-left: -14px;
                }
            }
        }
    }

    &:hover, &:hover > .content > .receivers > .receiver {
        background-color: ${attr => attr.theme.title100};
    }

`;

interface MailCard {
    data: {
        id: string
        code: string
        createdAt: Date
        title: string
        content: Object
        sender: any
        receivers: any[]
        parentId?: string
        _count: { [key: string]: number }
    }
}


function removerTagsHTML(content: any = []) {
    if (!content)
        return ""
    return content.reduce((str: string, c: any) => c.content ? removerTagsHTML(c.content) + str : str + (c.text || (c.attrs?.label ? "@" + c.attrs?.label : undefined) || ""), "")
}
const MailCard = ({
    data: { sender, parentId, id, receivers, title, content, _count }
}: MailCard) => {

    const navigate = useNavigate();
    const auth = useAuth();

    const to = receivers?.length > 0 ? receivers[0]?.user : undefined;

    return <Container onClick={() => navigate("/documentos/" + id)}>

        {!!sender && <PeopleCard data={sender} />}

        {!!to && !sender && <div className='to'>
            {/* <Icon name="send" size={18} /> */}
            <UserPicture data={to} />
            <div className='details'>
                <Text bold>Para: {to.name}</Text>
                {/* <Text lineHeight={0.8} fontSize={14} color={"contentSecondary"}>{_count.replies} Respostas</Text> */}
            </div>
        </div>}
        {!!parentId && <div className='sender'>
            <Icon name="reply" size={18} />
            <UserPicture data={auth.user} />
            <div className='details'>
                <Text bold>Para: {auth.user.name}</Text>
                <Text lineHeight={0.8} fontSize={14} color={"contentSecondary"}>Resposta</Text>
            </div>
        </div>}
        <div className='content'>
            <div className='mark' ><Text fontSize={12} color="orange400" bold>NOVO</Text></div>
            <div className='receivers'>
                {receivers.map(({ user, team }) => {

                    if (team)
                        return <div className='receiver team'>

                            <Icon color={"purple400"} name="serves3" size={20} />

                        </div>

                    else if (user) return (<div className='receiver'>
                        <UserPicture data={user} />
                    </div>)
                })}
            </div>
            <div className='title'>
                <Text nowrap fontSize={14} bold>{title}</Text>
            </div>
            <div className='separator' />
            <div className='content'>
                <Text numberOfLines={1} fontSize={14} color={"contentSecondary"}>{removerTagsHTML(content)}</Text>
            </div>
        </div>
    </Container>;
}

export default MailCard;