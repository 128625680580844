import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TextViewer } from '../../components/TextEditor';
import Button from '../../elements/Button';
import Flex from '../../elements/Flex';
import Heading from '../../elements/Heading';
import Icon from '../../elements/Icon';
import Loading from '../../elements/Loading';
import Text from '../../elements/Text';
import { formatTimeWithExtension } from '../../services/functions';
import DocumentAside from './components/Aside';
import DiscussionList from './components/DiscussionList';
import { useDocumentEntity } from './hooks/useDocumentEntity';

const Container = styled.div`

    padding: 20px 0;


    >header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;

        > .left, .right{
            align-items: center;
            display: flex;
            gap: 6px;
        }
        > .left{
            align-items: flex-start;
            flex-direction: column;
            gap: 0px;
        }
    }

    > main {
        position: relative;
        display: flex;
        gap: 20px;

        > section {

            flex: 2;

            > .header{
                display: inline-flex;
                align-items: center;
                gap: 6px;
                padding: 10px 0;
            }
            > .document {

                padding: 20px;
                border-radius: 8px;
                border: 1px solid ${props => props.theme.borderTransparent};
                box-shadow: 0px 0px 30px 0px #0001;
                min-height: 200px;
                background-color: ${props => props.theme.backgroundPrimary};
            }
        }

      
    }
`;




const DocumentViewPage = () => {

    const ref = useRef<any>(null);
    const { id } = useParams();

    const navigate = useNavigate();
    const entity = useDocumentEntity(id);

    if (entity.loading)
        return <Loading />

    if (!entity.data)
        return <></>;


    const data = entity.data;

    return <Container>
        <header>
            <div className='left'>
                {/* <Status status={data.status} /> */}
                <Heading size={2}>{data.title}</Heading>
                <Flex alignItems="center" gap={4}>
                    <Text fontSize={14} bold nowrap>{data.sender.name}</Text>
                    <Text fontSize={14} nowrap>Solicitou este documento em</Text>
                    <Text fontSize={14} nowrap>{formatTimeWithExtension(data.createdAt)}</Text>
                </Flex>
            </div>
            <div className='right'>
                <Button title="Encaminhar" icon="forward" onClick={entity.actions.btnForwardOnClick} />
                {/* <Button title="Responder" icon="backward" onClick={handleComment} /> */}
                <Button color="red100" title="Cancelar" icon="close" onClick={entity.actions.btnCancelOnClick} />
                <Button color="green100" title="Encerrar" icon="check" onClick={entity.actions.btnCancelOnClick} />
                <Button title="Arquivar" icon="archive" />
                <Button title="Editar" onClick={() => navigate("/documentos/e/" + id)} />
                <div onClick={entity.actions.btnFavoriteOnClick}>
                    <Icon name="star" color={entity.data.favorite ? "yellow400" : "contentTertiary"} />
                </div>
            </div>
        </header>
        <main>
            <section>

                <div className='document'>
                    <TextViewer content={data.content} />
                    {/* <ul>
                        {data.steps.map((step: any) => {
                            return <li>{step.step?.name}</li>
                        })}
                    </ul> */}
                </div>

                <DiscussionList discussions={data.discussions} actions={{
                    btnCommentOnClick: entity.actions.btnCommentOnClick
                }} />

            </section>

            <DocumentAside
                // sender={data.sender}
                // receivers={data.receivers}
                // labels={data.labels}
                // actions={{ lstLabelsOnInput: entity.actions.lstLabelsOnInput }}
                {...entity.data}
            />
        </main>
    </Container>;
}



export default DocumentViewPage;