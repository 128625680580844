import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../config';
import Button from '../elements/Button';
import Flex from '../elements/Flex';
import Separator from '../elements/Separator';
import Text from '../elements/Text';
import { useApi, useAuthDispatch } from '../hooks/useAuth';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${p => p.theme.backgroundTertiary};

    display: flex;
    flex-direction: column;
    padding: 50px;

    header,footer {
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    section {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        cursor: pointer;

        .picture {
            width: 64px;
            height: 64px;
            border-radius: 32px;
            background-color: ${colors.blue400};
            transition: 200ms;
        }
        .description {
            overflow: hidden;
            height:20px;
            margin-top: -10px;
            transition: 50ms;
            max-width:0px;

            & > div {
                transform: translateY(-20px);
                transition: 200ms;
                transition-delay: 50ms;
            }
        }

        &:hover{
            .picture {
                width: 92px;
                height: 92px;
                border-radius: 46px;
            }
            .description {
                max-width:100%;
                & > div {
                    transform: translateY(0px);
                }
            }
        }
    }

    input {
        width: 100%;
        padding: 10px;
        border-radius: 6px;
        background-color: ${p => p.theme.backgroundPrimary};
        border: 1px solid ${p => p.theme.borderOpaque};
        outline: none;

        &:focus{
            border: 1px solid ${p => p.theme.backgroundPrimary};
            outline: 1px solid ${p => p.theme.blue400};
        }
    }
    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    /* .box {
       
        display: flex;
        gap: 8px;
        padding: 16px;
        border-radius: 6px;
        background-color: ${props => props.theme.backgroundPrimary};
        flex-direction: column;
        box-shadow: 0 1px 4px 0 rgba(0, 8, 38, 0.05);
        border: 1px solid transparent;
        transition: 200ms;
        border: 1px solid ${p => p.theme.borderOpaque};

        padding: 24px;
        position: relative;
        min-width: 300px;
        min-height: 50px;
    } */

    .gov{
        cursor: pointer;
        background-color: ${p => p.theme.blue400};
        align-items: center;
        justify-content: center;
        display: flex;
        gap: 4px;
        padding: 6px 12px;
        border-radius: 4px;
    }
`;


const LoginPage = ({ modal }: any) => {

    const auth = useAuthDispatch();

    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const api = useApi();
    const navigate = useNavigate();

    const userInput = (e: React.FormEvent<HTMLInputElement>) => {
        setUser(e.currentTarget.value)
    }
    const passInput = (e: React.FormEvent<HTMLInputElement>) => {
        setPass(e.currentTarget.value)
    }

    const handleLoginGov = async () => {
        const { data } = await api.get("/auth/govbr_url");
        alert(data);
        window.location.replace(data);
    }

    const submit = async () => {
        try {
            await auth.login(user, pass);
            modal.close();
        }
        catch {
            alert("Algo deu errado!");
        }


    }

    return <Container>
        <header>

        </header>
        <section>
            <form >
                <Flex direction="vertical" alignItems="center">
                    <img style={{
                        height: 42
                    }} src={"http://localhost:3000/dbseti.png"} />
                    {/* <Text font="Barlow" children="Sistema para prefeituras" color={colors.gray900} fontSize={18} bold /> */}
                </Flex>
                <input onInput={e => userInput(e)} value={user} placeholder='CPF' />
                <input onInput={e => passInput(e)} value={pass} type='password' placeholder='Senha' />
                <Button title="Entrar" color={colors.blue400} align="center" onClick={submit} />
                <Separator />

                <button className='gov' onClick={handleLoginGov}>
                    <Text font="Barlow" color={"backgroundPrimary"} fontSize={16} bold >
                        Entrar com
                    </Text>
                    <img style={{
                        marginBottom: -2,
                        height: 24
                    }} src={"https://d3tvvdk4ie5nmt.cloudfront.net/includes/images/gov.br_logo2.png"} />
                </button>

            </form>



        </section>
        <footer>
            <Flex direction="vertical" alignItems="center">
                <Text font="Barlow" children="Desenvolvido por DBSistemas ©" color={colors.gray500} fontSize={16} bold />
                <Text font="Barlow" children="Criptografia e segurança de ponta-a-ponta" color={colors.gray500} fontSize={16} bold />
            </Flex>
        </footer>
    </Container>;
}

export default LoginPage;