import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { colors } from '../../config';
import Heading from '../../elements/Heading';
import Icon, { iconNames } from '../../elements/Icon';
import Text from '../../elements/Text';
import { useAuth } from '../../hooks/useAuth';
import { getContrast2 } from '../../services/functions';
import AppearancePage from './appearance';


const Container = styled.div<any>`

position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;
    display: flex;
    background-color: ${p => p.theme.backgroundPrimary};

    .close {
        width: 42px;
        height: 42px;
        border-radius: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid ${p => p.theme.borderTransparent}; 
        border-bottom: 3px solid ${p => p.theme.borderTransparent}; 
        background-color: ${p => p.theme.backgroundPrimary};
        cursor: pointer;
        position: fixed;
        top: 16px;
        left: 16px;
        z-index: 1000;

        &:hover{
            background-color: ${p => p.theme.backgroundTertiary};
        }
    }

    ul {
        margin-top: 12px;
        li {
            cursor: pointer;
            list-style: none;
            padding: 6px;
            border-radius: 6px;
            /* background-color: ${p => p.theme.backgroundSecondary}; */
            display: flex;
            align-items: center;
            gap: 6px;
            .icon {
                width: 24px;
                height: 24px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.selected {
                background-color: ${p => p.theme.gray300}; 
            }
        }
    }

    .search {
        background-color: ${p => p.theme.backgroundPrimary};
        display: flex;
        align-items: center;
       
        border-radius: 6px;

        padding-left: 6px;
        input {
            padding: 6px;
            outline: none;
        }
    }

    .sidebar{
        display: flex;
        flex: 1 0 218px;
        z-index: 1;
        justify-content: flex-end;

        > .sidebar-scroller {
            overflow: hidden scroll;
            padding-right: 0px;

            flex: 1 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
            background: ${p => p.theme.backgroundTertiary};

            > nav {
                padding: 16px;
            }

            /* width */
            /* &::-webkit-scrollbar {
            width: 10px;
            } */

            /* Track */
            /* &::-webkit-scrollbar-track {
            background: #ff4;
            } */

            /* Handle */
            /* &::-webkit-scrollbar-thumb {
            background: #888;
            } */

            /* Handle on hover */
            /* &::-webkit-scrollbar-thumb:hover {
            background: #555;
            } */

        }
    }

    .content {
        position: relative;
        display: flex;
        flex: 1 1 800px;
        align-items: flex-start;
        background: ${p => p.theme.backgroundPrimary};

        > .content-wrapper {
            flex: 1;
            height: 100%;

            >.content-scroller {
                overflow: hidden scroll;
              
                padding-right: 0px;
                justify-content: flex-start;
                height: 100%;
                position: static;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                overflow-x: hidden;
                background-color: ${p => p.theme.backgroundPrimary};

                > section {
                    max-width: 740px;
                    width: 100%;
                }
            }
        }
    }

`;


const Empty = () => {
    return <div></div>
}

interface IPage {
    primary: boolean,
    parent?: string,
    group: string,
    title: string,
    name: string,
    icon: iconNames,
    color: keyof typeof colors,
    page: any
}

const pages: IPage[] = [
    {
        primary: true,
        group: "profile",
        title: "Perfil",
        name: "profile",
        icon: "user",
        color: "blue400",
        page: <>Profile</>
    },
    {
        primary: true,
        group: "apparence",
        title: "Aparencia",
        name: "apparence",
        icon: "edit",
        color: "orange400",
        page: <AppearancePage />
    },
]

const SettingsPage: React.FC = () => {

    const [routes, setRoutes] = useState<string[]>(["profile"]);
    const [search, setSearch] = useState<string>("");
    const navigate = useNavigate();
    const theme = useTheme();

    const auth = useAuth();

    const currentPage = routes[routes.length - 1];
    const page = pages.find(p => p.name == currentPage);

    const navList = search ? pages.filter(p => p.title.toLowerCase().includes(search.toLowerCase())) : pages.filter(p => Boolean(p.primary));

    return <Container>

        <div className='close' onClick={() => navigate(-1)}>
            <Icon size={18} color="contentInverseTertiary" name="close" />
        </div>

        <div className='sidebar'>
            <div className='sidebar-scroller'>
                <nav>
                    <Heading>Configurações</Heading>
                    <div className='search'>
                        <Icon size={18} color="contentInverseTertiary" name="search" />
                        <input placeholder='pesquisar' value={search} onInput={e => setSearch(e.currentTarget.value)} />
                    </div>
                    <ul>
                        {
                            navList.map(p => (
                                <li onClick={() => setRoutes([p.name])} className={currentPage == p.name ? "selected" : ""}>
                                    <div className='icon' style={{ backgroundColor: theme[p.color] }}>
                                        <Icon
                                            size={18}
                                            color={getContrast2(theme[p.color])}
                                            name={p.icon || "bolt"}
                                        /></div>
                                    <Text bold>{p.title}</Text>
                                </li>
                            ))
                        }
                    </ul>
                </nav>
            </div>
        </div>
        <div className='content'>
            <div className='content-wrapper'>
                <div className='content-scroller'>
                    <section>
                        {page?.page}
                    </section>
                </div>
            </div>
        </div>


    </Container>;

}

export default SettingsPage;