import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../hooks/useAuth';
import UseEntityProvider from '../hooks/useEntity';
import UseModalProvider from '../hooks/useModal';
import AdminPage from '../pages/Admin';
import ForbiddenPage from '../pages/Forbidden';
import GovbrPage from '../pages/Govbr';
import IndexPage from '../pages/Index';
import LoginPage from '../pages/Login';
import NotFoundPage from '../pages/NotFound';
import StartingPage from '../pages/Starting';
import TaskSchedulePage from '../pages/TaskSchedule';
import DocumentPage from '../pages/documents';
import ExampleEditorPage from '../pages/examples/Editor';
import LabelMainPage from '../pages/labels';
import RolePage from '../pages/roles';
import ServicesPage from '../pages/services';
import SettingsPage from '../pages/settings';
import SolutionPage from '../pages/solutions';
import TeamPage from '../pages/teams';
import UserPage from '../pages/users';
import ProfilePage from '../pages/users/profile';
import LoggedRoutes from './logged.routes';
// import NavigatorMobile from '../components/Navigator.mobile';


const Container = styled.div`

    display: flex;
    overflow: hidden;
    flex-direction: column;
    height: 100%;



    > .container {

        flex: 1;
        width: 100%;
        display: flex;
        overflow: hidden;

        > .pages {

            flex: 1;
            display: flex;
            overflow: hidden;
            flex-direction: column;
            height: 100%;
         

            > .routes {
                flex: 1;
                display: flex;
          
                width: 100%;
                justify-content: center;
                align-items: flex-start;
                overflow-y: auto;

        
                > div {
                    padding: 60px 40px;
                    width: 100%;

                }
                
            }
        }
    }

`;


const Empty = () => {
    return <div></div>
}

const LoggedRoutesController = ({ children }: any) => {

    return <UseEntityProvider>
        <UseModalProvider>
            <Outlet />
        </UseModalProvider>
    </UseEntityProvider>

}


const StackRoutes: React.FC = () => {

    const auth = useAuth();

    if (auth.status == "initializing")
        return <StartingPage />

    return <Container>

        <Routes>

            <Route path="*" element={<NotFoundPage />} />
            <Route path="/sem-permissao" element={<ForbiddenPage />} />
            <Route path="/apis" element={<Empty />} />
            <Route path="/exemplos/editor" element={<ExampleEditorPage />} />

            <Route path="/login" element={<LoginPage />} />

            <Route path="/govbr" element={<GovbrPage />} />

            {/* QUALQUER ROTA SEM LOGIN */}

            <Route path="/" element={<LoggedRoutesController />} >

                {/* QUALQUER ROTA COM LOGIN SEM HEADER NEM FOOTER */}

                <Route path="/" element={<LoggedRoutes />} >

                    <Route index element={<IndexPage />} />

                    <Route path="solucoes/*" element={<SolutionPage />} />
                    <Route path="documentos/*" element={<DocumentPage />} />

                    <Route path="configuracoes" element={<SettingsPage />} />

                    <Route path='admin/*' element={<Outlet />} >

                        <Route index element={<AdminPage />} />

                        <Route path="tarefas" element={<TaskSchedulePage />} />
                        <Route path="setores/*" element={<TeamPage />} />
                        <Route path="cargos/*" element={<RolePage />} />
                        <Route path="servicos/*" element={<ServicesPage />} />
                        <Route path="usuarios/*" element={<UserPage />} />
                        <Route path="marcadores/*" element={<LabelMainPage />} />

                    </Route>

                    <Route path="perfil/*" element={<ProfilePage />} />


                </Route>

                <Route path="/configuracoes" element={<SettingsPage />} >

                </Route>


            </Route>

        </Routes>

    </Container>;

}

export default StackRoutes;