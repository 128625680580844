import styled from 'styled-components';
import { colors } from '../../config';
import { formatColor, getContrast } from '../../services/functions';
import Icon from '../Icon';
import Text from '../Text';

const Container = styled.div`

    /* height:36px;
    display: flex;
    align-items: center;
    background-color: ${colors.background};
    padding-left: 10px ; */
    display: flex;
    align-items: center;
    
    flex: 1;
    width: 100%;
    background-color: ${colors.gray100};
    height: 33px; 
    /* border-bottom: 1px solid ${colors.gray100}; */
    outline: none;
    /* border-width: 1px;
    border-style: solid;
    border-color: ${colors.gray300}; */
    transition:200ms;
    box-shadow: 0px 0px 12px 0px ${colors.gray200}99;
    padding-left: 6px;
    gap: 6px;

    &:first-child {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }
    &:last-child {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .icon{
        /* padding: 3px; */
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background-color: ${colors.orange400};
        border-radius: 6px;
    }
    .details {
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        justify-content: space-between;
        padding-right: 10px;

        
    }
 

    .input {
        position: relative; border-radius: 44px; 
    }
    .subinput {
        display: flex; flex-shrink: 0; height: 14px; width: 26px; border-radius: 44px; padding: 2px; box-sizing: content-box;  transition: background 200ms ease 0s, box-shadow 200ms ease 0s;
    }
    .cursor {
        width: 14px;
        height: 14px; 
        border-radius: 44px; 
        background: white; 
        transition: transform 200ms ease-out 0s, background 200ms ease-out 0s;
    }
    input {
        position: absolute; opacity: 0; width: 100%; height: 100%; top: 0px; left: 0px; cursor: pointer;
    }
`;

const BooleanInput = ({ title, index = 0, required, color, password, icon, name, placeholder, showLimit = false, clearErrors, readonly = false, value = "", description, max, onInput, error = false, rows = 1, padding = 5, leftIcon, rightIcon }: any) => {

    return <Container>

        {!!icon && <div className='icon' style={{ backgroundColor: formatColor(color) }}>
            {icon === "firstLetter" ?
                <Text color={getContrast(formatColor(color))} children={title.substring(0, 1)} fontSize={14} bold />
                : <Icon size={18} color={getContrast(formatColor(color))} name={icon} />}

        </div>}
        <div className='details' style={{
            borderTop: index > 0 ? `1px solid ${colors.gray200}` : 0
        }}>
            <Text fontSize={16} children={title} />
            <div className="input">
                <div className='subinput' style={{
                    backgroundColor: !!value ? colors.blue400 : colors.gray400
                }}>
                    <div className="cursor" style={{
                        transform: `translateX(${!!value ? "12px" : "0px"}) translateY(0px)`
                    }}></div>
                </div>
                <input value={value} onInput={(e) => {
                    onInput(Boolean(e.currentTarget.checked))
                }} type="checkbox" role="switch" tabIndex={-1} />
            </div>
        </div>
    </Container>
}


export default BooleanInput