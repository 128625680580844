import styled from 'styled-components';
import Button2 from '../../../elements/Button2';
import Flex from '../../../elements/Flex';
import ModalProvider from '../../../elements/ModalProvider';
import TextInput from '../../../elements/inputs/TextInput';
import { useApi } from '../../../hooks/useAuth';
import { useModal } from '../../../hooks/useModal';
import { useStateForm } from '../../../hooks/useStateForm';

const Container = styled.div`

   

`;


const CreateTeamModal = ({ callback = () => { } }: any) => {

    const { close } = useModal();
    const form = useStateForm();
    const api = useApi();

    const submit = async () => {
        const res = await api.post("/teams", {
            title: form.getFields().title
        })
        callback(res.data);
        close();
    }

    return <ModalProvider
        maxWidth={500}
        icon='serves3'
        title='Criar equipe'
        description='Reúna todos com uma equipe à qual você pode @mencionar, filtrar e atribuir trabalhos.'
    >
        <Container>


            <TextInput title="Nome da equipe" {...form.registerField("title")} />

            {/* <TextInput title="Membros da equipe" {...form.registerField("members")} /> */}

            <Flex justifyContent='space-between'>
                <div></div>
                <Button2 onClick={submit} title="Criar equipe" />
            </Flex>

        </Container>
    </ModalProvider>;

}

export default CreateTeamModal;