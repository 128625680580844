import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import ConnectionGrid from '../../components/ConnectionGrid';
import Button from '../../elements/Button';
import TabControl from '../../elements/TabControl';
import TextInput from '../../elements/inputs/TextInput';
import { IEntityTable } from '../../hooks/useEntity';
import { useForm } from '../../hooks/useForm';

const Container = styled.div`

    padding: 20px;

`;

class Teste {
    valor: string = "teste"
    constructor() { }
}


const UserForm = ({ EntityTable }: { EntityTable: IEntityTable }) => {

    const [state, setState] = useState<Teste>(new Teste())

    const { id } = useParams();
    const Entity = id && EntityTable.GetEntity(id);
    const form = useForm();
    const navigate = useNavigate();
    const theme = useTheme()

    console.log(Entity);

    // const initialize = async () => {
    //     console.log("initializing");
    //     if (id && id == "novo") {
    //         Entity.Create({});
    //         form.setInitialData(Entity.data);
    //     }
    //     else if (id) {
    //         await Entity.Get();
    //         form.setInitialData(Entity.data);
    //     }
    //     // else {
    //     //     Entity.Create();
    //     // }
    //     // await Entity.Get();
    //     // form.setInitialData(Entity.data)
    // }

    const submit = async () => {
        if (!Entity)
            return;

        await Entity.Update(form.getFields()).Save();
        navigate("../")
        EntityTable.Refresh();
    }


    useEffect(() => {
        if (id && id == "novo")
            EntityTable.Create();


        // initialize();
    }, [id])

    // useEffect(() => {
    //     if (Entity)
    //         Entity.Update(form.getFields())
    // }, [form])

    useEffect(() => {
        if (Entity)
            form.setInitialData(Entity.data)
        // initialize();
    }, [Entity])



    return <Container>

        {state.valor}

        <TabControl

            tabs={[
                {
                    name: 'user',
                    title: "Usuário",
                    component: <>

                        <TextInput title="Nome" {...form.registerField("name")} />
                        <TextInput title="email" {...form.registerField("email")} />
                        <TextInput title="password" {...form.registerField("password")} />


                    </>
                },
                {
                    name: 'roles',
                    count: form.fields.roles?.length,
                    title: "Cargos",
                    component: <>
                        <ConnectionGrid
                            url="/roles"
                            keyPath='roleId'
                            keyObject='role'
                            labelKey='name'
                            {...form.registerField("roles")}
                        />
                    </>
                }
            ]}

        />

        <Button title="Salvar" onClick={submit} />

    </Container>;

}

export default UserForm;