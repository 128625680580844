
import {
    forwardRef, useEffect, useImperativeHandle,
    useState,
} from 'react';
import { useApi } from '../hooks/useAuth';

import styled from 'styled-components';
import { colors } from '../config';
import Flex from '../elements/Flex';
import Icon from '../elements/Icon';
import Text from '../elements/Text';

const Container = styled.div`

    border: 1px solid ${colors.gray200};
    border-radius: 4px;
    background-color: ${colors.background};
    box-shadow: 0px 0px 15px 0px #0001;
    padding: 12px;
    > .items {
        > button {
            height: 24px;
            padding: 0px 6px;
            background-color: ${colors.background};
            cursor: pointer;

            &:hover{
                background-color: ${colors.gray100};
            }
        }
    }
`;


export default forwardRef((props: any, ref: any) => {
    const [selectedIndex, setSelectedIndex] = useState(0);




    const [users, setUsers] = useState<any[]>([]);
    const api = useApi();

    const get = async () => {
        const res = await api.get("/autocomplete/users", {
            params: {
                q: props.search || ""
            }
        });
        setUsers(res.data.map((user: any) => ({ id: user.id, text: user.name, target: user.target })))
    }



    const selectItem = (index: any) => {
        const item = users[index];

        if (item) {
            props.command({ label: item.text, target: item.target, id: item.id })
        }
    }

    const upHandler = () => {
        setSelectedIndex((selectedIndex + users.length - 1) % users.length)
    }

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % users.length)
    }

    const enterHandler = () => {
        selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [users])
    useEffect(() => { get() }, [props.search])

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }: any) => {
            if (event.key === 'ArrowUp') {
                upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                downHandler()
                return true
            }

            if (event.key === 'Enter') {
                enterHandler()
                return true
            }

            return false
        },
    }))

    const disabledList = props.disabledList || []
    console.log(disabledList)

    return (
        <Container>
            <Flex style={{ padding: 6 }} gap={2} alignItems="center">
                <Icon name="serves2" size={20} />
                <Text fontSize={14}>Mencionar: </Text>
            </Flex>
            <div className="items" style={{ display: 'flex', flexDirection: "column" }}>
                {users.length
                    ? users.filter(item => !disabledList.find((i: any) => item.id == i.id)).map((item: any, index: any) => (
                        <button
                            className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
                            key={index}
                            onClick={() => selectItem(index)}
                        >
                            <Text fontSize={14} bold align='center'>{item.text}</Text>

                        </button>
                    ))
                    : <div className="item">Não encontrado</div>
                }
            </div>
        </Container>
    )
})