import styled from 'styled-components';
import { colors } from '../../config';
import Text from '../Text';

const Container = styled.div`
    position: relative;
    margin: 4px 0;
    @keyframes borderEffect {
        0% {
           // border-width: 0px;
           box-shadow: 0px 0px 12px 0px ${colors.blue400}55;
    
        }
        50% {
            //border-width: 2px;
            box-shadow: 0px 0px 0px 0px ${colors.blue400}ff;
        }
        0% {
           // border-width: 0px;
           box-shadow: 0px 0px 12px 0px ${colors.blue400}55;
        }
    }
    
    flex: 1;
    width: 100%;
    
   .content {
       
    }

    .overlay {
        /* z-index: 2; */
        /* margin-bottom: -8px; */
        /* margin-left: 3px; */
        //height: 16px;
        /* left: 6px; */
       // position: absolute;
       padding: 4px 0;
        display: flex;
        align-items: center;
        /* padding: 6px 0px; */
    }

    .tip {
        display: inline-flex;
        align-items: center;
        gap: 3px;
        padding: 2px 6px;
        border-radius: 3px;
        background-color: ${colors.pink100};
        margin-left: 5px;
    }
    .title {
     
        display: inline-flex;
        padding: 0px 3px;
        border-radius: 3px;
        background-color: ${colors.background} !important;
    }
    .description {
        padding: 2px 3px;
    }
`;

const Input = ({ title, required, children, description }: any) => {

    return <Container>

        <div className='overlay'>

            {!!title && <div className="title" >
                <Text fontSize={14} children={title} color={colors.gray600} bold font="Barlow" />
            </div>}

            {required && <div className="required" >
                <Text fontSize={11} children={"(Obrigatório)"} color={colors.gray400} bold font="Barlow" />
            </div>}

        </div>

        <div className='content'>

            {children}

        </div>

        {!!description && <div className='description'>
            <Text fontSize={14} children={description} color={colors.gray500} font="Barlow" />
        </div>}

    </Container>
}


export default Input