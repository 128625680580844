import { useEffect, useRef, useState } from "react";
import { useApi } from "../../../hooks/useAuth";
import { useModal } from "../../../hooks/useModal";
import ForwardPage from "../pages/Forward";

type IDocument = {
    id: string
    favorite: Boolean
} & any

type IUseDocumentEntity<T> = {
    toggleFavorite: () => void;
}

export const useDocumentEntity = (id: any) => {

    const api = useApi();
    const modal = useModal();
    const ref = useRef<any>(null);
    const [loading, setLoading] = useState(false);
    const [discussions, setDiscussions] = useState<any[]>([]);
    const [labels, setLabels] = useState<any[]>([]);
    const [data, setData] = useState<IDocument | null>(null);

    const get = async (id: string) => {
        setLoading(true);
        const res = await api.get<IDocument>("/documents/" + id);
        setData(res.data);
        setDiscussions(res.data.discussions);
        setLabels(res.data.labels || []);
        setLoading(false);
    }

    const btnFavoriteOnClick = async () => {
        if (!data) return;

        const newfavorite = !data.favorite;
        const { status } = await api.put("/documents/" + id + "/favorite", { favorite: newfavorite })
        if (status == 200)
            setData({ ...data, favorite: newfavorite });
    }

    const btnCommentOnClick = async (json: any) => {
        if (!data) return;

        const noteJson = json.content;

        const res = await api.post("/documents/" + data.id + "/comment", {
            noteJson: noteJson
        });

        if (res.status == 200) {
            setDiscussions([...discussions, res.data]);
        }

    }
    const lstLabelsOnInput = async (data: any) => {
        setLabels(data);
        await api.put("/documents/" + id + "/labels", { labels });
        setLabels(s => [...s.map((l: any) => ({ ...l, _action: null }))]);
    }

    const btnForwardOnClick = () => {
        modal.open(<ForwardPage document={data} />)
    }
    const btnCancelOnClick = () => {

    }

    useEffect(() => { get(id) }, [id])

    return ({
        get,
        ref,
        loading,
        actions: {
            btnForwardOnClick,
            btnFavoriteOnClick,
            btnCancelOnClick,
            btnCommentOnClick,
            lstLabelsOnInput
        },

        data,
        discussions,
        labels
    });

}