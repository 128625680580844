import { Route, Routes } from 'react-router-dom';
import Box from '../../elements/Box';
import Flex from '../../elements/Flex';
import Heading from '../../elements/Heading';
import Icon from '../../elements/Icon';
import { useAuth } from '../../hooks/useAuth';
import { useEntityTable } from '../../hooks/useEntity';
import DocumentEditPage from './edit';
import DocumentGrid from './grid';
import DocumentViewPage from './view';

const DocumentPage = ({ }: any) => {

    const Entity = useEntityTable("documents");
    const { user } = useAuth();

    if (!user)
        return <div style={{ paddingTop: 20 }}><Box>
            <Flex direction='vertical' alignItems="center">
                <Icon size={32} name="alert" color={"red400"} />
                <Heading>Autentificação necessária</Heading>
            </Flex>
        </Box></div>

    return <Routes>
        <Route index element={<DocumentGrid Entity={Entity} />} />

        <Route path="/e/:id" element={<DocumentEditPage />} />
        <Route path=":id" element={<DocumentViewPage />} />

        {/* <Route path="/emitir" element={<DocumentCreate Entity={Entity} />} /> */}
    </Routes>


}

export default DocumentPage;