import { useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import Heading from "../../elements/Heading";
import { iconNames } from "../../elements/Icon";
import Text from "../../elements/Text";
import IColumnNumber from "./interfaces/IColumnNumber";
import IColumnString from "./interfaces/IColumnString";
import variants from "./variants";



type IColumnVariant = IColumnNumber | IColumnString | { type: "custom", format: string }

type IColumn = IColumnVariant & {
    key: string,
    width: number,
    name: string
}


const types: { [key: string]: any } = {
    "checkbox": (data: any) => <input readOnly type="checkbox" value={Boolean(data) ? "true" : "false"} />,
    "created_by": (data: any) => <input readOnly type="date" value={new Date(data).toString()} />,
    "created_time": (data: any) => <input readOnly type="date" value={new Date(data).toString()} />,
    "date": (data: any) => <input readOnly type="date" value={new Date(data).toString()} />,
    "email": (data: any) => <input readOnly type="text" value={String(data)} />,
    "files": () => <Text color="red400" fontSize={12}>Not supported</Text>,
    "formula": (data: any) => <input readOnly type="text" value={String(data)} />,
    "last_edited_by": () => <Text color="red400" fontSize={12}>Not supported</Text>,
    "last_edited_time": (data: any) => <input type="date" value={new Date(data).toString()} />,
    "multi_select": () => <Text color="red400" fontSize={12}>Not supported</Text>,
    "number": (data: any) => <input readOnly type="number" value={parseInt(data)} />,
    "user": () => <Text color="red400" fontSize={12}>Not supported</Text>,
    "phone_number": (data: any) => <input readOnly type="text" value={String(data)} />,
    "relation": () => <Text color="red400" fontSize={12}>Not supported</Text>,
    "rich_text": (data: any) => <input readOnly type="text" value={String(data)} />,
    "rollup": () => <Text color="red400" fontSize={12}>Not supported</Text>,
    "select": () => <Text color="red400" fontSize={12}>Not supported</Text>,
    "status": () => <Text color="red400" fontSize={12}>Not supported</Text>,
    "text": (data: any) => <input readOnly type="text" value={String(data)} />,
    "url": (data: any) => <a href={String(data)} />,
}


interface ITable {
    data: any[]
    columns: IColumn[]
    actions?: {
        onClick: (row: any) => void
        name: string,
        icon: iconNames
        color: string
    }[]
    definition: {
        key: string,
        type: keyof typeof types,
        width: number | string,
        name: string
    }[]
    renderRow?: (data: any) => any
    viewPage?: string
    customRenders?: {
        [key: string]: any
    }
}


const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const StyledTh = styled.th`
   border-bottom: 1px solid #ddd;
   padding: 16px 8px ;
  text-align: left;
`;

const StyledTd = styled.td`
  border-bottom: 1px solid #ddd;
  padding: 16px 8px ;
  text-align: left;

  .buttons {
    display: flex;
    gap: 4px;
  }
`;

const StyledButton = styled.button<any>`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
`;


const Table = ({ data, renderRow, columns, definition, actions = [], customRenders = {}, viewPage = "{{id}}" }: ITable) => {

    const allWidget = columns.reduce((acc, c) => acc + c.width, 0);
    const navigate = useNavigate();
    const getWiget = (num: number) => (100 * num / allWidget) + "%";

    const theme = useTheme();

    const variants2 = {
        ...variants,
        custom: customRenders
    }

    const renderColumn = (column: IColumn, row: any) => {
        const type = variants2[column.type];
        //@ts-ignore
        const { component: Component, render } = type[column.format];

        return <StyledTd>
            {render(row[column.key])}
        </StyledTd>
    }

    return (
        <StyledTable>
            <thead>
                <tr>
                    {definition.map(column => (
                        <StyledTh key={column.key} style={{ width: "auto" }}>
                            <Heading color="contentSecondary" size={4}> {column.name}</Heading>
                        </StyledTh>
                    ))}
                    {!!actions.length && <StyledTh>Ações</StyledTh>}
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    // <tr key={index} onClick={() => navigate(replaceVariablesString(viewPage, row))}>
                    <>{!!renderRow ? renderRow({
                        data: row
                    }) : definition.map(definition => <td>{types[definition.type](row[definition.key])}</td>)}
                    </>
                    // </tr>
                ))}

            </tbody>
        </StyledTable>
    );
};

export default Table;
