import { Route, Routes } from 'react-router-dom';
import { useEntityTable } from '../../hooks/useEntity';
import TeamTeam from './form';
import TeamGrid from './grid';
import TeamView from './view';

const TeamPage = ({ }: any) => {

    const Entity = useEntityTable("teams");

    if (!Entity)
        return <></>

    return <Routes>
        <Route index element={<TeamGrid Entity={Entity} />} />
        <Route path=":id/editar" element={<TeamTeam EntityTable={Entity} />} />
        <Route path=":id" element={<TeamView EntityTable={Entity} />} />
    </Routes>


}

export default TeamPage;