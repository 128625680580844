
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Icon, { iconNames } from '../../elements/Icon';
import Text from '../../elements/Text';
import { useApi } from '../../hooks/useAuth';
import PeopleCard from '../PeopleCard';
import { Container, SearchItemContainer } from './styles';

type ISuggestion = {
    title: string
    value: any
    icon?: iconNames
    description?: string

}

const dateSuggestionsList: ISuggestion[] = [
    {
        value: new Date().toLocaleDateString(),
        title: "Hoje",
        description: new Date().toLocaleDateString(),
    },
    {
        value: new Date(new Date().getTime() - (60000 * 60 * 24)).toLocaleDateString(),
        title: "Ontem",
        description: new Date(new Date().getTime() - (60000 * 60 * 24)).toLocaleDateString(),
    },
    {
        value: new Date(new Date().getTime() - (60000 * 60 * 24 * 7)).toLocaleDateString(),
        title: "Semana passada",
        description: new Date(new Date().getTime() - (60000 * 60 * 24 * 7)).toLocaleDateString(),
    },
    {
        value: new Date(new Date().getTime() - (60000 * 60 * 24 * 30)).toLocaleDateString(),
        title: "Mês passado",
        description: new Date(new Date().getTime() - (60000 * 60 * 24 * 30)).toLocaleDateString(),
    },
    {
        value: new Date(new Date().getTime() - (60000 * 60 * 24 * 365)).toLocaleDateString(),
        title: "Ano passado",
        description: new Date(new Date().getTime() - (60000 * 60 * 24 * 365)).toLocaleDateString(),
    },
]

const DateSuggestions = ({ input, onSuggestion }: any) => {
    return <div className='popup'>
        <ul>
            {dateSuggestionsList.map((sugg, i) => (<button className='tabIndex' tabIndex={i + 1} onClick={() => onSuggestion(sugg.value)}>
                {!!sugg.icon && <Icon name={sugg.icon} size={16} />}
                <div style={{ flex: 1 }}><Text bold>{sugg.title}</Text></div>
                {!!sugg.description && <Text color="contentTertiary">{sugg.description}</Text>}
            </button>))}
        </ul>
    </div>
}

const UserSuggestions = ({ input, onSuggestion }: any) => {

    const [data, setData] = useState<any[]>([]);
    const api = useApi();

    const get = async () => {
        const res = await api.get("/users");
        setData(res.data);
    }

    useEffect(() => { get(); }, [])

    return <div className='popup'>
        <ul>
            {data.map((user: any, i: number) => (<button className='tabIndex' tabIndex={i + 1} onClick={() => onSuggestion(user.name)}>
                <PeopleCard data={user} />
            </button>))}
        </ul>
    </div>
}

type IOperator = {
    title: string
    key: string
    icon?: iconNames
    description?: string
}

type IType = {
    title: string
    key: string
    icon?: iconNames
    description?: string
    operators: IOperator[]
    suggestion: any
}

const operatorsKeys: any = {
    "=": "{{key}}",
    "!=": "not[{{key}}]",
    ">": "gt[{{key}}]",
    ">=": "gte[{{key}}]",
    "<": "lt[{{key}}]",
    "<=": "lte[{{key}}]",
    "in": "in[{{key}}]",
    "notin": "notin[{{key}}]",
    "%": "includes[{{key}}]"
}


const types: { [key: string]: IType } = {
    author: {
        title: "Autor",
        key: "author",
        icon: "pen",
        suggestion: UserSuggestions,
        operators: [
            {
                title: "=",
                key: "=",
                description: "Igual"
            },
            {
                title: "!=",
                key: "!=",
                description: "Diferente"
            },
        ]
    },
    receiver: {
        title: "Destinatário",
        key: "receiver",
        icon: "serves3",
        suggestion: UserSuggestions,
        operators: [
            {
                title: "=",
                key: "=",
                description: "Igual"
            },
            {
                title: "!=",
                key: "!=",
                description: "Diferente"
            },
        ]
    },
    date: {
        title: "Criado em",
        key: "date",
        icon: "clock",
        suggestion: DateSuggestions,
        operators: [{
            title: "=",
            key: "=",
            description: "Igual"
        },
        {
            title: "!=",
            key: "!=",
            description: "Diferente"
        },

        {
            title: ">",
            key: ">",
            description: "Maior"
        },
        {
            title: ">=",
            key: ">=",
            description: "Maior ou igual"
        },
        {
            title: "<",
            key: "<",
            description: "Menor"
        },
        {
            title: "<=",
            key: "<=",
            description: "Menor ou igual"
        }]
    },
}

// const operators: { [key: string]: IType } = {
//     "=": {
//         title: "=",
//         key: "=",
//         description: "Igual"
//     },
//     "!=": {
//         title: "!=",
//         key: "!=",
//         description: "Diferente"
//     },
//     ">": {
//         title: ">",
//         key: ">",
//         description: "Maior"
//     },
//     ">=": {
//         title: ">=",
//         key: ">=",
//         description: "Maior ou igual"
//     },
//     "<": {
//         title: "<",
//         key: "<",
//         description: "Menor"
//     },
//     "<=": {
//         title: "<=",
//         key: "<=",
//         description: "Menor ou igual"
//     },

// }

type ISearches = {
    isOpen: boolean
    value?: any
    type: keyof typeof types
    operator: string | null
}


const filterByKeyOrDesc = (text: string, array: any[]) => {

    text = text.toLowerCase();

    return array.filter(type => (
        type.key.toLowerCase().includes(text) || text.toLowerCase().includes(type.key) ||
        type.title.toLowerCase().includes(text) || text.toLowerCase().includes(type.title) ||
        (type.description && (type.description.toLowerCase().includes(text) || text.toLowerCase().includes(type.description)))
    ))
}

const Search = ({ onSubmit = () => { } }: any) => {

    const inputRef = useRef<HTMLInputElement>(null);

    const [searches, setSearches] = useState<ISearches[]>([]);
    const [focus, setFocus] = useState(false);
    const [input, setInput] = useState<string>("");

    const [searchParams, setSearchParams] = useSearchParams();

    const opened = searches.find(s => s.isOpen);
    const last = searches[searches.length - 1];

    const handleInsertSearch = (type: IType) => {
        setSearches([...searches, { isOpen: true, type: type.key, operator: null }]);
        inputRef.current?.focus();
    }

    const handleSetOperator = (type: IOperator) => {
        const index = searches.indexOf(last);
        setSearches(state => {
            state[index].operator = type.key;
            return [...state];
        })
        setInput("");
        inputRef.current?.focus();
    }

    const onInput = (e: React.FormEvent<HTMLInputElement>) => {

        let value = e.currentTarget.value;
        setInput(value);

        if (opened && opened.operator == null) {
            if (value == "=") {
                const index = searches.indexOf(last);
                setSearches(state => {
                    state[index].operator = "=";
                    return [...state];
                })
                setInput("");
            }
            else {
                if (value == "!=") {
                    const index = searches.indexOf(last);
                    setSearches(state => {
                        state[index].operator = "!=";
                        return [...state];
                    })
                    setInput("");
                }
            }
        }

    }

    const submit = () => {

        const filters: any = {

        }

        searches.map((s) => {

            filters[types[s.type].key] = s.value;
            if (s.operator)
                searchParams.set(operatorsKeys[s.operator].replace("{{key}}", types[s.type].key), s.value);



        })
        if (input) {
            searchParams.set("any", input);
        }
        onSubmit(filters);
        setSearchParams(searchParams);
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code == "Backspace") {

            if (!!input) return;

            if (!last) return;

            const index = searches.indexOf(last);

            if (last.isOpen == true && last.operator) {
                setSearches(state => {
                    setInput(state[index]?.operator || "");
                    state[index].operator = null;
                    return [...state];
                })
            }
            else if (last.isOpen == true && !last.operator) {
                setInput(types[last.type].title || "");
                setSearches(state => {
                    state.splice(index, 1);
                    return [...state];
                })
            }
            else {
                setSearches(state => {
                    setInput(state[index].value);
                    state[index].isOpen = true;
                    state[index].value = null;
                    return [...state];
                })
            }

            e.preventDefault();
        }
        else if (e.key == "Enter") {
            if (opened && opened.operator == null) {

                const index = searches.indexOf(opened);
                setSearches(state => {
                    state[index].operator = types[opened.type].operators.find(o => o.key == input) ? input : "=";
                    return [...state];
                })
                setInput("");
                inputRef.current?.focus();
            }
            else if (opened) {
                const index = searches.indexOf(opened);
                setSearches(state => {
                    state[index].isOpen = false;
                    state[index].value = input;
                    return [...state];
                })
                setInput("");
                inputRef.current?.focus();
            }
            else if (!opened && !input) {
                submit()
            }
            else {
                const first = filterByKeyOrDesc(input, Object.values(types))[0];
                if (!first) {
                    return submit()
                }
                setSearches([...searches, { isOpen: true, type: first.key, operator: null }]);
                inputRef.current?.focus();
                setInput("");
            }
        }

        else if (e.key == "Tab" || e.key == "ArrowDown") {
            e.preventDefault();
            const element: HTMLButtonElement | null = document.querySelector(".tabIndex[tabindex='1']")
            if (element) {
                element.focus();
            }
        }
        else if (e.key == "Escape") {
            setFocus(false);
            inputRef.current?.blur()
        }
    }

    const SuggestionComponent = (opened && opened.operator != null) ? types[opened.type].suggestion : null;

    const keyClickOut = (e: any) => {

        const element: HTMLDivElement = e.target;

        if (!element) return;

        if (!element.closest(".searchbox")) {
            handleBlur();
        }

    }

    const handleBlur = () => {
        setFocus(false);
        document.removeEventListener("mousedown", keyClickOut);
    }

    const handleFocus = () => {
        setFocus(true);
        document.addEventListener("mousedown", keyClickOut);
    }

    return <Container className="searchbox">
        <div className={'left-container ' + (focus ? "focused" : "")}>
            {/* <button className='history-button'>
                <Text bold color="contentTertiary">Pesquisas Recentes</Text>
            </button> */}
            <div className='input-container'>
                <div className='input'>
                    {searches.map(data => <SearchItem data={data} />)}
                    <input
                        onFocus={handleFocus}
                        ref={inputRef}
                        value={input}
                        onKeyDown={onKeyDown}
                        onInput={onInput}
                        placeholder='Pesquisar ou filtrar resultados...' />
                </div>
            </div>
        </div>
        {focus && !opened && <div className='popup'>
            <ul>
                {filterByKeyOrDesc(input, Object.values(types)).map((type, i) => (<button className='tabIndex' tabIndex={i + 1} onClick={() => handleInsertSearch(type)}>
                    {!!type.icon && <Icon name={type.icon} size={16} />}
                    <Text style={{ flex: 1 }} fontSize={14}>{type.title}</Text>
                    {!!type.description && <Text >{type.description}</Text>}
                </button>))}
            </ul>
        </div>}
        {focus && opened && opened.operator == null && <div className='popup'>
            <ul>
                {filterByKeyOrDesc(input, types[opened.type].operators).map((type, i) => (<button className='tabIndex' tabIndex={i + 1} onClick={() => handleSetOperator(type)}>
                    {!!type.icon && <Icon name={type.icon} size={16} />}
                    <div style={{ flex: 1 }}><Text fontSize={14} bold>{type.title}</Text></div>
                    {!!type.description && <Text color="contentTertiary">{type.description}</Text>}
                </button>))}
            </ul>
        </div>}
        {focus && SuggestionComponent != null && <SuggestionComponent input={input} onSuggestion={(v: any) => { setInput(v); inputRef.current?.focus() }} />}
    </Container>;
}

const SearchItem = ({ data }: { data: ISearches }) => {

    const type = types[data.type];

    return <SearchItemContainer>
        <div className='key'><Text nowrap fontSize={12} bold>{type.title}</Text></div>
        {!!data.operator && <div className='operator'><Text nowrap fontSize={12} bold>{data.operator}</Text></div>}
        {!!data.value && <div className='value'>
            <Text nowrap fontSize={12} bold>{data.value}</Text>
            <button className='action'>
                <Icon name="close" size={16} />
            </button>
        </div>}
    </SearchItemContainer>
}




export default Search;