import React, { useCallback, useRef, useState } from 'react';
// import DotObject from '../services/dot-object';
import { cloneObject } from '../services/functions';

// import { Container } from './styles';

const dotStringToArray = (array: any = {
    "name": "oi",
    "group.element1.name": "numero 1",
    "group.element1.desc": "...",
    "group.element2.name": "numero 2",
    "group.element2.desc": "......",
}) => {

    let result: any = {};

    Object.keys(array).map(key => {
        let value = array[key];

        let dotSplit = key.split(".");
        let dots = dotSplit.reverse();
        let obj = {};
        dots.map((dotKey, i) => {

            if (i === 0) {
                obj = { ...obj, [dotKey]: value }
            }
            else {
                obj = { [dotKey]: obj }
            }

        })
        result = Object.assign(result, obj);
    })

    return result
}

interface fieldsProps {
    [name: string]: {
        defaultValue?: any,
        value: any
    }
}

interface optionsProps {
    onlyModified?: boolean
    includesId?: boolean
    fieldsSync?: fieldsSync | undefined | Object
}

interface fieldsSync {
    [input: string | number]: any
}

interface errorsProps {
    [input: string]: string | undefined
}

interface validityProps {
    [input: string]: {
        require?: boolean,
        min?: number,
        minLength?: number,
    }
}

export const useStateForm = (initialData: any = {}, prevalidity: validityProps = {}, formOptions: optionsProps = {}) => {

    //  var fields = useRef<fieldsProps>({}).current;
    // const [fields,setFields] = useState<fieldsProps>({});
    // const [fields, setFields] = useState<any>(DotObject.dot(initialData));
    // const [historyFields, setHistoryFields] = useState<any>(DotObject.dot(initialData));
    const [fields, setFields] = useState<any>(initialData);
    const validity = useRef<validityProps>(prevalidity).current;
    const [fields2, setFields2] = useState<any>(initialData);
    const [historyFields, setHistoryFields] = useState<any>(initialData);


    const insertField = (field: string, value: any, returnValue: any = value) => {

        //  fields[field] = {...fields[field],...values};

        // let insert = typeof value === "object" ? DotObject.dot({
        //     [field]: value
        // }) : { [field]: value }


        setFields2((state: any) => ({ ...state, [field]: value }))
        setFields((state: any) => ({ ...state, [field]: returnValue }))


        //fields[field].value = value;


    }
    // const setFields = (fncNewValue:any) => {
    //     let newvalue = fncNewValue(fields);
    //     fields = newvalue;
    // }

    const lastfields = React.useRef<any>();

    const options: optionsProps = {
        onlyModified: true,
        includesId: false,
        fieldsSync: undefined,
        ...formOptions
    }

    const [errors, setErrors] = useState<errorsProps>({})

    const getSync = useCallback(() => {
        let rtrn: any[] = [];

        if (options && options.fieldsSync)
            Object.keys(errors).map(er => {
                //@ts-ignore
                if (er && options.fieldsSync[er.toString()]) {
                    //@ts-ignore
                    rtrn.push(options.fieldsSync[er])
                }
            });

        return ({
            errors: rtrn
        })
    }, [errors, options.fieldsSync])

    const getInitialData = useCallback(() => {
        return historyFields
    }, [historyFields])

    const updateHistory = useCallback((extra: Object = {}) => {
        let newvalue = ({ ...historyFields, ...fields, ...extra })
        setFields(newvalue)
        setHistoryFields(newvalue)
    }, [historyFields, fields])

    const reset = useCallback(() => {
        setFields({ ...historyFields })
    }, [historyFields])

    const validityFields = useCallback(() => {

        Object.keys(validity).map(field => {


            let validate = validity[field];
            let value = fields[field];
            let key = field;

            const valide = (key: any, value: any) => {
                if (validate.require && (!value || (typeof value === "number" && value === 0))) {
                    setErrors({ [key]: "Preencha esse dado." })


                    throw new Error(`${key} is not a valid input (number required)`)
                }
                else if (validate.minLength && ((!value && validate.minLength > 0) || (value.length < validate.minLength))) {
                    setErrors({ [key]: `Preencha esse dado com ${validate.min} caracteres.` })

                    throw new Error(`${key} is not valid`)
                }
                else if (validate.min && ((!value && validate.min > 0) || (value < validate.min))) {
                    setErrors({ [key]: `Valor minimo deste campo é ${validate.min}.` })

                    throw new Error(`${key} is not valid`)
                }
            }

            if (key.startsWith("*")) {
                let keys = Object.keys(fields).filter((k) => {
                    return k.endsWith(key.substring(1))
                })
                keys.map(k => {
                    valide(k, fields[k])
                })
            } else {
                valide(key, value)
            }




        })

    }, [fields, validity])

    const diffField = useCallback(() => {

        let returnFields: any = {};



        Object.keys(fields).map(name => {

            let defaultValue = historyFields[name];
            let value = fields[name];

            if (((name === 'id' || name.endsWith(".id")) && options.includesId) || !String(defaultValue) || String(value) !== String(defaultValue)) {
                returnFields[name] = value;
            }

        })


        return returnFields

    }, [historyFields, fields])

    const getFields = useCallback((showAll: boolean = false) => {

        // try {
        validityFields();
        let fi = (options.onlyModified && !showAll) ? diffField() : fields;
        // let data = DotObject.object(cloneObject(fi));
        let data = cloneObject(fi);
        return data;
        // }
        // catch (err) {
        //     return undefined;
        // }

    }, [historyFields, fields, validity])

    const isModified = useCallback(() => {

        return (JSON.stringify(fields) !== JSON.stringify(historyFields))

        // let fi = diffField();
        // return Object.keys(fi).length > 0

    }, [historyFields, fields])

    const setError = useCallback((field: string, message: string) => {
        setErrors((laststate) => {
            return ({ ...laststate, [field]: message })
        })
    }, [initialData])


    const setInitialData = useCallback((object: any) => {

        // if (!object)
        //     return;


        // setHistoryFields(DotObject.dot(object));
        // setFields(DotObject.dot(object));

        setHistoryFields({ ...object });
        setFields({ ...object });
        setFields2({ ...object });

    }, [initialData])


    const setField = useCallback((field: string, value: any) => {

        insertField(field, value)

    }, [initialData])



    const removeField = useCallback((field: string) => {
        delete fields[field]
    }, [initialData])

    const getDefaultValue = useCallback((name: any) => {

        return (
            initialData[name] ? initialData[name] : undefined
        )

    }, [initialData])



    const registerField = useCallback((name: string, field?: any) => {

        //let defaultValue = getDefaultValue(name)

        var value = fields2[name];
        // var values = Object.entries(fields).filter(([key]) => key.includes(name) && key.includes("."))
        if (field && field.required == true) {
            validity[name] = { require: true };
        }

        // let value = values.length > 1 ? DotObject.object(values.reduce((obj, [key, value]) => ({
        //     ...obj,
        //     [key.replace(name + ".", "")]: value
        // }), {})) : fields[name];
        // console.log(name,value2)

        const onInput = (value: any, returnValue: any = value) => {

            //onInputSecond && onInputSecond(value)

            insertField(name, value, returnValue);

            if (errors[name])
                setErrors(s => {
                    delete s[name]
                    return s
                })


        }


        return ({ onInput, error: errors[name], required: (field && field.required), name, defaultValue: value, value })

    }, [errors, initialData, fields])

    if (!lastfields.current) {
        lastfields.current = initialData
    }

    return ({ getFields, updateHistory, isModified, reset, getInitialData, registerField, setInitialData, fields, errors, setError, setField, getSync, removeField })//({getFields,registerField})

}

