import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import Button from '../../elements/Button';
import TextInput from '../../elements/inputs/TextInput';
import { IEntityTable } from '../../hooks/useEntity';
import { useForm } from '../../hooks/useForm';

const Container = styled.div`

    padding: 20px;

`;


const TeamTeam = ({ EntityTable }: { EntityTable: IEntityTable }) => {

    const { id } = useParams();
    const { entity } = EntityTable;
    const form = useForm();
    const navigate = useNavigate();
    const theme = useTheme()

    console.log(entity);

    // const initialize = async () => {
    //     console.log("initializing");
    //     if (id && id == "novo") {
    //         Entity.Create({});
    //         form.setInitialData(Entity.data);
    //     }
    //     else if (id) {
    //         await Entity.Get();
    //         form.setInitialData(Entity.data);
    //     }
    //     // else {
    //     //     Entity.Create();
    //     // }
    //     // await Entity.Get();
    //     // form.setInitialData(Entity.data)
    // }

    const submit = async () => {
        if (!entity)
            return;
        // entity.data = {}
        entity.SetData(form.getFields());
        await entity.Save();
        await EntityTable.Get(id);
        navigate("../" + id)
        EntityTable.Refresh();
    }


    useEffect(() => {
        if (!entity)
            EntityTable.Get(id);
    }, [id])

    // useEffect(() => {
    //     if (Entity)
    //         Entity.Update(form.getFields())
    // }, [form])

    useEffect(() => {
        if (entity)
            form.setInitialData({
                title: entity.data.data.title
            })
        // initialize();
    }, [entity])



    return <Container>

        <TextInput title="Nome" {...form.registerField("title")} />
        <Button title="Salvar" onClick={submit} />

    </Container>;

}

export default TeamTeam;