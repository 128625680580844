import { Route, Routes } from 'react-router-dom';
import { useEntityTable } from '../../hooks/useEntity';
import DocumentCreate from '../documents/create';
import TeamGrid from './grid';

const SolutionPage = ({ }: any) => {

    const Entity = useEntityTable("teams");

    if (!Entity)
        return <></>

    return <Routes>
        <Route index element={<TeamGrid Entity={Entity} />} />

        <Route path="protocolar" element={<DocumentCreate />} />

        {/* <Route path=":id/editar" element={<TeamTeam EntityTable={Entity} />} /> */}
        {/* <Route path=":id" element={<TeamView EntityTable={Entity} />} /> */}
    </Routes>


}

export default SolutionPage;