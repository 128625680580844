// import LOGO from '../assets/logo.json';
// import LottieView from 'react-lottie'
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '../GlobalStyles';
import UseAuthProvider from '../hooks/useAuth';
import { useTheme } from '../hooks/useTheme';
import StackRoutes from './stack.routes';

const Routes = () => {

    const { theme } = useTheme();

    // const { status, merchantId } = useAuth();


    // if (status === "initializing")
    //     return <Loading />

    // if (status !== "logged")
    //     return <LoginPage />

    // if (!merchantId)
    //     return <LoginSelectMerchantPage />



    return <ThemeProvider theme={theme} >
        <UseAuthProvider>
            <StackRoutes />
            <GlobalStyles />
        </UseAuthProvider>
    </ThemeProvider>

}

export default Routes
