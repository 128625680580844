
import styled from 'styled-components';


const Container = styled.div`
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
    background-color:  ${p => p.theme.backgroundSecondary};

    @keyframes initialloading{
        0% {
            transform: translate(-34px);
        }

        50% {
            transform: translate(96px);
        }
        100% {
            transform: translate(-34px);
        }
    }

    .loading-bar {
        
        width: 130px;
        height: 4px;
        margin: 0 auto;
        border-radius: 2px;
        background-color:  ${p => p.theme.backgroundTertiary};
        position: relative;
        overflow: hidden;
        z-index: 1;
        transform: rotateY(0);
        transition: transform .3s ease-in;
    }

    .blue-bar {
        height: 100%;
        width: 68px;
        position: absolute;
        transform: translate(-34px);
        background-color: ${p => p.theme.yellow400};
        border-radius: 2px;
        animation: initialloading 1.5s ease infinite;
    }

    .logo {
        img {
            width: 200px;
        }
    }
   
`;


const StartingPage = () => {

    return <Container>
        <div className="logo">
            <img src={"http://localhost:3000/logo.png"} />
        </div>
        <div className="loading-bar">
            <div className="blue-bar"></div>
        </div>
    </Container>;
}

export default StartingPage;