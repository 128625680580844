import styled from 'styled-components';
import Button from '../../../elements/Button';
import Separator from '../../../elements/Separator';
import BooleanInput from '../../../elements/inputs/BooleanInput';
import TextInput from '../../../elements/inputs/TextInput';
import { useForm } from '../../../hooks/useForm';

const Container = styled.div`

    padding: 20px 20px;

    > main {
        gap: 20px;
        display: flex;

        > .left{
            flex: 2;
        }
        > .right {
            flex: 1;
        }
    }

`;


const ForwardPage = ({ document }: any) => {

    const form = useForm({
        favorite: document.favorite,
        to: document.receivers.filter((a: any) => Boolean(a.user))[0]?.user.email
    });

    return <Container>

        <form action="">

            <TextInput title="Destinatário" placeholder="Usuário ou Setor" {...form.registerField("to")} />
            <BooleanInput title="Continuar acompanhando" {...form.registerField("favorite")} />
            <Separator />
            <Button color="blue400" title="Encaminhar" icon="forward" />

        </form>

    </Container>;

}

export default ForwardPage;