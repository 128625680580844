
import styled from 'styled-components';
import config, { colors } from '../../config';
import Icon from '../../elements/Icon';
import Text from '../../elements/Text';


// const Container = styled.td`
//     .container {
//         padding: 6px;
//         border-radius: 4px;
//         display: flex;
//         gap: 5px;
//         align-items: center;

//         > .picture {
//             flex-shrink: 0;
//             background: #ddd;
//             box-shadow: none;
//             outline: rgba(227, 226, 224, 0.5) solid 1px;
//             outline-offset: -1px;
//             border-radius: 4px;
//             width: 36px;
//             height: 36px;
//             user-select: none;
//             opacity: 1;
//             overflow: hidden;

//             > img {
//                 display: block;
//                 object-fit: cover;
//                 width: 100%;
//                 height: 100%;
//             }
//         }

//         /* &:hover{
//             background-color: #eeeeee;
//         } */
//         width: 100%;
//         background-color: #eeeeee;
//     }
//     width: 100%;
//     flex: 1;
// `;


const Container = styled.div`

    > .picture {
        flex-shrink: 0;
        background: #ddd;
        box-shadow: none;
        /* outline: rgba(227, 226, 224, 0.5) solid 1px; */
        outline-offset: -1px;
      
        overflow: hidden;
        user-select: none;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    position: relative;

    padding: 0;
    >.mentioned {
        width: 16px;
        height: 16px;
        position: absolute;
        border-radius: 8px;
        background-color: ${colors.orange400};
        display: flex;
        align-items: center;
        justify-content: center;
        right: -2px;
        bottom: -2px;
    }
    
`;


const UserPicture = ({ data, size = 28, mentioned = false }: any) => {

    return <Container>
        <div className='picture' style={{
            width: size,
            height: size,
            borderRadius: size / 2
        }}>
            {!!data.picture ? <img src={config.url.files + data.picture?.low} />
                : <Text bold fontSize={size / 2} color={colors.gray500}> {String(data.name).substring(0, 2).toUpperCase()} </Text>}
        </div>

        {mentioned && <div className='mentioned'>
            <Text fontSize={12} color={colors.background} bold>
                <Icon name="atsign" color={colors.background} size={18} />
            </Text>
        </div>}

    </Container>
}

export default UserPicture