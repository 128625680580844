import styled from 'styled-components';

export const Container = styled.div`

    position: relative;

    > .info {
        position: absolute;
        z-index: 2;
        bottom: -6px;
        right: 10px;
        padding: 2px 6px;
        border-radius: 4px;
        background-color: ${p => p.theme.backgroundPrimary};
    }

    > .border {
        pointer-events: none;
        opacity: 0.7;
        z-index: 10;
        position: absolute;
        display: block;
        top: -2px;
        bottom: -2px;
        left: -2px;
        right: -2px;
        width: calc( 100% + 4px );
        height: calc( 100% + 4px );
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: ${p => p.theme.red100}ff;
        border: 2px dashed ${p => p.theme.red400};

       
    }

`;
