
import { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../config';
import { classList } from '../services/functions';
import Icon, { iconNames } from './Icon';
import Text from './Text';

const Container = styled.div`

    width: 100%;

    .tablist {

        border-bottom: 1px solid ${colors.gray200};
        display: flex;
        align-items: center;
        height: 100%;
        gap: 4px;
        flex-grow: 1;
        justify-content: space-between;
        padding-bottom: 4px;

        .left, .right {
            display: flex;
            align-items: center;
            gap: 4px;
        }    

        .tab {
            user-select: none;
            transition: background 20ms ease-in 0s;
            cursor: pointer;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            white-space: nowrap;
            height: 32px;
            border-radius: 4px;
            line-height: 1.2;
            min-width: 0px;
            padding-left: 14px;
            padding-right: 14px;
            max-width: 220px;
            position: relative;
            gap: 6px;

            > .count {
                min-width: 20px;
                padding: 0 6px;
                height: 20px;
                border-radius: 10px;
                background-color: ${p => p.theme.backgroundTertiary};
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.selected::after {
                content: "";
                height: 2px;
                position: absolute;
                background-color: ${p => p.theme.blue400};
                bottom: -4px;
                left: 0;
                right: 0;
                //background-color: ${colors.blue100};
            }

            &:hover{
                background-color: ${colors.gray100};
            }
        }
    }
`;

interface TabControl {
    tabs: {
        component: any
        title: string
        count?: number
        icon?: iconNames
        name: string
    }[]
    step?: number
    defaultSelected?: string
}

const TabControl = ({ step = 0, tabs = [{ component: <></>, title: "Tab", name: "TAB" }], defaultSelected = tabs[0]?.name || "" }: TabControl) => {

    const [selected, setSelected] = useState<string>(defaultSelected);

    const selectedTab = tabs.find(t => t.name === selected);



    return <Container>
        <div className='tablist'>
            <div className='left'>
                {tabs.filter(t => Boolean(t.title)).map((tab, i) => {

                    const enabled = step == 0 || i < step

                    return (<div style={{

                    }} onClick={() => enabled ? setSelected(tab.name) : undefined} className={classList(['tab', selected == tab.name ? "selected" : ""])}>
                        {!!step && i < step && <Icon name="check_circle" size={18} />}
                        {!!tab.icon && <Icon name={tab.icon} size={18} />}
                        <Text color={enabled ? "contentPrimary" : "contentTertiary"} fontSize={16} >
                            {tab.title}
                        </Text>
                        {tab.count != null && <div className='count'>
                            <Text bold fontSize={14}>{tab.count || 0}</Text>
                        </div>}
                    </div>)
                })}
            </div>
            <div className='right'>
                {tabs.filter(t => !Boolean(t.title)).map((tab, i) => {

                    const enabled = step == 0 || i < step

                    return (<div style={{

                    }} onClick={() => enabled ? setSelected(tab.name) : undefined} className={classList(['tab', selected == tab.name ? "selected" : ""])}>
                        {!!step && i < step && <Icon name="check_circle" size={18} />}
                        {!!tab.icon && <Icon name={tab.icon} size={18} />}
                        <Text color={enabled ? "contentPrimary" : "contentTertiary"} fontSize={16} >
                            {tab.title}
                        </Text>
                        {tab.count != null && <div className='count'>
                            <Text bold fontSize={14}>{tab.count || 0}</Text>
                        </div>}
                    </div>)
                })}
            </div>
        </div>
        <div className='container' style={{ paddingTop: 20 }}>
            {!!selectedTab && selectedTab.component}
        </div>
    </Container>;
}

export default TabControl;