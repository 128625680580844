
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Flex from '../elements/Flex';
import Heading from '../elements/Heading';
import IconButton from '../elements/IconButton';
import Text from '../elements/Text';
import { useApi } from '../hooks/useAuth';
import { inlineSwitch } from '../services/functions';

const Container = styled.div`

    .search {
        margin: 10px 0;
        border: 1px solid ${p => p.theme.borderTransparent};
        background-color: ${p => p.theme.backgroundPrimary};
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding-left: 6px;

        input {
            padding: 6px;
            outline: none;
        }

    }

    .all {
        li {
            list-style: none;
            padding: 6px 12px;
            background-color: ${p => p.theme.backgroundSecondary};
            margin-bottom: 6px;
            border-radius: 4px;
            cursor: pointer;

            &.selected{
                border: 2px solid ${p => p.theme.borderSelected};
            }

            &:hover{
                background-color: ${p => p.theme.backgroundTertiary};
            }

        }
    }
`;

interface IConnectionGrid {
    url: string
    onInput: any
    labelKey?: string
    value: IItem[]

    keyObject?: string
    keyPath?: string
}


type IItem = { [key: string]: any } | IItemBase

type IItemBase = {
    _action?: "delete" | "create" | "update"
    id: string
}

const ConnectionGrid = ({ url, onInput, value = [], keyPath = "userId", keyObject = "user", labelKey = "name" }: IConnectionGrid) => {

    const [search, setSearch] = useState("");
    const [data, setData] = useState<any>([]);
    const api = useApi();


    const get = async () => {
        const res = await api.get(url);
        if (res.data)
            setData(res.data);
    }

    const handleRemove = (item: IItem) => {

        const index = value.indexOf(item);
        let oldvalue = value;

        if (index == -1) return;

        if (item._action && item._action == "create") {
            oldvalue.splice(index, 1);
        }
        else {
            oldvalue[index] = { ...item, _action: "delete" };
        }

        onInput([...oldvalue])
    }

    const handleInsert = (data: any) => {

        let oldvalue = value;
        //@ts-ignore
        let find = oldvalue.find(d => d[keyPath] == data.id);


        if (find) {
            let index = oldvalue.indexOf(find);
            if (find._action == "delete") {
                oldvalue[index]._action = null
            }
        }
        else {
            oldvalue.push({
                [keyObject]: data,
                [keyPath]: data.id,
                _action: "create"
            })
        }

        onInput([...oldvalue])



    }

    useEffect(() => {
        get();
    }, [search])

    return <Container>
        <div className='search'>
            <input placeholder='Pesquisar...' value={search} onInput={e => setSearch(e.currentTarget.value)} />
        </div>

        <div className='dialog' style={{ display: search ? "block" : "none" }}>

            <Heading size={4}>Todos</Heading>

            <ul className='all'>

                {data.map((d: any) => <li

                    className={value.find((v: any) => v[keyPath] == d.id) ? "selected" : ""}

                    onClick={() => handleInsert(d)}>
                    <Text bold>{d[labelKey]}</Text>
                </li>)}

            </ul>

        </div>


        <Heading size={4}>Selecionados</Heading>

        <ul className='all'>

            {value.map((d: any) => <li>
                <Flex alignItems="center">
                    <Text bold>{d[keyObject][labelKey]}</Text>
                    <Text style={{ paddingLeft: 6 }} bold color={
                        inlineSwitch(d._action, ["create", "blue400", "update", "orange400", "delete", "red400"], "contentPrimary")
                    }>{d._action}</Text>
                    <IconButton icon="trash" size={18} onClick={() => handleRemove(d)} />
                </Flex>
            </li>)}

        </ul>



    </Container>;
}

export default ConnectionGrid;