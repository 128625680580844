import styled from 'styled-components';

export const Container = styled.div`

    position: relative;
    max-width: 600px;

    > .left-container{
        border: 1px solid ${p => p.theme.contentTertiary};
        min-height: 34px;
        display: flex;
        background-color: ${p => p.theme.backgroundPrimary};
        border-radius: 4px;
        position: relative;

        &::before{
            content: "";
            left: -4px;
            right: -4px;
            top: -4px;
            bottom: -4px;
            border-radius: 6px;
            width: calc( 100% + 4px );
            height: calc( 100% + 4px );
            border: 2px solid transparent;
            position: absolute;
            transition: 200ms ;
        }

        &.focused::before{
            border: 2px solid ${p => p.theme.blue400};
        }

        > .input-container{
          //  border-left: 1px solid ${p => p.theme.contentTertiary};
            flex: 1;
            position: relative;
            min-width: 0;
          

            > .input {

                display: flex;
                overflow-x: auto;
                white-space: nowrap;
                width: 100%;
                padding : 0  6px ;

                > input {
                    width: 100%;
                    height: 30px;
                    outline: none;
                    padding: 0 2px;
                    font-weight: 400;
                }
            }
        }

        > .history-button {
            padding: 0 12px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border:1px solid transparent;
            transition: 200ms;
            cursor: pointer;

            &:hover{
                background-color: ${p => p.theme.backgroundTertiary};
                border-color: ${p => p.theme.contentTertiary};
            }
           
        }
    }

    > .popup{
        position: absolute;
        width: auto;
        left: 0px;
        top: calc( 100% - 4px );
        z-index: 300;
        min-width: 240px;
        max-width: 500px;
        margin-top: 4px;
        margin-bottom: 24px;
        font-size: 0.875rem;
        font-weight: 400;
        padding: 8px 0;
        background-color: #fff;
        border: 1px solid #dcdcde;
        border-radius: 0.25rem;
        box-shadow: 0 2px 16px 0px rgba(0,0,0,0.2);

        >ul {
            > button{
                width: 100%;
                display: flex;
                align-items: center;
                gap: 6px;
                padding: 8px 8px;
                transition: 200ms;
                cursor: pointer;

                &:hover{
                    background-color: ${p => p.theme.backgroundTertiary};
                }
            }
        }
    }
`;

export const SearchItemContainer = styled.div`

    display: inline-flex;
    margin: 4px;

    > .key,.operator,.value {
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 6px;
        min-width: 24px;
        height: 24px;
        background-color: ${p => p.theme.backgroundTertiary};
        margin-right: 2px;
        gap: 2px;
    }

    > .value > .action {
        padding: 3px;
        border-radius: 2px;
        cursor: pointer;
        transition: 200ms;

        &:hover {
            background-color: ${p => p.theme.borderTransparent};
        }
    }
    
   
`;
