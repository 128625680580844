
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import UserPicture from '../../../components/user/UserPicture';
import Heading from '../../../elements/Heading';
import { useApi } from '../../../hooks/useAuth';
import { useModal } from '../../../hooks/useModal';
import SelectUserModal from '../../modals/SelectUser';


const Container = styled.div<any>`
    display: flex;
    gap: 8px;
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;
    background-color: ${props => props.theme.backgroundPrimary};
    flex-direction: column;
    box-shadow: 0 1px 4px 0 rgba(0, 8, 38, 0.04);
    border-radius: 10px;
    border: 1px solid transparent;
    transition: 200ms;

    > header{
        border-radius: 6px 6px 0 0;
        width: 100%;
        height: 80px;
        background-color: ${p => p["attr-color"]};
   
   
        > .members{
            align-items: center;
            justify-content: center;
            height: 100%;
            display: flex;
        
        .receiver{
            width: 40px;
            position: relative;
            height: 40px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${attr => attr.theme.backgroundPrimary};
            border: 3px solid ${p => p["attr-color"]};

            &:not(:first-child){
                margin-left: -14px;
            }
        }
        .new{
            width: 36px;
            position: relative;
            height: 36px;
            border-radius: 18px;
            display: flex;
            cursor: pointer;
            align-items: center;
            border: 4px solid ${p => p.theme.backgroundPrimary};
            justify-content: center;
            background-color: ${attr => attr.theme.backgroundSecondary};

            &:not(:first-child){
                margin-left: -14px;
            }

            &:hover {
                background-color: ${attr => attr.theme.backgroundTertiary};
            }
        }
    }
   

    }

    > section {
        padding: 12px;

     
    }

   

`;

const TeamCard = ({
    entity
}: { entity: any }) => {

    const navigate = useNavigate();
    const api = useApi();
    const modal = useModal();
    const theme = useTheme();

    const InsertUser = async (userId: string) => {
        const res = await api.post("/teams/" + entity.data.id + "/add", {
            userId
        })
        entity.Update(res.data);
    }

    const openModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        modal.open(<SelectUserModal
            onConfirm={({ selected }: any) => {
                InsertUser(selected)
            }}
            blackList={entity.data.users.reduce((arr: any, { user }: any) => [...arr, user.id], [])}
        />)
    }

    return <Container attr-color={theme.blue400} onClick={() => navigate(entity.data.id)}>

        <header >
            <div className='members'>
                {entity.data?.users?.map(({ user }: any) => (<div className='receiver' >
                    <UserPicture data={user} />
                </div>))}
                {/* <div className='new' onClick={openModal}>
                    <Icon size={24} name="plus" color={"contentTertiary"} />
                </div> */}
            </div>
        </header>

        <section>

            <Heading size={4}>{entity.data.title}</Heading>

        </section>

    </Container>;
}

export default TeamCard;