import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
   
    display: flex;
  

`;

type FlexProps = {
  children?: any
  gap?: number
  style?: React.CSSProperties
  type?: "default" | "justify"
  marginVertical?: number,
  marginHorizontal?: number,
  justifyContent?: "stretch" | "flex-end" | "flex-start" | "center" | "space-between" | "space-around"
  flex?: any[]
  alignItems?: any
  direction?: "horizontal" | "vertical"
}

const Flex = ({ direction = "horizontal", alignItems, children, justifyContent, flex = [], gap = 0, marginVertical = 0, marginHorizontal = 0, style, type = "default" }: FlexProps) => {
  return <Container style={{ flexDirection: direction === "horizontal" ? "row" : "column", alignItems, justifyContent, margin: `${marginVertical}px ${marginHorizontal}px`, ...style, }}>
    {React.Children.map(children, (item: any, index: any) => (
      <div className='flexitem' style={{ ...(type === "justify" ? ({ flex: 1 }) : ({ flex: flex[index] })), ...(gap > 0 && index > 0) ? (direction === 'horizontal' ? { marginLeft: gap } : { marginTop: gap }) : ({}) }}>
        {item}
      </div>
    ))}
  </Container>;
}

export default Flex;