import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../elements/Button';
import Flex from '../../elements/Flex';
import Heading from '../../elements/Heading';
import { useApi } from '../../hooks/useAuth';
import { IEntityTable } from '../../hooks/useEntity';
import { useModal } from '../../hooks/useModal';
import Table from '../../libraries/table';

const Container = styled.div`

    padding: 20px 60px;

`;


const RoleGrid = ({ Entity }: { Entity: IEntityTable }) => {

    const [data, setData] = useState<any[]>([]);
    const api = useApi();
    const modal = useModal();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const get = async () => {
        const res = await api.get<any[]>("/roles");
        setData(res.data);
    }

    const create = async () => {
        navigate("novo");
    }

    useEffect(() => {
        console.log("get");
        if (Entity.data.length == 0)
            get();
    }, [Entity])


    return <Container>

        <Heading size={1}>Cargos</Heading>

        <Flex style={{ padding: "20px 0px ", gap: 10 }}>
            <Button title="Novo cargo" onClick={create} />
            <Button title="Atualizar" onClick={get} />
        </Flex>

        <Table
            viewPage="/admin/cargos/{{id}}"
            data={data}

            columns={[]}
            definition={[
                {
                    key: "name",
                    name: "Nome",
                    width: 3,
                    type: "string",
                }
            ]}
        />


    </Container>;

}

export default RoleGrid;