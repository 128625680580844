import { ReactNode } from 'react';

import styled from 'styled-components';
import { useModal } from '../hooks/useModal';
import Heading from './Heading';
import Icon, { iconNames } from './Icon';
import Text from './Text';

const Container = styled.div`
 
    position: relative;

    > header{
        padding: 20px 20px 0 20px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    > button.close{
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover{
            background-color: ${p => p.theme.backgroundTertiary};
        }
    }

    > section {
        padding: 20px;
    }
`;

type IModalProvider = {
    children: ReactNode
    title?: string
    icon?: iconNames
    description?: string
    minWidth?: number
    maxWidth?: number
}

const ModalProvider = ({ children, title = "Modal", description, icon, maxWidth = 400, minWidth = 200 }: IModalProvider) => {

    const { close } = useModal();

    return <Container style={{ maxWidth }}>
        <button onClick={close} className='close'>
            <Icon size={20} name="close" color="contentTertiary" />
        </button>
        <header>
            {!!icon && <Icon name={icon} color='blue400' size={32} />}
            <Heading size={2} align='center'>{title}</Heading>
            {!!description && <Text color="contentSecondary" fontSize={14} align='center'>{description}</Text>}
        </header>
        <section>{children}</section>
    </Container>;
}

export default ModalProvider;