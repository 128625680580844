import styled, { useTheme } from 'styled-components';
import Icon from '../Icon';
import Input from './Input';

const Container = styled.div`
   
   display: flex;
   gap: 6px;

   > .selected {
        width: 66px;
        border-radius: 4px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
   }
   > .select {
        width: 66px;
        border-radius: 4px;
        height: 50px;
        border: 1px solid ${p => p.theme.contentTertiary};
        display: flex;
        align-items: center;
        justify-content: center;
   }

    > .colors {

        display: grid;
        grid-template-columns: repeat(9, 1fr);

        > .color {
            cursor: pointer;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            margin-right: 6px;
            margin-bottom: 6px;
            border-width: 1px;
            border-color: ${p => p.theme.backgroundPrimary};
            border-style: solid;
            outline-style: solid;
            outline-width: 1px;
        }
    }
     
`;

const ColorInput = ({ title, required, numberOfLines = 1, password, icon, name, placeholder, showLimit = false, clearErrors, readonly = false, value = "", description, max, onInput, error = false, rows = 1, padding = 5, leftIcon, rightIcon }: any) => {

    const theme = useTheme();

    const colorList = [
        theme.green300,
        theme.blue300,
        theme.purple300,
        theme.pink300,
        theme.yellow300,
        theme.orange300,
        theme.red300,
        theme.gray400,
        theme.white,

        theme.green400,
        theme.blue400,
        theme.purple400,
        theme.pink400,
        theme.yellow400,
        theme.orange400,
        theme.red400,
        theme.gray600,
        theme.black,
    ]

    return <Input {...{ title, required, description }}><Container>

        <div className='selected' style={{ backgroundColor: value }} >
            <Icon name="check" color="backgroundPrimary" size={32} />
        </div>
        {/* <div className='select' >
            <Icon name="pin" color="contentTertiary" size={32} />
        </div> */}


        <div className='colors'>
            {colorList.map(hex => <div onClick={() => onInput(hex)} style={{ backgroundColor: hex, outlineColor: hex == value ? theme.borderSelected : theme.borderOpaque }} className='color' />)}

        </div>

    </Container> </Input>
}


export default ColorInput