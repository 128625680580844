
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../elements/Button';
import Flex from '../elements/Flex';
import Heading from '../elements/Heading';
import Text from '../elements/Text';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;


const ForbiddenPage = () => {

    const navigate = useNavigate();

    return <Container>
        <Heading size={1}>ERROR</Heading>
        <Text>Sem permissão</Text>
        <Flex style={{ marginTop: 10 }}>
            <Button onClick={() => navigate("../")} title="Voltar" />
        </Flex>
    </Container>;
}

export default ForbiddenPage;