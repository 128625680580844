
import styled from 'styled-components';
import PeopleCard from '../../../components/PeopleCard';
import Flex from '../../../elements/Flex';
import Icon from '../../../elements/Icon';
import Text from '../../../elements/Text';

const Container = styled.aside`
    .receiver{
        width: 28px;
        position: relative;
        height: 28px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${attr => attr.theme.purple100};
        
    }
`;


const ReceiversList = ({ receivers }: any) => {

    return <Container>

        {receivers.map(({ user, team }: any) => {
            if (team)
                return <Flex alignItems="center" gap={4}><div className='receiver'>
                    <Icon color={"purple400"} name="serves3" size={20} />
                </div>
                    <Text bold>{team.title}</Text>
                </Flex>
            else if (user) return (<PeopleCard data={user} />)
        })}

    </Container>;
}




export default ReceiversList;