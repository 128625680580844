import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../config';
import Icon from './Icon';
import IconButton from './IconButton';
import Text from './Text';



export const Container = styled.div`

  position: relative;
  


    .button {
   border-radius: 8px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 14px;
    
   
    padding: 0px 30px;
    height: 36px;

   
    font-family: Whitney Bold;
    outline: none;
    color: ${colors.background};
  
    transition: 200ms;

    &:hover{
      opacity: 0.7;
    }
  }

  .context {
    background-color: ${colors.background};
    box-shadow: 0px 0px 15px #0002;
    border-radius: 6px;
    overflow: hidden;
    min-width: 150px;
    position: absolute;
    z-index: 10000;
    top: 100%;
    right: 0;
    padding: 8px;

    li{
      display: flex;
      align-items: center;
      padding: 8px;
      gap: 6px;
      border-radius: 4px;

      &:hover{
        background-color: ${colors.gray100};
      }
    }
  }

`;


const ContextMenu = ({ context, title, onClick, flex, disabled, icon = "more_horizontal", margin = 10, variant = "link", color = colors.primary, submit }: any) => {

  const variants: any = {
    contained: {
      backgroundColor: color,
    },
    link: {
      color,
    }
  }

  const [show, setShow] = useState(false);

  const handleClickOut = () => {
    //setShow(false)

    //document.removeEventListener('click', handleClickOut);
  }

  const toggle = () => {
    setShow(true)
  }

  useEffect(() => {
    if (show)
      document.addEventListener('click', handleClickOut);
  }, [show])

  return <Container style={{ margin: `${margin}px 0`, flex: flex ? 1 : undefined, ...variants[variant], width: flex ? "100%" : 'auto', opacity: disabled ? 0.5 : 1, cursor: disabled ? "progress" : "pointer" }} onClick={onClick}>

    <IconButton icon={icon} color="backgroundPrimary" foreColor='contentTertiary' size={24} onClick={() => setShow(!show)} />

    {show && <div className='context'>
      {context.map((li: any) => (
        <li onClick={li.onClick} key={li.title}>
          <Icon name={li.icon} size={18} color="contentTertiary" />
          <Text align='left' fontSize={14} color="contentTertiary" bold children={li.title} />
        </li>
      ))}

    </div>}

  </Container>
}

export default ContextMenu