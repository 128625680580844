
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import Icon, { iconNames } from '../../../elements/Icon';
import Text from '../../../elements/Text';
import { getContrast2 } from '../../../services/functions';

const Container = styled.div`

    display: flex;
    gap: 8px;
    padding: 16px;
    border-radius: 6px;
    cursor: pointer;
    background-color: ${props => props.theme.backgroundPrimary};
    flex-direction: column;
    box-shadow: 0 1px 4px 0 rgba(0, 8, 38, 0.04);
    border-radius: 10px;
    border: 1px solid transparent;
    transition: 200ms;

    .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: ${p => p.theme.blue400};
        border-radius: 6px;
        flex-shrink: 0;
        transition: 200ms;
    }

    &:hover {
        /* background-color: ${p => p.theme.backgroundTertiary}; */
        border: 1px solid ${p => p.theme.contentTertiary};
        outline: 1px solid ${p => p.theme.backgroundPrimary};
        box-shadow: 0px 0px 0px 3px ${p => p.theme.backgroundTertiary};
     
    }
    
`;




interface IPublicServiceCard {
    data: {
        title: string
        description: string
        id: string
        icon?: iconNames,
        color?: string
    }
}

const SolutionCard = ({
    data: {
        title,
        id,
        description,
        icon = "wrench",
        color = "blue400"
    }
}: IPublicServiceCard) => {

    const theme = useTheme();
    const navigate = useNavigate();

    return <Container onClick={() => navigate("protocolar?servico=" + id)}>
        <div className='icon' style={{ backgroundColor: color }}>
            <Icon name={icon} color={getContrast2(color)} />
        </div>
        <div className='details'>
            <Text bold fontSize={20}>{title}</Text>
            <Text>{description}</Text>
        </div>

    </Container>;
}

export default SolutionCard;