import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import Box from '../../elements/Box';
import Button from '../../elements/Button';
import Heading from '../../elements/Heading';
import ColorInput from '../../elements/inputs/ColorInput';
import TextInput from '../../elements/inputs/TextInput';
import { useApi } from '../../hooks/useAuth';
import { IEntityTable } from '../../hooks/useEntity';
import { useForm } from '../../hooks/useForm';

const Container = styled.div`

    padding: 20px;
/* 
    display: flex;
    align-items: center;
    justify-content: center; */

    .content {
        width: 100%;
        max-width: 800px;
    }

`;


const LabelFormPage = ({ EntityTable }: { EntityTable: IEntityTable }) => {

    const { id } = useParams();
    const Entity = id && EntityTable.GetEntity(id);
    const form = useForm();
    const navigate = useNavigate();
    const theme = useTheme()
    const api = useApi();
    console.log(Entity);

    // const initialize = async () => {
    //     console.log("initializing");
    //     if (id && id == "novo") {
    //         Entity.Create({});
    //         form.setInitialData(Entity.data);
    //     }
    //     else if (id) {
    //         await Entity.Get();
    //         form.setInitialData(Entity.data);
    //     }
    //     // else {
    //     //     Entity.Create();
    //     // }
    //     // await Entity.Get();
    //     // form.setInitialData(Entity.data)
    // }

    const submit = async () => {
        if (id) {
            await api.put("/labels/" + id, form.getFields())

        } else {
            await api.post("/labels", form.getFields())
        }
        navigate("../")
    }

    const get = async () => {
        if (id) {
            const res = await api.get("/labels/" + id);
            form.setInitialData(res.data);
        }
    }


    useEffect(() => {
        get()
    }, [id])

    // useEffect(() => {
    //     if (Entity)
    //         Entity.Update(form.getFields())
    // }, [form])

    useEffect(() => {
        if (Entity)
            form.setInitialData(Entity.data)
        // initialize();
    }, [Entity])


    return <Container>

        <Heading size={1}>{id ? form.fields.name : "Novo marcador"}</Heading>

        <Box>
            <div className='content'>

                <TextInput title="Nome" {...form.registerField("name")} />
                <TextInput numberOfLines={4} title="Descrição" {...form.registerField("description")} />
                <ColorInput title="Cor de fundo" {...form.registerField("color")} />
                <ColorInput title="Cor do texto" {...form.registerField("textColor")} />

                <Button title="Salvar" onClick={submit} />

            </div>
        </Box>


    </Container>;

}

export default LabelFormPage;