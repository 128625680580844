import styled from 'styled-components';
import { colors } from '../config';


const Container = styled.div`
   width: 100%;
   height: 100%;
   display: grid;
   grid-template-columns: repeat(4,1fr);
   grid-auto-rows: 200px;
   gap: 40px;
 

   @keyframes loadingline {
        0%{
            left: -30%;
        }
        100%{
            left: 110%;
        }
   }

   .loading {
        background-color: ${colors.gray50};
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        &::after{
            content: "";
            background-color: ${colors.background};
            filter: blur(10px);
            transform: rotate(-10deg);
            top: -50%;
            width: 30%;
            height: 200%;
            position: absolute;
            animation: loadingline 1s infinite;
        }
   }
`;

const defaultTemplate = [["A A A B"], ["A A A B"], ["C C C C"], ["D D D D"]];

const LoadingContent = ({ template = defaultTemplate }) => {

    return <Container style={{
        gridTemplateAreas: `${defaultTemplate.map(c => `'${c}'`).join(" ")}`
    }}>
        <div style={{ gridArea: "A" }} className='loading' />
        <div style={{ gridArea: "B" }} className='loading' />
        <div style={{ gridArea: "C" }} className='loading' />
        <div style={{ gridArea: "D" }} className='loading' />
    </Container>;
}

export default LoadingContent;