import React, { createContext, forwardRef, memo, ReactNode, useContext, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import config, { colors } from '../config';
import Button from '../elements/Button';
import Flex from '../elements/Flex';
import Heading from '../elements/Heading';
import Text from '../elements/Text';

type open = (component: any, params?: any, options?: {
    width?: number,
    height?: any,
    disableScroll?: boolean
}) => void

export interface ModalContextProps {
    navigate: (name: string, params?: any) => void;
    open: open,
    isOpened: () => boolean,
    context: (props: any) => any,
    register: (params: {
        name: string,
        component: any,
        width?: number,
        height?: number,
        preventClickout?: boolean,
        position?: "center" | "right"
    }) => void;
    close: () => void;
    dialog: (options: { title?: string, description?: string, acceptText?: string, rejectText?: string }) => Promise<any>,
}

interface ModalComponentContextProps {
    open: (component: any, options: any) => void;
    isOpened: () => boolean
    close: () => void;
}


const Context = createContext<ModalContextProps>({
    navigate: () => { },
    open: () => { },
    isOpened: () => false,
    //@ts-ignore;
    context: () => { },
    //@ts-ignore;
    dialog: () => { },
    register: () => { },
    close: () => { },
});


// import { Container } from './styles';

const UseModalProvider = ({ children }: any) => {

    const modals = useRef<any>([]).current;
    const modalRef = useRef<ModalComponentContextProps>(null);
    const dialogRef = useRef<any>(null);
    const contextRef = useRef<any>(null);


    const navigate = (name: string, params?: object) => {

        let modal = modals.find((m: any) => m.name === name);

        if (modal && modalRef.current) {
            let Component = modal.component;
            let modalControls = {
                navigate,
                dialog,
                open,
                close
            }

            modalRef.current.open(<Component modal={modalControls} {...params} />, { ...modal })
        }
    }

    const isOpened = () => {
        return modalRef.current ? modalRef.current.isOpened() : false;
    }

    const open: open = (Component, params, options = { width: 300, height: 'auto', disableScroll: false }) => {

        if (modalRef.current) {
            let modalControls = {
                navigate,
                open,
                dialog,
                close
            }
            console.log(Component)
            modalRef.current.open(typeof Component === 'function' ? Component({ modal: modalControls, ...params }) : {
                ...Component,
                props: { ...Component.props, modal: modalControls }
            }, { ...options })
        }
    }

    const dialog = ({ title, description, acceptText, rejectText }: any) => {

        let promise = new Promise(function (resolve, reject) {
            dialogRef.current.open({ title, description, acceptText, rejectText, onAccept: resolve, onReject: reject })
        });

        return promise

    }

    const context = (props: any) => {
        return contextRef.current.open(props)
    }

    const close = () => {
        if (modalRef.current)
            modalRef.current.close();
        if (contextRef.current)
            contextRef.current.close();
    }

    const register = ({ name, component, width, height, preventClickout, position }: any) => {
        modals.push({ name, component, width, height, preventClickout, position });
    }

    return <Context.Provider value={{ navigate, context, register, open, close, dialog, isOpened }}>
        <ModalComponent ref={modalRef} />
        <DialogComponent ref={dialogRef} />
        <ContextMenuComponent ref={contextRef} />
        {children}
    </Context.Provider>;
}

// import { Container } from './styles';


export type AlertProps = {

    title?: string,
    description?: string,
    picture?: any,
    buttons?: [{
        title?: string,
        onPress?: () => any
    }],
    disableClose?: boolean,
    footerContainer?: React.FC,
    closeButton?: string

}

const Container = styled.div`

z-index: 1000;

> .content {

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;

    @media(max-width: 600px){
        padding: 20px;
    }

    .background {
        width: 100%;
        height: 100%;
        background-color: ${p => p.theme.backgroundInversePrimary};
        opacity: 0.75;
        z-index: -1;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        animation: bg 200ms;
        display: flex;
        align-items: center;
    }
    .windows {

        transition: 200ms;
        background-color: #fff;
        max-height: 100%;
        animation: scaleanimation 100ms ease-in-out;
        box-shadow: ${config.shadow.high};
        border-radius: 14px;
        overflow: hidden;
        transition: 200ms;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

    }
     .window {
        width: 100%;
        /* position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0; */
        overflow-y: auto;
        flex: 0 0 100%;
        transition: 200ms;
        /* transition: 200ms; */
        /* &:last-child{
            animation: last 200ms;
            transform: translateX(0%)
        }
        &:not(:last-child){
            animation: notlast 200ms;
            transform: translateX(-100%)
        } */


      
      }


      &.pos-right {
        justify-content: flex-end;
        padding: 0px;

        .windows {
            animation: slide 100ms ease-in-out;
            border-radius: 14px 0px 0px 14px;
            height: 100%;
        }
      }

    }

      @keyframes bg {
          0%{
              opacity: 0;
          }
      }
      @keyframes scaleanimation {
          0%{
              transform: scale(0.75)
          }
          100%{
              transform: scale(1)
          }
      }
      @keyframes slide {
            0%{
                transform: translateX(100%)
            }
      }
      @keyframes slideout{
            100%{
                transform: translateX(100%)
            }
      }

      @keyframes last{
            0%{
                transform: translateX(100%)
            }
            100%{
                transform: translateX(0%)
            }
      }
      @keyframes notlast{
            0%{
                transform: translateX(0%)
            }
            100%{
                transform: translateX(-100%)
            }
      }


`;

const ContextMenuContainer = styled.div`

    /* top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1000;
    overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center; */

    /* .background {
        width: 100%;
        height: 100%;
        background-color: #0005;
        z-index: -1;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        animation: bg 200ms;
       
    }
  */
     .window {

        position: absolute;
        z-index: 1000;
        
/*         
        width: 350px;
        height: 150px;
        top: 50px; */
        //transition: 500ms;
        /* opacity: 0; */
        /* 
        background-color: #fff;
        border-radius: 10px ;
        box-shadow: 0 4px 17px 6px rgb(0 0 0 / 7%);
        padding: 16px;

        display: flex;
        flex-direction: column;

       */
      
      }

      @keyframes bg {
          0%{
              opacity: 0;
          }
      }
  

`;

const ContextMenuComponent = forwardRef(({ }, ref) => {

    const [visible, setVisible] = useState(false);
    const [options, setOptions] = useState<any>({ bounding: {} });

    var context = useRef({}).current;

    // const [modalOptions,setModalOptions] = useState<any>({});

    const open = (opt: any) => {

        context = { destroy: close };

        setVisible(true)

        const element: HTMLDivElement = opt.target;
        const bounding = element.getBoundingClientRect();
        console.log(bounding)

        setOptions({ ...opt, bounding });

        return context;
        //setModalOptions(modaloptions)
    }

    const close = () => {
        setVisible(false);
        if (options.onClose)
            options.onClose();
    }



    useImperativeHandle(ref, () => ({
        open, close
    }))

    const containerRef = useRef<HTMLDivElement>(null);

    // if(childrens.length === 0)
    //   return <></>



    function posicionarMenuFlutuante(target: HTMLDivElement, menu?: HTMLDivElement) {

        if (!menu)
            return;

        // Obtém as dimensões do menu e do target
        const menuRect = menu.getBoundingClientRect();
        const targetRect = target.getBoundingClientRect();

        // Obtém as dimensões da tela
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        const diffWidth = menuRect.width - targetRect.width;

        // Calcula a posição ideal do menu
        let left = targetRect.left + window.scrollX - (diffWidth / 2); // Posição X do target
        let top = targetRect.bottom + window.scrollY; // Posição Y do target

        // Verifica se o menu ultrapassa a largura da tela
        if (left + menuRect.width > screenWidth) {
            left = screenWidth - menuRect.width;
        }

        // Verifica se o menu ultrapassa a altura da tela
        if (top + menuRect.height > screenHeight) {
            top = targetRect.top - menuRect.height;
        }

        console.log(left, top)

        // Define a posição do menu
        menu.style.left = left + 'px';
        menu.style.top = top + 'px';
    }

    // useEffect(() => {
    //     if (containerRef.current)
    //         posicionarMenuFlutuante(options.target, containerRef.current);
    // }, [containerRef])

    const onClick = (e: any) => {
        if (!e.target.closest("#contextWindow"))
            close();
    }

    React.useEffect(() => {
        document.addEventListener("click", onClick);
        return () => {
            document.removeEventListener("click", onClick);
        }
    }, [])

    if (!visible)
        return <></>

    return <ContextMenuContainer  >

        <div className="window" id="contextWindow" ref={(ref) => ref ? posicionarMenuFlutuante(options.target, ref) : undefined}>
            {options.children}
        </div>

        {/* <div className="background" onClick={close} /> */}


    </ContextMenuContainer>
})

//#region dialog

const ContainerDialog = styled.div`

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1000;
    overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

    .background {
        width: 100%;
        height: 100%;
        background-color: #0005;
        z-index: -1;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        animation: bg 200ms;
       
    }
 
     .window {
        
        width: 350px;
        height: 150px;
        top: 50px;
        transition: 500ms;
        /* opacity: 0; */
        position: absolute;
        background-color: #fff;
        border-radius: 10px ;
        box-shadow: 0 4px 17px 6px rgb(0 0 0 / 7%);
        padding: 16px;

        display: flex;
        flex-direction: column;

        .header {
            flex: 1;
            
        }
      
      
      }

      @keyframes bg {
          0%{
              opacity: 0;
          }
      }
  

`;

const DialogComponent = forwardRef(({ }, ref) => {

    const [visible, setVisible] = useState(false);
    const [options, setOptions] = useState<any>({});
    // const [modalOptions,setModalOptions] = useState<any>({});

    const open = (opt: any) => {

        setVisible(true)
        setOptions({
            title: opt.title || "Titulo",
            description: opt.description,
            acceptText: opt.acceptText || "Ok",
            rejectText: opt.rejectText || "Cancelar",
            onAccept: opt.onAccept,
            onReject: opt.onReject
        });
        //setModalOptions(modaloptions)
    }

    const close = () => {
        setVisible(false);

    }

    const handleReject = () => {
        close()
        options.onReject();
    }

    const handleAccept = () => {
        close()
        options.onAccept();
    }

    useImperativeHandle(ref, () => ({
        open
    }))

    const containerRef = useRef<HTMLDivElement>(null);

    // if(childrens.length === 0)
    //   return <></>

    if (!visible)
        return <></>

    return <ContainerDialog ref={containerRef} >

        <div className="window" >
            <div className='header'>
                <Heading title={options.title} bold size={4} />
                <Text children={options.description} bold fontSize={16} color={colors.gray500} />
            </div>
            <div className='content'>
                <Flex gap={10} type="justify">
                    <Button flex onClick={handleReject} color={colors.red400} title={options.rejectText} variant="outline" />
                    <Button flex onClick={handleAccept} title={options.acceptText} variant="contained" />
                </Flex>
            </div>
        </div>

        <div className="background" onClick={close} />


    </ContainerDialog>
})

//#endregion




const ModalComponent = forwardRef(({ }, ref) => {

    const [visible, setVisible] = useState(false);
    const [childrens, setChildrens] = useState<any[]>([]);
    // const [modalOptions,setModalOptions] = useState<any>({});

    const open = (options: any, modaloptions: number) => {
        containerRef.current?.classList.add("open")
        setVisible(true)
        setChildrens([...childrens, {
            component: options,
            options: modaloptions
        }])
        //setModalOptions(modaloptions)
    }

    const isOpened = () => childrens.length > 0

    const update = (options: any) => {

    }

    const close = () => {



        if (childrens.length - 1 <= 0)
            containerRef.current?.classList.remove("open")

        setChildrens(state => [...state.filter((_, i) => i < childrens.length - 1)]);

        // setTimeout(() => {
        //   if(childrens.length -1 <= 0)
        //     setVisible(false);
        // },200);

    }

    useImperativeHandle(ref, () => ({
        open, close, update, isOpened
    }))

    const containerRef = useRef<HTMLDivElement>(null);


    // if(childrens.length === 0)
    //   return <></>
    let options = childrens.length > 0 ? childrens[childrens.length - 1].options : undefined
    let component = childrens.length > 0 ? childrens[childrens.length - 1].component : undefined

    if (childrens.length === 0)
        return <></>


    return <>{childrens.map((c: any, i: any) => <Container ref={containerRef}>

        {c.options.position === "right" ?
            <div className='content pos-right' style={{ opacity: i === childrens.length - 1 ? 1 : 0.4 }} >
                <div className='windows' style={{ maxWidth: c.options ? c.options.width : 500 }}>
                    <Window component={c.component} />
                </div>
                <div className="background" onClick={options.preventClickout ? () => { } : close} />
            </div>
            : <div className='content' style={{ opacity: i === childrens.length - 1 ? 1 : 0.4 }} >
                <div className='windows' >
                    <Window component={c.component} />
                </div>

                <div className="background" onClick={options.preventClickout ? () => { } : close} />

            </div>
        }

    </Container>)}</>
})

// const Window = ({c,style}:any) => {
//     return  <div style={style} className="window" >
//     {(c.component) && c.component}
//   </div>
// }
const Window = memo(({ component, ...props }: any) => {
    return <div className="window" {...props}>
        {component}
    </div>
})


const Modal = ({ name, position = "center", preventClickout = false, component, width, height }: { width?: number, preventClickout?: boolean, height?: number, disableScroll?: any, name: string, component: ReactNode, position?: "center" | "right" }) => {

    const { register } = useContext(Context);

    register({
        name, component, width, height, preventClickout, position
    })

    return <></>

}

export const useModal = () => {

    const modalOptions = useContext(Context);
    return modalOptions

};


export { Modal, ModalComponent };
export default UseModalProvider;