import styled, { useTheme } from 'styled-components';
import { colors } from '../config';
import { getContrast2, shadeColor } from '../services/functions';
import Icon, { iconNames } from './Icon';
import Text from './Text';

const Container = styled.button<any>`
   
 //  width: 100%;
 
    user-select: none;
    transition: background 20ms ease-in 0s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 3px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.2;
    position: relative;
    gap: 6px;
    color: ${props => props.theme[props["attr-color"]]};
    //max-width: calc(100% - 28px);
    font-weight: 500;
    
    cursor: pointer;

    &:hover {
        background-color: ${(props: any) => shadeColor(String(props["attr-color"]).startsWith("#") ? props["attr-color"] : props.theme[props["attr-color"]], -10)} !important;
    }

    >.alerts {
        min-width: 16px;
        padding: 0 3px;
        height: 16px;
        position: absolute;
        border-radius: 8px;
        background-color: ${colors.red400};
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0px;
        bottom: 0px;
    }
`;

type IconButtonProps = {
    description?: string,
    onClick?: any,
    icon: iconNames,
    color?: string
    foreColor?: string,
    withBorder?: boolean
    alerts?: number
    size?: number
}

const IconButton = ({ alerts = 0, description, onClick, icon, color = "backgroundPrimary", foreColor, withBorder = false, size = 24 }: IconButtonProps) => {

    const theme = useTheme();

    if (!foreColor)
        foreColor = getContrast2(theme[color])

    return <Container title={description} attr-color={color} attr-forecolor={foreColor} attr-hover-color={shadeColor(color, -10)} onClick={onClick} style={{
        backgroundColor: theme[color],

        ...(withBorder ? {
            borderColor: shadeColor(color, -15),
            borderWidth: 1,
            borderStyle: "solid"
        } : {}),

        width: size + 8,
        height: size + 8
    }}>
        {!!icon && <Icon name={icon} color={foreColor} size={size} />}
        {alerts > 0 && <div className='alerts'>
            <Text fontSize={12} color={foreColor} bold>{alerts}</Text>
        </div>}
    </Container>;
}

export default IconButton;