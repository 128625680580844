
import styled from 'styled-components';
import PeopleCard from '../../../components/PeopleCard';
import ContextMenu from '../../../elements/ContextMenu';
import Text from '../../../elements/Text';
import { useEntity } from '../../../hooks/useCrud';
import { formatTimeWithExtension } from '../../../services/functions';

const Container = styled.tr`
    padding: 6px;
`;

const UserRow = ({ data, actions }: any) => {

    const entity = useEntity(data, {});

    return <Container>
        <td><PeopleCard data={data} /></td>
        <td><Text>{formatTimeWithExtension(data.createdAt)}</Text></td>
        <td>
            <ContextMenu context={[
                {
                    icon: "trash",
                    title: "Deletar",
                    onClick: async () => {
                        await entity.destroy();
                    }
                }
            ]} />
        </td>
    </Container>;
}

export default UserRow;