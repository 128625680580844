import styled, { useTheme } from 'styled-components';
import { getContrast2, shadeColor } from '../services/functions';
import Icon from './Icon';
import Text from './Text';

const Container = styled.div`
   
 //  width: 100%;
 
    user-select: none;
    transition: background 20ms ease-in 0s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 3px;
    height: 32px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.2;
   
    gap: 6px;
    min-width: 32px;
    //max-width: calc(100% - 28px);
    font-weight: 500;
    
    cursor: pointer;

    &:hover {
        background-color: ${(props: any) => props['attr-hover-color']} !important;
    }
`;

const Button = ({ title, onClick, align = "center", icon,
    color = "backgroundPrimary",
    foreColor,
    withBorder = true, showTitle = true }: any) => {

    const theme = useTheme();
    const colorHex = theme[color];

    return <Container title={title} attr-hover-color={shadeColor(colorHex, -10)} onClick={onClick} style={{
        backgroundColor: colorHex,

        ...(withBorder ? {
            borderColor: shadeColor(colorHex, -15),
            borderWidth: 1,
            borderStyle: "solid"
        } : {}),

        width: (!title || !showTitle) ? 32 : undefined
    }}>
        {!!icon && <Icon name={icon} color={foreColor || getContrast2(colorHex)} size={18} />}
        {(!!title && showTitle) && <Text lineHeight={1.0} nowrap fontWeight={500} align={align} color={foreColor || getContrast2(colorHex)} fontSize={14} bold children={title} />}
    </Container>;
}

export default Button;