
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Icon from '../elements/Icon';
import Text from '../elements/Text';
import { useApi } from '../hooks/useAuth';

const Container = styled.div`

    > .button{
        width: 32px;
        height: 32px;
        border-radius: 6px;
        cursor: pointer;
        border: 1px solid ${p => p.theme.borderOpaque};
        background-color: ${p => p.theme.backgroundPrimary};
    }

    > .popup {

        padding: 12px;

        position: absolute;
        transform: translate3d(0px, 32px, 0px);
        top: 0px;
        left: 0px;
        will-change: transform;

        display: block;
        min-height: 40px;
        max-height: 445px;
        overflow-y: auto;

        position: absolute;
        width: auto;
        z-index: 300;
        min-width: 240px;
        max-width: 500px;
        margin-top: 4px;
        margin-bottom: 24px;
        font-size: 0.875rem;
        font-weight: 400;
        background-color: #fff;
        border: 1px solid ${p => p.theme.borderOpaque};
        border-radius: 0.25rem;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }

    .search {
        border: 1px solid ${p => p.theme.borderOpaque};
        background-color: ${p => p.theme.backgroundPrimary};
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding-left: 6px;

        input {
            padding: 6px;
            outline: none;
        }

    }

        li {
            display: flex;
            align-items: center;
            gap: 4px;
            height: 32px;
            padding: 0px 8px;
           // background-color: ${p => p.theme.backgroundSecondary};
            border-radius: 4px;
            cursor: pointer;

            .icon {
                width: 24px;
            }

            &.selected{
                border: 2px solid ${p => p.theme.borderSelected};
            }

            &:hover{
                background-color: ${p => p.theme.backgroundTertiary};
            }

        }
`;

interface IConnectionGrid {
    url: string
    onInput: any
    labelKey?: string
    value: IItem[]

    keyObject?: string
    keyPath?: string
}


type IItem = { [key: string]: any } | IItemBase

type IItemBase = {
    _action?: "delete" | "create" | "update"
    id: string
}

const DEFAULTTIME = 2000; //MS

const SelectionButton = ({ url, onInput, value = [], keyPath = "userId", keyObject = "user", labelKey = "name" }: IConnectionGrid) => {

    const [show, setShow] = useState(false);
    const [event, setEvent] = useState<DOMRect | null>(null);

    //const [time,setTime] = useState(DEFAULTTIME);
    var time = useRef(DEFAULTTIME).current;

    const buttonRef = useRef<HTMLButtonElement>(null);

    const [search, setSearch] = useState("");
    const [data, setData] = useState<any>([]);
    const api = useApi();


    const get = async () => {
        const res = await api.get(url);
        if (res.data)
            setData(res.data);
    }

    const handleRemove = (item: IItem) => {

        const index = value.indexOf(item);
        let oldvalue = value;

        if (index == -1) return;

        if (item._action && item._action == "create") {
            oldvalue.splice(index, 1);
        }
        else {
            oldvalue[index] = { ...item, _action: "delete" };
        }

        onInput([...oldvalue])
    }

    const handleInsert = (data: any) => {

        let oldvalue = value;
        //@ts-ignore
        let find = oldvalue.find(d => d[keyPath] == data.id);


        if (find) {
            let index = oldvalue.indexOf(find);
            if (find._action == "delete") {
                oldvalue[index]._action = null
            }
            else if (find._action && find._action == "create") {
                oldvalue.splice(index, 1);
            }
            else {
                oldvalue[index] = { ...find, _action: "delete" };
            }
        }
        else {
            oldvalue.push({
                [keyObject]: data,
                [keyPath]: data.id,
                _action: "create"
            })
        }

        onInput([...oldvalue])



    }

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if(time > 0){
    //             time -= 50;
    //         }

    //      }, 50)
    //      return () => clearInterval(interval); 
    // }, []) 


    useEffect(() => {
        get();
    }, [search])

    return <Container>

        <button ref={buttonRef} className='button' onClick={(e) => setEvent(event ? null : (buttonRef.current?.getBoundingClientRect() || null))}>
            +
        </button>

        {!!event && <div className='popup' style={{
            left: event.left,
            top: event.top
        }}>

            <div className='search'>
                <input placeholder='Pesquisar...' value={search} onInput={e => setSearch(e.currentTarget.value)} />
            </div>


            <ul className='all'>

                {data.map((d: any) => <li
                    onClick={() => handleInsert(d)}>
                    <div className='icon'>
                        {!!value.find((v: any) => v[keyPath] == d.id) && <Icon name='check' />}
                    </div>
                    <Text bold>{d[labelKey]}</Text>
                </li>)}

            </ul>


            {/* 
            <Heading size={4}>Selecionados</Heading>

            <ul className='all'>

                {value.map((d: any) => <li>
                    <Flex alignItems="center">
                        <Text bold>{d[keyObject][labelKey]}</Text>
                        <Text style={{ paddingLeft: 6 }} bold color={
                            inlineSwitch(d._action, ["create", "blue400", "update", "orange400", "delete", "red400"], "contentPrimary")
                        }>{d._action}</Text>
                        <IconButton icon="trash" size={18} onClick={() => handleRemove(d)} />
                    </Flex>
                </li>)}

            </ul> */}


        </div>}
    </Container>;
}

export default SelectionButton;