import { useRef, useState } from "react";
import styled from "styled-components";
import { useApi } from "../../hooks/useAuth";
import { useModal } from "../../hooks/useModal";
import Flex from "../Flex";
import Icon from "../Icon";
import Text from "../Text";

const SelectInputContainer = styled.div`

    @keyframes iconAnimation {
        
        50% {
            transform: translateY(2px);
        }
    }

    .select {
        display: flex;
        align-items: center;
        gap: 6px;
        height: 33px;
        padding: 0 8px;
        border-bottom: 1px solid ${p => p.theme.borderTransparent};
        outline: none;
        position: relative;
        cursor: pointer;

        &:hover {
                background-color: ${p => p.theme.backgroundSecondary};

            .icon {
                animation: iconAnimation 500ms infinite;
            }
        }

        > .display {
            width: 100%;

           
        }

        > .options {
            position: absolute;
            top: 100%;
            border-radius: 8px;
            border: 1px solid ${p => p.theme.backgroundSecondary};
            box-shadow: 0px 0px 15px 0px #00000010;
            width: 100%;
            padding: 4px;
            

            .option {
                display: flex;
                align-items: center;
                gap: 6px;
                height: 33px;
                padding: 0 8px;
                border-radius: 6px;
               &:hover{ 
                background-color: ${p => p.theme.backgroundTertiary};
               }
            }
         

        }


   }
`;

const SelectInput = ({ value, onInput, options, relation, renderRelationTitle, lookupUrl }: any) => {

    const [visibility, setVisibility] = useState(false);
    const ref = useRef<any>(null);
    const api = useApi();

    const modal = useModal();

    const selected = options.find((o: any) => o.name == value);

    console.log(options, value)

    const hidden = () => {
        setVisibility(false);
        document.removeEventListener("click", hidden)
    }

    const show = (e: any) => {
        // modal.context({
        //     target: e.currentTarget,
        //     children: <div className='options'>
        //         {options.map((option: any) => (<div
        //             onClick={() => { onInput(option.name); hidden(); }}
        //             className='option' >
        //             <Text children={option.title} color={option.color} fontSize={16} font="Barlow" />
        //         </div>))}
        //     </div>
        // })
        modal.open(<div className='options'>
            {options.map((option: any) => (<div
                onClick={() => { onInput(option.name); hidden(); }}
                className='option' >
                <Text children={option.title} color={option.color} fontSize={16} font="Barlow" />
            </div>))}
        </div>)
        setVisibility(!visibility);
        //  document.addEventListener("click", hidden)
    }


    return <SelectInputContainer >

        <div className='select' >

            <div className='display' onClick={show}>
                <Flex flex={[1, 0]} alignItems="center" >
                    <div>
                        {selected
                            ? <Text children={(selected.title)} fontSize={16} font="Barlow" />
                            : <Text children={"Selecione"} color={"gray300"} fontSize={16} font="Barlow" />}
                    </div>
                    <div className='icon'>
                        <Icon name="arrow-down" color={"gray500"} />
                    </div>
                </Flex>
            </div>

            {/* {visibility && } */}

        </div>
    </SelectInputContainer>
}

export default SelectInput