import { Editor } from '@tiptap/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import TextEditor, { useTextEditor } from '../../components/TextEditor';
import Button from '../../elements/Button';
import Flex from '../../elements/Flex';
import Heading from '../../elements/Heading';
import Icon from '../../elements/Icon';
import Loading from '../../elements/Loading';
import Text from '../../elements/Text';
import { useApi, useAuth } from '../../hooks/useAuth';
import { useEntityTable } from '../../hooks/useEntity';
import { useStateForm } from '../../hooks/useStateForm';
import { getContrast2 } from '../../services/functions';


const Container = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${p => p.theme.backgroundSecondary};

    .content {
        max-width: 980px;
        width: 100%;
    }

.separator {
                margin: 0 20px;
                height: 18px;
                width: 2px;
                background-color: ${props => props.theme.title300};
            }
            
    .box{
        background-color: ${p => p.theme.backgroundPrimary};
        border-radius: 8px;
        border: 1px solid ${props => props.theme.borderTransparent};
       
        box-shadow: 0px 0px 30px 0px #0001;

      

        > header {
            border-radius: 8px 8px 0 0 ;
            background-color: ${props => props.theme.title50};
            border-bottom: 1px solid ${props => props.theme.borderTransparent};
            align-items: center;
            display: flex;

            > .type {
                margin-left: 16px;
                padding: 4px 8px;
                border-radius: 4px;
                background-color: ${props => props.theme.blue100};
            }

            input {
                flex: 1;
            }

           
        }
        > section.sender {
            padding: 16px 16px 0px 16px;
        }
        section.replyTo {
            padding: 16px 16px 0px 16px;
            display: flex;
            gap: 10px;
        }
        section.sendTo {
            padding: 16px 16px 0px 16px;
            display: flex;
            gap: 10px;
        }
        section.body {
            /* padding: 0 24px 24px 24px; */
            > .tools {

                border-bottom: 1px solid ${p => p.theme.borderOpaque};
                padding: 6px;

                >.left{
                    display: flex;

                    > button {
                        width: 26px;
                        height: 26px;
                        padding: 4px;
                        cursor: pointer;

                        &:hover{
                            background-color: ${p => p.theme.backgroundSecondary};
                        }

                        &.active {
                            background-color: ${p => p.theme.backgroundTertiary};
                        }
                    }

                }
            }
            
        }
        footer {
            padding: 0px 24px 24px 24px;
            display: flex;
            gap: 10px;
        }
    }

    .editable {
        outline: none;
        padding: 20px;

       
    }

    .tools {
                display: flex;

                > .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 32px;
                    height: 32px;
                    padding: 0px 4px;
                    border-radius: 4px;
                    gap: 4px;
                    cursor: pointer;

                    >.counter {

                    }

                    &:hover{
                        background-color: ${props => props.theme.title100};
                    }
                }
            }
    
    .titleInput{
        outline: none;
        padding: 20px;
        font-size: 18px;

    }

    .files {
        display: flex;
        padding: 16px;
        padding-top: 0px;
        .file {
            padding: 4px 8px;
            border: 1px solid ${props => props.theme.borderOpaque};
            border-radius: 6px;
        }
    }

    .service {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > .icon {
            width: 48px;
            height: 48px;
            border-radius: 0 0 8px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        > .details {
            padding: 16px;
        }

     
    }

    .back {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${p => p.theme.backgroundPrimary};
        border-radius: 21px;
        position: fixed;
        left: 16px;
        border: 1px solid ${props => props.theme.borderTransparent};
        box-shadow: 0px 0px 30px 0px #0001;
        top: 16px;
        cursor: pointer;

        &:hover{
            background-color: ${p => p.theme.backgroundTertiary};
        }
    }
  

`;

function removerTagsHTML(content: any = []) {
    if (!content)
        return ""


    return content.reduce((str: string, c: any) => c.content ? removerTagsHTML(c.content) + str : str + (c.text || (c.attrs?.label ? "@" + c.attrs?.label : undefined) || ""), "")
}

const DocumentCreate = ({ }: {}) => {

    const editor = useTextEditor({
        editable: true, content: [
            { type: "paragraph", content: [{ type: "text", text: "" }] }
        ]
    });

    const EntityDocument = useEntityTable("documents");
    const EntityService = useEntityTable("services");
    const service = EntityService.entity?.data;

    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const serviceId = searchParams.get("servico");
    const navigate = useNavigate();
    const form = useStateForm();
    const api = useApi();
    const theme = useTheme();
    const auth = useAuth();
    const [loading, setLoading] = useState("");
    const [repling, setRepling] = useState(false);
    const [files, setFiles] = useState<any[]>([]);
    const ref = useRef<any>(null);

    const [showing, setShowing] = useState<string[]>([]);

    const submit = async () => {
        const fields = form.getFields();
        setLoading("MAIL");

        // if (!to.length) {
        //     alert("Selecione pelo menos um destinatário")
        //     return;
        // }

        const res = await api.post("/documents", {
            title: fields.title || "Sem título",
            // to,
            serviceId: service.id,
            files: files.reduce((arr, f) => [...arr, f.id], []),
            content: editor?.getJSON().content
        })
        if (res.status == 200) {
            navigate("/")
        }
        setLoading("")
    }

    const toggleShow = (id: string) => {
        if (showing.includes(id))
            setShowing([...showing.filter(s => s != id)])
        else
            setShowing([...showing, id])
    }


    const getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result)
            };
            reader.onerror = function (error) {
                reject()
            };
        });
    }

    const handleFileInput = async (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            const file = e.currentTarget.files[0];
            const base64 = await getBase64(file);

            const res = await api.post("/mails/file", { base64 })

            setFiles([...files, res.data])

            // e.currentTarget.files = null;

        }
    }

    useEffect(() => {
        if (serviceId) {
            EntityService.Get(serviceId);
            // searchParams.delete("servico");
            // setSearchParams(searchParams);
        }
    }, [searchParams])

    const upgrateText = async () => {

        let editor: Editor = ref.current.getEditor();

        const res = await api.post("/ai/upgrade/document", {
            text: removerTagsHTML(editor.getJSON().content)
        })
        editor.commands.clearContent();
        editor.commands.insertContent([
            { type: 'text', text: res.data.text },
        ])
    }

    const toggleBulletList = () => {
        editor?.commands.toggleBulletList();
    }
    const toggleOrderedList = () => {
        editor?.commands.toggleOrderedList();
    }



    const ToolButton = useCallback(({ tag, action, icon }: any) => {
        if (editor?.isActive(tag))
            return <button className='tool-button active' onClick={action}>
                <Icon name={icon} size={18} />
            </button>

        return <button className='tool-button' onClick={action}>
            <Icon name={icon} size={18} color="contentSecondary" />
        </button>

    }, [editor]);

    if (!service)
        return <></>


    return <Container>

        <div className='back' onClick={() => navigate("/")}>
            <Icon size={32} name="arrow-left" />
        </div>

        <div className='content'>

            <div style={{
                width: "100%",
                height: 6,
                borderRadius: "0 0 6px 6px",
                backgroundColor: service.color
            }} />

            <div className='service'>
                <div className='icon' style={{ backgroundColor: String(service.color).startsWith("#") ? service.color : theme[service.color] }}>
                    <Icon name={service.icon} color={getContrast2(theme[service.color])} />
                </div>
                <div className='details'>
                    <Text align='center' bold fontSize={24}>{service.title}</Text>
                    <Text align='center'>{service.description}</Text>
                </div>
            </div>

            {/* <Flex>
            <QRCode value={document.location.href} size={64} />
        </Flex>

        <div style={{ height: 20 }} /> */}


            <Heading size={4}>Descrição:</Heading>


            <div className='box'>
                {/* <header> */}
                {/* <div className="type">
                    <Flex alignItems="center">
                        <Text color="blue400" bold fontSize={14}>{EntityService.entity?.data?.title}</Text>
                        <Icon color="blue400" size={18} name="arrow-down" />
                    </Flex>
                </div>
                <input value={form.fields.title} onInput={e => form.setField("title", e.currentTarget.value)} className="titleInput" placeholder='Digite o titulo...' />
            </header> */}



                <section className='body'>

                    {editor && <div className='tools'>
                        <div className='left'>
                            <ToolButton tag="bold" icon="bold" action={() => editor.chain().focus().toggleBold().run()} />
                            <ToolButton tag="italic" icon="italic" action={() => editor.chain().focus().toggleItalic().run()} />
                            <ToolButton tag="underline" icon="underline" action={() => editor.chain().focus().toggleUnderline().run()} />
                            <ToolButton tag="strike" icon="strike" action={() => editor.chain().focus().toggleStrike().run()} />
                            <ToolButton tag="quote" icon="quote" action={() => editor.chain().focus().toggleBlockquote().run()} />
                            <ToolButton tag="code" icon="code" action={() => editor.chain().focus().toggleCode().run()} />
                            <ToolButton tag="link" icon="link" />
                            <ToolButton tag="bulletList" icon="list-bulleted" action={() => editor.chain().focus().toggleBulletList().run()} />
                            <ToolButton tag="orderedList" icon="list-numbered" action={() => editor.chain().focus().toggleOrderedList().run()} />

                            {/* <ToolButton tag="img" icon="no_image" action={() => editor?.commands.setImage({
                                src: "https://st4.depositphotos.com/14431644/22076/i/450/depositphotos_220767694-stock-photo-handwriting-text-writing-example-concept.jpg"
                            })} />

                            <div style={{ position: 'relative' }}>
                                <input onInput={handleFileInput} type='file' style={{ opacity: 0, zIndex: 2, position: "absolute", width: 32, height: 32 }} />
                                <ToolButton
                                    icon="attach"
                                    tag="file"
                                />
                            </div> */}

                        </div>
                    </div>}

                    <div className='editor'>
                        {editor && <TextEditor editor={editor} />}
                    </div>
                </section>

                {/* <footer style={{ alignItems: "center", justifyContent: "flex-start" }}>
                    <div>
                        <Text fontSize={26} font="Ephesis" align='center'>{auth.user.name}</Text>
                        <div style={{ minWidth: 200, height: 1, backgroundColor: theme.contentInverseTertiary }} />
                        <Text fontSize={12} align='center'>Ass. Solicitante</Text>
                    </div>
                </footer> */}


                <section className='files'>
                    {files.map(file => <div className='file'>
                        <Flex>
                            <Icon name="attach" />
                            <Text>{file.url}</Text>
                        </Flex>
                    </div>)}
                </section>
                <footer style={{ alignItems: "center" }}>

                    <Flex>




                        {/* <IconButton
                        icon="attach"
                        color="backgroundPrimary"
                        foreColor='contentTertiary'
                    />

                    <IconButton
                        onClick={() => {
                            ref.current.getEditor().chain().focus().setMark("link");
                        }}
                        icon="link"
                        color="backgroundPrimary"
                        foreColor='contentTertiary'
                    /> */}
                    </Flex>


                </footer>





            </div>
            <Flex style={{ padding: 16 }} justifyContent="flex-end">
                {loading == "MAIL" ? <Loading /> : <Button
                    // foreColor={service.color}
                    color={"backgroundTertiary"}
                    icon="send"
                    title="Protocolar"
                    onClick={submit} />}
            </Flex>
        </div>
    </Container>;
}



export default DocumentCreate;