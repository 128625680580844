// import DotObject from '../services/dot-object';

import { createContext, useCallback, useEffect, useState } from "react";
import { useApi } from "./useAuth";

// import { Container } from './styles';


export type IEntity = {
    id?: string
}

type IOptions = {
    autoRequest?: boolean
}

export type IuseCrud = {
    url?: string
    options?: IOptions
}

const DEFAULT_OPTIONS: IOptions = {
    autoRequest: true
}


const Context = createContext<any>({

});

export const CrudProvider = ({ children }: any) => {
    return <Context.Provider value={{}}>{children}</Context.Provider>
}


export const useEntity = <T extends IEntity>(e: T, { url }: any) => {

    const [state, setState] = useState<T | null>(e);
    const api = useApi();

    const Get = async (id: string) => {
        const res = await api.get<T>(url + "/" + id);
        setState(res.data)
    }

    const destroy = async () => {
        if (!state?.id)
            return;

        const res = await api.delete<T>(url + "/" + state.id);
        setState(null);
    }

    return ({ data: state, Get, destroy })

}

export const useCrud = <T extends IEntity>({
    url = "/",
    options = DEFAULT_OPTIONS
}: IuseCrud) => {

    const [list, setList] = useState<Entity<T>[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const api = useApi();

    const registerField = useCallback((name: string, entity: Entity<any>) => {

        var value = entity.data[name];

        const onInput = (value: any, returnValue: any = value) => {

            entity.SetValue(name, value)

        }

        return ({ onInput, error: null, name, defaultValue: value, value })

    }, [list])

    class Entity<T extends IEntity> {

        id?: string;
        data: T;
        isEditing = false;
        isCreating = false;
        index = -1;

        constructor(e: T) {
            this.id = e.id;
            const entity = list.find(e => e.id == this.id);
            this.index = entity ? list.indexOf(entity) : -1;
            this.data = e;
            // if (this.index >= 0) {
            //     //@ts-ignore
            //     this.data = list[this.index];
            // }
        }

        RegisterField = (name: any) => {
            return {
                //@ts-ignore
                value: this.data[name],
                onInput: (value: any) => {
                    this.SetValue(name, value)
                }
            }
        }

        Update = async (newData: any) => {

            setList(s => {
                s.forEach((c) => {
                    if (c.id == this.id) {
                        c.data = { ...this.data, ...newData }
                    }
                    return c;
                })
                return [...s]
            })

        }

        Get = async () => {

            const res = await api.get<any>(url + "/" + this.id);

            setList(s => {
                s.forEach((c) => {
                    if (c.id == this.id) {
                        c.data = { ...this.data, ...res.data }
                    }
                    return c;
                })
                return [...s]
            })

        }

        PreSave = async () => {
            //@ts-ignore
            setList([...list, this]);
        }

        Save = async () => {
            if (this.isEditing) {
                const res = await api.put(url + "/" + this.id, { ...this.data });
                setList(s => {
                    s.forEach((c) => {
                        if (c.id == this.id) {
                            c.data = { ...this.data, ...res.data }
                        }
                        return c;
                    })
                    return [...s]
                })
            }
            else if (this.isCreating) {
                const res = await api.post(url, { ...this.data, id: undefined });

                this.data = { ...this.data, ...res.data };
                this.isCreating = false;
                this.isEditing = false;

                //@ts-ignore
                setList(s => [...s, this])
            }

            this.isCreating = false;
            this.isEditing = false;
        }

        SetValue = (key: keyof T, value: any) => {
            setList(s => {
                s.forEach((c) => {
                    if (c.id == this.id) {
                        //@ts-ignore
                        c.data = { ...this.data, [key]: value }
                    }
                    return c;
                })
                return [...s]
            })
        }

    }

    const Create = (fields: T) => {
        const ent = new Entity({ ...fields, id: Math.random().toString() });
        ent.isCreating = true;
        return ent;
    }

    const GetMany = async () => {
        const res = await api.get<T[]>(url);
        const result: any[] = [];

        if (res.status != 200)
            return;

        res.data.map((e) => {

            // const t = useEntity<T>(e, { url });
            result.push(new Entity<T>(e));
        })


        setList(result);

        return;
    }

    const GetUnique = async (id: string) => {
        const res = await api.get<T>(url + "/" + id);

        if (res.status != 200)
            return;

        setList([new Entity<T>(res.data)]);

        return;
    }




    useEffect(() => {
        if (options.autoRequest) GetMany();
    }, [])


    return { loading, list, GetMany, Create, GetUnique, registerField }

}

