
import { Editor } from '@tiptap/core';
import { useRef } from 'react';
import styled from 'styled-components';
import TextEditor from '../../../components/TextEditor';
import Button from '../../../elements/Button';
import Flex from '../../../elements/Flex';
import Heading from '../../../elements/Heading';
import DiscussionCard from './DiscussionCard';

const Container = styled.div`

> .editor {
        border-radius: 8px;
        border: 1px solid ${props => props.theme.contentTertiary};
        box-shadow: 0px 0px 30px 0px #0001;
        min-height: 80px;
        background-color: ${props => props.theme.borderOpaque};
    }

    > ul {
        > li {

        }
    }
`;




const DiscussionList = ({ discussions, actions }: any) => {

    const ref = useRef<Editor>(null);

    return <Container>

        <Heading size={3}>Atividade</Heading>

        <ul>

            {discussions?.map((data: any) => (
                <DiscussionCard data={data} />
            ))}

        </ul>

        <div className='editor'>
            <TextEditor ref={ref} />
        </div>

        <Flex gap={4}>
            <Button title="Comentar" onClick={() => actions.btnCommentOnClick(ref.current?.getJSON())} />
        </Flex>

    </Container>;
}


export default DiscussionList;