import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Loading from '../elements/Loading';
import { useAuth } from '../hooks/useAuth';
// import NavigatorMobile from '../components/Navigator.mobile';


const Container = styled.div`

    /* display: flex; */
    /* overflow: auto;
    flex-direction: column; */
    height: 100%;
    overflow: auto;
   
    background-color: ${props => props.theme.backgroundSecondary};

    > .top {

        min-height: 100vh;

    > .container {
        width: 100%;
        display: flex;
        padding: 0px 20px;
        /* overflow: auto; */

        > .pages {
            width: 100%;
            max-width: 960px;
            margin: 0 auto;
            /* flex: 1; */
            /* display: flex; */
            /* overflow: hidden; */
            /* flex-direction: column; */
            /* height: 100%; */
         

            /* > .routes {
                flex: 1;
                display: flex;
          
                width: 100%;
                justify-content: center;
                align-items: flex-start;

        
                > div {
                    width: 100%;

                }
                
            } */
        }
    }
    }
`;


const Empty = () => {
    return <div></div>
}

const LoggedRoutes: React.FC = () => {

    const auth = useAuth();

    if (auth.status == "refreshToken")
        return <Loading />

    // else if (auth.status != "logged")
    //     return <LoginPage />

    return <Container>

        <div className='top'>
            <Header />
            <div className='container'>
                {/* <div className='navigator'>
                    <Nav />
                </div> */}
                <div className='pages'>
                    <Outlet />
                </div>
            </div>
        </div>
        <Footer />
        {/* </div> */}
    </Container>;

}

export default LoggedRoutes;