
import Icon from '../../elements/Icon';
import { useAuth } from '../../hooks/useAuth';
import { Container } from './styles';

const SecurityArea = ({ children, permission = "admin::*" }: any) => {

    const { user } = useAuth();


    if (user && user.roles.includes(permission))
        return children;

    else if (user && user.roles.includes("developer::*"))
        return <Container >
            {/* <div className='info'>
            <Icon name="lock" color="red400" size={16} />
        </div> */}
            <div className='border'>   <Icon name="lock" color="red400" /></div>
            <div className='children'> {children}</div>
        </Container>;

    return <></>
}

export default SecurityArea;