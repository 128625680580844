import styled from 'styled-components';
import LinkCard from '../components/LinkCard';
import SecurityArea from '../components/SecurityArea';
import Heading from '../elements/Heading';

const Container = styled.div`
    padding: 20px 0;
`;

const AdminPage = () => {

    return <Container>

        <Heading >Configuração</Heading>

        <SecurityArea permission="page::admin::services">
            <LinkCard title='Serviços' icon="wrench" url='/admin/servicos' />
        </SecurityArea>

        <SecurityArea permission="page::admin::cargos">
            <LinkCard title='Cargos e Permissões' icon="shield_lock" url='/admin/cargos' />
        </SecurityArea>

        <SecurityArea permission="page::admin::usuarios">
            <LinkCard title='Usuários do sistemas' icon="serves2" url='/admin/usuarios' />
        </SecurityArea>

        <SecurityArea permission="page::admin::setores">
            <LinkCard title='Grupos e setores' icon="serves3" url='/admin/setores' />
        </SecurityArea>

        <SecurityArea permission="admin::*">
            <LinkCard title='Marcadores' icon="tag" url='/admin/marcadores' />
        </SecurityArea>

        <Heading>Sistema</Heading>
        <SecurityArea permission="page::admin::tarefas">
            <LinkCard title='Tarefas automátizadas' icon="bolt" url='/admin/tarefas' />
        </SecurityArea>

    </Container>;
}

export default AdminPage;