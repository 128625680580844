import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../config';
import Button from '../../elements/Button';
import Flex from '../../elements/Flex';
import { IEntityTable } from '../../hooks/useEntity';
import { useModal } from '../../hooks/useModal';
import ServiceCard from './components/serviceCard';

const Container = styled.div`

    padding: 20px 60px;

`;


const ServiceGrid = ({ Entity }: { Entity: IEntityTable }) => {


    const modal = useModal();
    const navigate = useNavigate();


    const [searchParams] = useSearchParams();


    console.log(Entity);

    const get = async () => {
        if (Entity)
            Entity.GetMany();
    }

    const create = async () => {
        const created = await Entity.Create({
            title: "Novo serviço",
            description: "Clique para editar",
            icon: "therms",
            color: colors.blue400
        })
        console.log(created);
        created.Save();
        //await Entity.GetMany();
    }

    useEffect(() => {
        get();
    }, [])

    useEffect(() => {
        if (searchParams.get("refresh")) {
            get();
            searchParams.delete("refresh")
        }
    }, [searchParams])

    return <Container>

        <Flex style={{ padding: "20px 0px ", gap: 10 }}>
            <Button title="Novo Serviço" onClick={create} />
            <Button title="Atualizar" onClick={get} />
        </Flex>

        {Entity.data.map((Entity) => <ServiceCard Entity={Entity} />)}

        {/* <Table
            data={Entity?.data.map((ent: any) => ent.data)}
            headers={[
                {
                    key: "title",
                    name: "Nome",
                    width: "30%"
                },
                {
                    key: "description",
                    name: "Descrição",
                    width: "70%"
                }
            ]}
            actions={[
                {
                    name: "Delete",
                    color: "red400",
                    icon: "trash",
                    onClick(row) {
                        const entity = Entity.GetEntity(row.id);
                        console.log(entity);
                        if (entity)
                            entity.Delete().Save();
                    },
                },
                {
                    name: "Editar",
                    color: "blue400",
                    icon: "edit",
                    onClick(row) {
                        navigate(row.id);
                        // const entity = Entity.GetEntity(row.id);
                        // if (!entity)
                        //     return;

                        // modal.open(<ServiceModal entity={entity} />)
                    },
                }
            ]}
        /> */}


    </Container>;

}

export default ServiceGrid;