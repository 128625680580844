
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../config';
import Flex from '../elements/Flex';
import { useApi } from '../hooks/useAuth';

const getWidget = (columns: number) => {
    return columns * 60 + ((columns - 1) * 16)
}

const Container = styled.div`
    width: 100%;
    background-color: ${p => p.theme.backgroundSecondary};
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: auto;

   > header, > footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 60px 30px;
        padding-top: 20px;
    }
    >nav {
        display: flex;
        align-items: center;
        justify-content:  space-between;
        width: 100%;
        padding: 20px 20px;
    }
    section {
        /* padding: 50px; */

        width: 100%;
        max-width: calc( 80px * 12 );
        height: 100%;
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        /* display: grid; */
        gap: 16px;
        grid-template-columns: repeat(12, 60px);
        /* grid-auto-rows: 60px; */

        
        > .widget {
            background-color: ${colors.background};
            padding: 24px;
            border-radius: 8px;
            position: relative;
            min-width: ${getWidget(4)}px;
            min-height: ${getWidget(3)}px;

            /* &:hover {
                animation: mousehover 400ms;
            } */
        }


    }

    @keyframes mousehover {
        0%{ transform: scale(1)}
        30%{ transform: scale(1.02) }
        100%{ transform: scale(1)}
    }

    /* header {
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        background: ${p => `linear-gradient(180deg, ${p.theme.borderTransparent}, ${p.theme.backgroundSecondary})`}; 
        width: 100%;
        height: 200px;
    } */

   
`;


const IndexPage = () => {

    const navigate = useNavigate();
    const [data, setData] = useState<any>({ services: [] });
    const api = useApi();

    const get = async () => {
        const res = await api.get<any>("home");
        setData({
            services: [...res.data.services]
        });
    }



    useEffect(() => {
        get();
    }, [])

    return <Container>
        <nav>


        </nav>
        {/* <header>
            <Flex direction='vertical' alignItems='center' gap={4}>
                <Heading size={2}>Central de Atendimento</Heading>
                <Text fontSize={26}>Governo de Brasília</Text>
            </Flex>
        </header> */}
        <section>


            <div className='widget' style={{ width: getWidget(12) }}>
                <Flex style={{ height: '100%', padding: 20 }} alignItems='center' justifyContent='center'>
                    <img style={{ width: '100%' }} src={'http://localhost:3000/bg.png'} />
                </Flex>
            </div>

            {/* <div className='widget' style={{ gridColumn: `9 / span 4`, gridRow: "span 1" }}></div> */}


            {/* <div className='widget' style={{ width: getWidget(8) }}>
                <Heading>Serviços</Heading>
                {data.services.map(({ color, icon, title, id, description }: any) => (
                    <PublicServiceCard data={{
                        color, icon, title, description, id
                    }} />
                ))}
            </div> */}
            {/* 
            <div className='widget' style={{}}><MailWidget /></div>

            <div className='widget' style={{}}><CalendarWidget /></div> */}





        </section>
        {/* <footer>
            <Flex direction="vertical" alignItems="center">
                <Text font="Barlow" children="Desenvolvido por 2DOCs Sistemas ©" color={colors.gray500} fontSize={16} bold />
                <Text font="Barlow" children="Criptografia e segurança de ponta-a-ponta" color={colors.gray500} fontSize={16} bold />
            </Flex>
        </footer> */}
    </Container>;
}

export default IndexPage;