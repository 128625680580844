
import styled from 'styled-components';

interface customDiv {
  "attr-color": string
}

const Container = styled.div<customDiv>`
    /* width: 100%;  */
    margin-top: 1px;
    margin-bottom: 1px;
   // gotham;//,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Helvetica,Arial,sans-serif;

  .text{
      max-width: 100%;
      width: 100%;
      white-space: pre-wrap;
      /* word-break: break-word;  */
      caret-color: rgb(55, 53, 47); 
      padding: 0px ;
      color: ${props => String(props["attr-color"]).startsWith("#") ? props["attr-color"] : props.theme[props["attr-color"]]};
    }
`;

const sizes: { [index: number]: number } = {
  1: 32,
  2: 24,
  3: 20,
  4: 18,
  5: 16,
  6: 14,
  7: 12,
  8: 11,
  9: 10,
  10: 8
}

export interface HeadingProps {
  fontSize?: number,
  children: any
  bold?: boolean,
  nowrap?: boolean
  color?: string
  font?: string
  lineHeight?: number,
  fontWeight?: number,
  numberOfLines?: number,
  style?: React.CSSProperties | undefined
  align?: "left" | "center" | "right"
}

const Text = ({ children = "", numberOfLines = 0, style = {}, align = "left", color = "contentPrimary", lineHeight, fontSize, bold, fontWeight, nowrap, font = "'Roboto', sans-serif" }: HeadingProps) => {
  return <Container attr-color={color} >
    <div style={{ color: "inherit", fill: "inherit" }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            lineHeight, fontFamily: font,

            whiteSpace: nowrap ? "nowrap" : "normal",
            textAlign: align,
            fontWeight: fontWeight ? fontWeight : (bold ? 600 : 400),
            fontSize,
            ...(numberOfLines ? ({
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: numberOfLines,
              lineClamp: numberOfLines,
              WebkitBoxOrient: 'vertical'
            }) : {}),
            ...style
          }}
          className="text notranslate"
          spellCheck="true"
          placeholder="Press ‘space’ for AI, ‘/’ for commands…"
          data-content-editable-leaf="true"
        >
          {children}
        </div>
      </div>
    </div>
  </Container>;
}


export default Text;