import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import MailCard from '../../components/MailCard';
import Search from '../../components/Search';
import TabControl from '../../elements/TabControl';
import { useApi } from '../../hooks/useAuth';
import { IEntityTable } from '../../hooks/useEntity';
import { useModal } from '../../hooks/useModal';
import DocumentRow from './components/DocumentRow';

const Container = styled.div`

    padding: 20px 0px;

    > main {
        gap: 20px;
        display: flex;

        > .left{
            flex: 2;
        }
        > .right {
            flex: 1;
        }
    }

`;


const DocumentGrid = ({ Entity }: { Entity: IEntityTable }) => {

    const [searchParams] = useSearchParams();
    const api = useApi();

    const [data, setData] = useState({
        count: {
            personal: 4,
            team: 3,
            archived: 10
        },
        data: {
            personal: [],
            team: [],
            archived: []
        }
    });

    const modal = useModal();
    const navigate = useNavigate();

    const get = async (filters: any = {}) => {
        const res = await api.get("/documents?" + searchParams.toString());
        if (res.status == 200)
            setData(res.data);
    }



    const create = async () => {
        navigate("novo");
    }

    useEffect(() => {
        console.log("get");
        if (Entity.data.length == 0)
            get();
    }, [Entity, searchParams])

    // if (data.length == 0)
    //     return <Empty title="Nenhum documento encontrado" description='Não foi encontrado nenhum documento' action={get} />

    return <Container>

        {/* <Trail /> */}

        <main>

            <div className="left">

                <Search onSubmit={get} />

                <div style={{ height: 12 }} />

                <TabControl
                    tabs={[
                        {
                            name: "all",
                            title: "Em aberto",
                            count: data.count.personal + data.count.team,
                            icon: "arrow-down",
                            component: <Tab1 data={data} />
                        },
                        {
                            name: "personal",
                            title: "Caixa de saída",
                            count: data.count.personal,
                            icon: "arrow-top",
                            component: <>{data.data.personal.map((data) => <MailCard data={data} />)}</>
                        },
                        {
                            name: "team",
                            title: "",
                            count: data.count.team,
                            icon: "star",
                            component: <>{data.data.team.map((data) => <MailCard data={data} />)}</>
                        },
                        {
                            name: "archived",
                            title: "",
                            count: data.count.archived,
                            icon: "archive",
                            component: <>{data.data.archived.map((data) => <MailCard data={data} />)}</>
                        },
                    ]}
                />


            </div>

            {/* <div className="right">
                <Box></Box>
            </div> */}

        </main>



    </Container>;

}

const Tab1 = ({ data }: any) => {
    return <>
        {[...data.data.personal, ...data.data.team].map((data) => <DocumentRow data={data} />)}
        {/* <Table
            data={[...data.data.personal, ...data.data.team]}
            definition={[
                {
                    key: "number",
                    name: "N",
                    type: "text",
                    width: 1
                },
                {
                    key: "author",
                    name: "De",
                    type: "text",
                    width: 1
                },
                {
                    key: "content",
                    name: "Assunto",
                    type: "text",
                    width: 1
                },
                {
                    key: "activity",
                    name: "Atividade",
                    type: "text",
                    width: 1
                },
            ]}
            columns={[]}
            renderRow={(data) => <DocumentRow {...data} />}
        /> */}

    </>
}

export default DocumentGrid;