import axios, { AxiosError, AxiosInstance } from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import config, { layout } from "../config";
import cookie from "../services/cookie";
// import { useAlert } from "./useAlert";
// import { getExpoToken } from "../services/notifications"; //ONLY MOBILE

interface Options {
    developmentMode: boolean
}

export interface ContextStoreProps {
    user?: any,
    status: string
    credentials: any
    address?: any
    options: Options
    merchant?: any
    merchantId?: any
}

interface Credentials {
    accessToken?: string
    refreshToken?: string
    merchantId?: string,
    accessTokenExpiresIn?: number,
    refreshTokenExpiresIn?: number
}

export interface ContextDispatchProps {
    logout: () => void;
    initialize: () => void,
    selectMerchant: any
    login: (email: string, password: string) => any
    loginGovbr: (code: string) => any
    setAddress: (address: Object) => any
    toggleDevelopmentMode: () => void
    updateUser: any
}

export interface ContextProps {
    store: ContextStoreProps,
    dispatch: ContextDispatchProps,
    api: AxiosInstance
};

const DEFAULT_OPTIONS: Options = {
    developmentMode: true
}

const Context = createContext<ContextProps>({
    store: {
        user: undefined,
        credentials: {},
        options: DEFAULT_OPTIONS,
        status: "initializing"
    },
    dispatch: {
        selectMerchant: () => undefined,
        toggleDevelopmentMode: () => undefined,
        login: () => undefined,
        loginGovbr: () => undefined,
        logout: () => undefined,
        initialize: () => undefined,
        setAddress: () => { },
        updateUser: () => { }
    },
    api: axios.create({})
});

const UseAuthProvider = ({ children }: any) => {

    const [user, setUser] = useState<any>();
    const [address, setAddress] = useState<any>();
    const [merchantId, setMerchantId] = useState<any>();
    const [merchant, setMerchant] = useState<any>();
    const [status, setStatus] = useState<any>("initializing");
    const [credentials, setCredentials] = useState<Credentials>({});
    // const alert = useAlert();

    const navigate = useNavigate();

    const [options, setOptions] = useState(DEFAULT_OPTIONS);

    //const merchantId = credentials?.merchantId

    // const merchant = user?.merchant

    const api = axios.create({
        baseURL: config.url.api, timeout: 50000, headers: {

            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS',

            merchant: merchantId,
            ...credentials ? ({ authorization: "Bearer " + credentials.accessToken }) : ({}),
            ...address ? ({
                'city-code': address.cityCode,
                lat: address.lat,
                lng: address.lng
            }) : ({}),
            os: "web/" + layout.version,
            'Accept-Encoding': 'gzip;q=1.0, compress;q=0.5'
        }
    })


    api.interceptors.request.use((request: any) => {
        // alert.show({
        //     title: `Fetching api`,
        //     description: `${request.method}: ${request.url}`,
        //     type: "LOADING"
        // });
        return request;
    })

    api.interceptors.response.use((response: any) => {
        // alert.show(
        //     {
        //         title: `Api fetched`,
        //         description: `${response.config.method}: ${response.config.url} (${response.status})`,
        //         type: "SUCCESS"
        //     });

        return response;
    }, async (error: AxiosError<any>) => {
        // const status = error.response ? error.response.status : null

        // if (status === 503) {
        //     setStatus("inMaintenance");
        //     return Promise.reject(error);
        // }

        // navigate("/sem-permissao")
        // modal.open(<div>
        //     <Heading>Erro {error.response?.status}</Heading>
        //     <Text>{error.response?.data.message || "Sem mensagem"}</Text>
        // </div>)

        // return axios(error?.response?.config);
        // alert.show(
        //     {
        //         title: `Api fetched`,
        //         description: `${error.config.method}: ${error.config.url} (${error.status})`,
        //         type: "ERROR"
        //     });

        //return error;

        return error



    })

    const refreshToken = async () => {

        try {

            const token = cookie.getItem("@refreshToken");
            // const expoToken = await getExpoToken() //ONLY MOBILE
            if (token)
                setStatus("refreshToken")

            const { data, status }: any = await axios.get(config.url.api + "auth/refreshToken", {
                params: {
                    refreshToken: token,
                    // expoToken //ONLY MOBILE
                }, timeout: 10000
            })


            if (status === 200) {
                setUser({ ...data.user })
                setCredentials({ ...data });
                setStatus("logged")
                cookie.setItem('@refreshToken', data.refreshToken)
                return true
            }
            else
                return dispatch.logout();

        } catch {
            setStatus("notlogged")
        }
    }



    const initialize = async () => {

        try {

            // setStatus("refreshingToken")

            // if(layout.version){

            //     const {data}:any = await api.get("/v",{
            //         timeout: 5000
            //     });

            //     if(data && isLastVersion(layout.version,data.requiredVersion)){
            //         return setStatus("needUpdate")
            //     }

            // } 

            // const addressString = await cookie.getItem("@address");
            // console.log('get address...', addressString)
            // addressString && setAddress(JSON.parse(addressString))

            // const tokenString = await cookie.getItem("@credentials");
            // console.log('get credentials...', tokenString)


            // if (!tokenString) {
            //     return setStatus("notlogged")
            // }

            // const lastRefreshToken = JSON.parse(tokenString)


            // if (!lastRefreshToken)
            //     return dispatch.logout();
            await refreshToken();

        }
        catch (err: any) {
            if (err.response && err.response.status !== 503) {
                setStatus("networkError")
            }
        }
    }

    const getMerchant = async () => {
        const { data } = await api.get("/merchants/current");
        setMerchant(data);
    }

    useEffect(() => {
        setTimeout(() => {
            initialize();
        }, 100);
    }, [])

    useEffect(() => {
        if (merchantId && merchantId != "master")
            getMerchant();
    }, [merchantId])

    const dispatch: ContextDispatchProps = {

        selectMerchant: (merchantId: any) => {
            setMerchantId(merchantId);
            setMerchant(null);
        },
        logout: () => {
            setCredentials({})
            setUser(undefined)
            setStatus("notlogged");
            cookie.removeItem('@refreshToken')
        },
        setAddress: (address) => {

            setAddress({ ...address });
            cookie.setItem('@address', JSON.stringify(address))
        },
        login: async (email, password) => {

            // const expoToken = getExpoToken() //ONLY MOBILE
            try {
                const { data, status } = await axios.get(config.url.api + `auth/login`, {
                    params: {
                        email, password
                    }
                })
                if (status === 200) {
                    setUser({ ...data.user })
                    setCredentials({ ...data });
                    setStatus("logged")
                    cookie.setItem('@refreshToken', data.refreshToken)
                    return true
                } else {
                    return false
                    //   setLoginAttempt({...data,lastPhoneAttemptAt: new Date()})
                    //   setValue("")
                    //   alert.open({status: "ERROR",message: 'Código inválido'});
                }
            } catch {
                alert("Email ou senha incorretos")
            }


        },
        loginGovbr: async (code) => {

            // const expoToken = getExpoToken() //ONLY MOBILE
            try {
                const { data, status } = await api.get("/auth/govbr", {
                    params: {
                        code
                    }
                })

                if (status === 200) {
                    setUser({ ...data.user })
                    setCredentials({ ...data });
                    setStatus("logged")
                    cookie.setItem('@refreshToken', data.refreshToken)
                    return true
                } else {
                    return false
                    //   setLoginAttempt({...data,lastPhoneAttemptAt: new Date()})
                    //   setValue("")
                    //   alert.open({status: "ERROR",message: 'Código inválido'});
                }
            } catch {
                return false;
            }


        },
        initialize,
        updateUser: (state: any) => {
            setUser((laststate: any) => {
                if (laststate) {
                    return ({ ...laststate, ...state })
                }
            })
        },



        //OPTIONS
        toggleDevelopmentMode: () => {
            setOptions({ ...options, developmentMode: !options.developmentMode })
        }


    }

    return (
        <Context.Provider value={{ api, store: { options, user, credentials, merchant, merchantId, status, address }, dispatch }}>
            {children}
        </Context.Provider>
    )
}

export const useAuthDispatch = () => {
    const { dispatch } = useContext(Context);
    return useMemo(() => dispatch, [dispatch])
};

export function useAuth<Selected>(
    // selector?: (value: ContextStoreProps) => Selected,
) {
    const { store } = useContext(Context);
    return store
    // let result = selector ? selector(store) : store;
    // return useMemo(() => result, [result])
}

export function useApi() {
    const { api } = useContext(Context);
    return api
}

export default UseAuthProvider