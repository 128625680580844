
import styled from 'styled-components';
import Text from '../../../elements/Text';

const Container = styled.tr`

    padding: 0px 12px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    border-radius: 12px;

`;

const LabelCard = ({ data }: any) => {

    return <Container style={{ backgroundColor: data.color }}>
        <Text fontSize={12} bold color={data.textColor}>{data.name}</Text>
    </Container>;

}

export default LabelCard;