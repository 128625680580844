import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import MailCard from '../../components/MailCard';
import TeamMailReceiverCard from '../../components/user/TeamMailReceiver';
import UserMailReceiverCard from '../../components/user/UserMailReceiver';
import Flex from '../../elements/Flex';
import Heading from '../../elements/Heading';
import Icon from '../../elements/Icon';
import IconButton from '../../elements/IconButton';
import { IEntityTable } from '../../hooks/useEntity';
import { useForm } from '../../hooks/useForm';

const Container = styled.div`

    padding: 20px;
    display: flex;
    align-items: center;

    .grid {
        max-width: 900px;
        width: 100%;
        display: flex;
        gap: 16px;

        .subgrid {
            display: flex;
            flex-direction: column;
            gap: 16px;

             .widget {
                border: 1px solid ${p => p.theme.borderTransparent} ;
                border-bottom: 3px solid ${p => p.theme.borderTransparent} ;
                background-color: ${p => p.theme.backgroundPrimary} ;
                padding: 24px;
                border-radius: 8px;
                position: relative;
                min-width: 300px;
                width: 100%;
                min-height: 50px;

                .logo {
                    width: 42px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 42px;
                    background-color: ${p => p.theme.blue400} ;
                }
            }

        }
    }

`;


const TeamView = ({ EntityTable }: { EntityTable: IEntityTable }) => {

    const { id } = useParams();
    const { entity } = EntityTable;
    const form = useForm();
    const navigate = useNavigate();
    const theme = useTheme()

    console.log(entity);

    // const initialize = async () => {
    //     console.log("initializing");
    //     if (id && id == "novo") {
    //         Entity.Create({});
    //         form.setInitialData(Entity.data);
    //     }
    //     else if (id) {
    //         await Entity.Get();
    //         form.setInitialData(Entity.data);
    //     }
    //     // else {
    //     //     Entity.Create();
    //     // }
    //     // await Entity.Get();
    //     // form.setInitialData(Entity.data)
    // }

    // const submit = async () => {
    //     if (!Entity)
    //         return;

    //     await Entity.Update(form.getFields()).Save();
    //     navigate("../")
    //     EntityTable.Refresh();
    // }


    useEffect(() => {
        if (id && id == "novo")
            EntityTable.Create();


        // initialize();
    }, [id])

    // useEffect(() => {
    //     if (Entity)
    //         Entity.Update(form.getFields())
    // }, [form])

    useEffect(() => {
        EntityTable.Get(id);
        // if (Entity)
        //     form.setInitialData(Entity.data)
        // initialize();
    }, [id])

    if (!entity || !entity.data)
        return <></>

    const { data, members, documents } = entity.data;

    return <Container>


        <div className='grid'>
            <div className='subgrid' style={{ flex: 2 }}>
                {/* <div className='widget'> */}
                <Heading size={3}>Documentos</Heading>
                {documents.map((mail: any) => <MailCard data={mail} />)}
                {/* </div> */}
                {/* <div className='widget'>b</div> */}
            </div>
            <div className='subgrid' style={{ flex: 1 }}>
                <div className='widget'>
                    <Flex flex={[0, 1, 0]} gap={6} alignItems="center">
                        <div className='logo'>
                            <Icon color="white" name="serves3" />
                        </div>
                        <Heading size={3}>{data.title}</Heading>
                        <IconButton size={18} icon='edit' color="blue400" onClick={() => navigate("editar")} />
                        <IconButton size={18} icon='trash' color="gray500" />
                    </Flex>
                    <Flex style={{ paddingTop: 16 }} direction='vertical' gap={16}>
                        {members.map((receiver: any) => {
                            if (receiver.user)
                                return <UserMailReceiverCard data={receiver} />
                            else if (receiver.team)
                                return <TeamMailReceiverCard data={receiver} />
                        })}
                    </Flex>
                </div>
                {/* <div className='widget'>d</div> */}
            </div>
        </div>

    </Container>;

}

export default TeamView;