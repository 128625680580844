import { createContext, useCallback, useState } from 'react';
// import DotObject from '../services/dot-object';

// import { Container } from './styles';
import dot from 'dot-object';
import { cloneObject } from '../services/functions';
dot.keepArray = false;

interface fieldsProps {
    [name: string]: {
        defaultValue?: any,
        value: any
    }
}

interface optionsProps {
    onlyModified?: boolean
    includesId?: boolean
    fieldsSync?: fieldsSync | undefined | Object
}

interface fieldsSync {
    [input: string | number]: any
}

interface errorsProps {
    [input: string]: string | undefined
}

interface validityProps {
    [input: string]: {
        require?: boolean,
        min?: number,
        minLength?: number,
    }
}

const Context = createContext<any>({
   
});


export const useForm = (initialData: any = {}, prevalidity: validityProps = {}, formOptions: optionsProps = {}) => {


    const [fields, setFields] = useState<any>({...dot.dot(initialData)});
    const [errors, setErrors] = useState<errorsProps>({});

    const Form = ( {children}:any) => {
        <Context.Provider value={{ 
            scorePath: ''
         }}>
            {children}
        </Context.Provider>
    }

    const reset = useCallback(() => {
        setFields({})
    }, [])


    const getFields = useCallback((showAll: boolean = false) => {

        let result:any = dot.object(cloneObject(fields));
        return result;

    }, [fields])

    const setError = useCallback((field: string, message: string) => {
        setErrors((laststate) => {
            return ({ ...laststate, [field]: message })
        })
    }, [initialData])

    const setInitialData = (object: any) => {
        let dotobj = {}
        dot.dot(object,dotobj);
        console.log(JSON.stringify(dotobj));
        setFields({...object});
    }

    const removeField = useCallback((field: string) => {
        delete fields[field]
    }, [initialData])


    const insertField = (field: string, value: any, returnValue: any = value) => {
        setFields((state: any) => ({ ...state, [field]: returnValue }))
    }

    const registerField = useCallback((name: string, field?: any) => {
        var value = fields[name];

        const onInput = (value: any, returnValue: any = value) => {

            insertField(name, value, returnValue);

            if (errors[name])
                setErrors(s => {
                    delete s[name]
                    return s
                })

        }

        return ({ onInput, error: errors[name], required: (field && field.required), name, defaultValue: value, value })

    }, [errors, initialData, fields])



    return ({ getFields, reset, registerField, setInitialData, fields, errors, setError, removeField })

}


// export function Scope({ path, children }: any) {
//     const { scopePath, ...form } = useContext(Context)
  
//     return (
//       <Context.Provider
//         value={{
//           ...form,
//           scopePath: scopePath.concat(scopePath ? `.${path}` : path),
//         }}
//       >
//         {children}
//       </Context.Provider>
//     )
//   }
