
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PeopleCard from '../../../components/PeopleCard';
import UserPicture from '../../../components/user/UserPicture';
import Flex from '../../../elements/Flex';
import Icon from '../../../elements/Icon';
import Text from '../../../elements/Text';
import { formatDate } from '../../../services/functions';
import LabelCard from '../../labels/components/LabelCard';


const Container = styled.div`

    border: 1px solid ${props => props.theme.borderTransparent};
    border-bottom: 3px solid ${attr => attr.theme.borderTransparent};
    /* box-shadow: 0px 0px 20px 0px ${attr => attr.theme.shadow}; */
    border-radius: 6px;
    padding: 8px  12px ;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    background-color: ${attr => attr.theme.backgroundPrimary};
    cursor: pointer;
    gap: 6px;
    
    > .sender{
        flex: 2;
        display: flex;
        align-items: center;
        gap: 6px;
        

        > .details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0;
        }
    }

    > .to{
        flex: 2;
        display: flex;
        align-items: center;
        gap: 6px;
        

        > .details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0;
        }
    }

    > .content {
        flex: 5;
        display: flex;
        align-items: center;
        gap: 6px;

        > .mark {
            padding: 2px 6px;
            border-radius: 4px;
            background-color: ${attr => attr.theme.orange100};
        }

        > .separator{
            width: 12px;
            height: 2px;
            flex-shrink: 0;
            background-color: ${attr => attr.theme.contentSecondary};
        }

        
    }

    &:hover, &:hover > .content > .receivers > .receiver {
        background-color: ${attr => attr.theme.title100};
    }

    .receivers{
            display: flex;
            
            .receiver{
                width: 36px;
                position: relative;
                height: 36px;
                border-radius: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: ${attr => attr.theme.backgroundPrimary};

                &.team {
                    background-color: ${attr => attr.theme.purple100};
                    border: 4px solid   ${attr => attr.theme.backgroundPrimary};
                }

                &:not(:first-child){
                    margin-left: -14px;
                }

                &.more {
                    border: 4px solid   ${attr => attr.theme.backgroundPrimary};
                    background-color: ${attr => attr.theme.backgroundTertiary};
                }
            }
        }

`;

interface MailCard {
    data: {
        id: string
        code: string
        createdAt: Date
        title: string
        content: Object
        sender: any
        labels: any[]
        receivers: any[]
        updatedAt: any
        parentId?: string
        _count: { [key: string]: number }
    }
}


function removerTagsHTML(content: any = []) {
    if (!content)
        return ""
    return content.reduce((str: string, c: any) => c.content ? str + " " + removerTagsHTML(c.content) : str + (c.text || (c.attrs?.label ? "@" + c.attrs?.label : undefined) || ""), "")
}

const DocumentRow = ({
    data: { sender, parentId, id, receivers, labels, title, content, _count, updatedAt, createdAt }
}: MailCard) => {

    const navigate = useNavigate();

    return <Container onClick={() => navigate("/documentos/" + id)}>

        <td style={{ flex: 1 }}>
            <Text fontSize={16} bold font="Barlow">0001/2023</Text>
        </td>
        <td style={{ flex: 2 }}>
            <PeopleCard data={sender} />
        </td>
        <td style={{ flex: 5 }}>
            <Flex gap={6}>
                <Text fontSize={16} bold >{title}</Text>
                <Flex gap={2} alignItems="center">
                    <Icon name="comments" size={14} />
                    <Text fontSize={16} bold font="Barlow">{_count?.discussions}</Text>
                </Flex>
            </Flex>
            <Text fontSize={16} numberOfLines={2} >{removerTagsHTML(content)}</Text>
            {labels?.map(({ label }) => <LabelCard data={label} />)}
        </td>
        <td style={{ flex: 1 }}>

            <Flex direction='vertical' alignItems="flex-end">

                <Receivers receivers={receivers} />

                {!!updatedAt
                    ? <Text nowrap fontSize={14} color="contentSecondary">Atualizado {formatDate(updatedAt)}</Text>
                    : <Text nowrap fontSize={14} color="contentSecondary">Criado {formatDate(createdAt)}</Text>}

            </Flex>

        </td>

    </Container>;
}

const Receivers = ({ receivers }: { receivers: any[] }) => {
    return <div className='receivers'>
        {receivers.slice(0, receivers.length > 5 ? 4 : 5).map(({ user, team }: any) => {
            if (team)
                return <div className='receiver team'>
                    <Icon color={"purple400"} name="serves3" size={20} />
                </div>
            else if (user) return (<div className='receiver'>
                <UserPicture size={24} data={user} />
            </div>)
        })}
        {receivers.length > 5 && <div className='receiver more'>
            <Text fontSize={14} bold>+{receivers.length - 4}</Text>
        </div>}
    </div>
}

export default DocumentRow;