
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import Icon, { iconNames } from '../elements/Icon';
import Text from '../elements/Text';

const Container = styled.div`

    display: flex;
    gap: 8px;
    padding: 16px;
    border-radius: 6px;
    cursor: pointer;
    background-color: ${props => props.theme.backgroundPrimary};
    box-shadow: 0 1px 4px 0 rgba(0, 8, 38, 0.04);
    border-radius: 10px;
    border: 1px solid transparent;
    transition: 200ms;
    margin-bottom: 8px;

    .icon{
    }

    &:hover {
        /* background-color: ${p => p.theme.backgroundTertiary}; */
        border: 1px solid ${p => p.theme.contentTertiary};
        outline: 1px solid ${p => p.theme.backgroundPrimary};
        box-shadow: 0px 0px 0px 3px ${p => p.theme.backgroundTertiary};
     
    }
    
`;


interface IPublicServiceCard {
    title: string
    url: string
    icon?: iconNames,
}

const LinkCard = ({
    title,
    url = "",
    icon = "wrench",
}: IPublicServiceCard) => {

    const theme = useTheme();
    const navigate = useNavigate();

    return <Container onClick={() => navigate(url)}>
        <div className='icon' >
            <Icon name={icon} color="contentSecondary" />
        </div>
        <div className='details'>
            <Text fontSize={18} color='contentSecondary'>{title}</Text>
        </div>

    </Container>;
}

export default LinkCard;