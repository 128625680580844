
import styled from 'styled-components';
import Flex from '../../elements/Flex';
import Icon from '../../elements/Icon';
import Text from '../../elements/Text';
import { formatTimeWithExtension } from '../../services/functions';
import UserPicture from './UserPicture';


// const Container = styled.td`
//     .container {
//         padding: 6px;
//         border-radius: 4px;
//         display: flex;
//         gap: 5px;
//         align-items: center;

//         > .picture {
//             flex-shrink: 0;
//             background: #ddd;
//             box-shadow: none;
//             outline: rgba(227, 226, 224, 0.5) solid 1px;
//             outline-offset: -1px;
//             border-radius: 4px;
//             width: 36px;
//             height: 36px;
//             user-select: none;
//             opacity: 1;
//             overflow: hidden;

//             > img {
//                 display: block;
//                 object-fit: cover;
//                 width: 100%;
//                 height: 100%;
//             }
//         }

//         /* &:hover{
//             background-color: #eeeeee;
//         } */
//         width: 100%;
//         background-color: #eeeeee;
//     }
//     width: 100%;
//     flex: 1;
// `;


const Container = styled.td`
    .container {
        padding: 5px;
        height: 28px;
        border-radius: 5px;
        width: 100%;
        justify-content: center;
        display: flex;
        gap: 8px;
        align-items: center;

        > .picture {
            flex-shrink: 0;
            background: #ddd;
            box-shadow: none;
            outline: rgba(227, 226, 224, 0.5) solid 1px;
            outline-offset: -1px;
            border-radius: 16px;
            overflow: hidden;
            width: 28px;
            height: 28px;
            user-select: none;
            opacity: 1;

            > img {
                display: block;
                object-fit: cover;
                /* border-radius: 20%; */
                width: 100%;
                height: 100%;
            }
        }

        /* &:hover{
            background-color: #eeeeee;
        } */
    }
`;


const UserMailReceiverCard = ({ data: { readAt, user, team, mentioned } }: any) => {

    if (user)
        return <Container>
            <div className='container'>
                <UserPicture mentioned={mentioned} size={32} data={user} />
                <Flex style={{ flex: 1 }} justifyContent={"center"} direction='vertical' gap={0}>
                    <Text nowrap fontSize={16} bold font="Barlow" lineHeight={0.8}>{user.name}</Text>
                    {readAt ? <Flex alignItems="center">
                        <Icon color={"blue400"} name="double-check" />
                        <Text color={"blue400"} fontSize={14}>{"Visto " + formatTimeWithExtension(readAt)}</Text>
                    </Flex> : <Flex alignItems="center">
                        <Icon color={"contentTertiary"} name="double-check" />
                        <Text color={"contentTertiary"} fontSize={14}>{"Recebido"}</Text>
                    </Flex>}
                </Flex>
            </div>
        </Container>
    else if (team)
        return <Container>
            <div className='container'>
                <div className='picture' style={{
                    width: 32,
                    height: 32,
                    borderRadius: 16,
                    display: 'flex',
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Icon color={"purple400"} name="serves3" />
                </div>
                <Flex style={{ flex: 1 }} justifyContent={"center"} direction='vertical' gap={0}>
                    <Text nowrap fontSize={16} bold font="Barlow" lineHeight={0.8}>{team.title}</Text>
                </Flex>
            </div>
        </Container>
    else
        return <></>
}

export default UserMailReceiverCard