
import styled from 'styled-components';
import Flex from '../../../elements/Flex';
import Heading from '../../../elements/Heading';
import Icon from '../../../elements/Icon';
import Text from '../../../elements/Text';
import { getContrast } from '../../../services/functions';

const Container = styled.aside`
    padding: 8px;
    background-color: ${p => p.theme.backgroundPrimary};
    border: 1px solid ${p => p.theme.borderOpaque};
    border-bottom-width: 4px;
    border-radius: 6px;

    .icon {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
`;


const DocumentServiceCard = ({ service }: any) => {

    return <Container style={{ borderBottomColor: service.color }}>

        <Heading size={4}>Serviço solicitado:</Heading>
        <Flex alignItems="center" gap={4}>
            <div className='icon' style={{ backgroundColor: service.color }}>
                <Icon size={18} color={getContrast(service.color)} name={service.icon} />
            </div>
            <Text bold>{service.title}</Text>
        </Flex>


    </Container>;
}


export default DocumentServiceCard;