
import Flex from '../../elements/Flex';
import Icon from '../../elements/Icon';
import Text from '../../elements/Text';
import { getContrast } from '../../services/functions';
import UserPicture from '../user/UserPicture';
import { Container } from './styles';

const PeopleCard = ({ data }: any) => {

    console.log({ data })

    return <Container >
        <div className='content'>
            <UserPicture data={data} />
            <div className='details'>
                <Text bold>{data.name}</Text>
            </div>
        </div>
        <div className='popup'>
            <Flex gap={10} style={{ width: "100%", flex: 1 }}>
                <UserPicture size={64} data={data} />
                <div>
                    <Text bold>{data.name}</Text>
                    <div className='roles'>
                        {data.roles?.map(({ role }: any) => (<div className='role' >

                            {role.icon ? <div className='icon'  >
                                <Icon name={role.icon} color={role.color} size={16} />
                            </div> : <div className='circle' style={{ backgroundColor: role.color }} />}
                            <Text bold fontSize={12} color={getContrast(role.color)}>{role.name}</Text>
                        </div>))}
                    </div>
                </div>
            </Flex>
        </div>
    </Container>;
}

export default PeopleCard;