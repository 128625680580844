
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../config';
import { useAuthDispatch } from '../hooks/useAuth';
import StartingPage from './Starting';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${p => p.theme.backgroundTertiary};

    display: flex;
    flex-direction: column;
    padding: 50px;

    header,footer {
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    section {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        cursor: pointer;

        .picture {
            width: 64px;
            height: 64px;
            border-radius: 32px;
            background-color: ${colors.blue400};
            transition: 200ms;
        }
        .description {
            overflow: hidden;
            height:20px;
            margin-top: -10px;
            transition: 50ms;
            max-width:0px;

            & > div {
                transform: translateY(-20px);
                transition: 200ms;
                transition-delay: 50ms;
            }
        }

        &:hover{
            .picture {
                width: 92px;
                height: 92px;
                border-radius: 46px;
            }
            .description {
                max-width:100%;
                & > div {
                    transform: translateY(0px);
                }
            }
        }
    }

    input {
        width: 100%;
        padding: 10px;
        border-radius: 6px;
        background-color: ${p => p.theme.backgroundPrimary};
        border: 1px solid ${p => p.theme.borderOpaque};
        outline: none;

        &:focus{
            border: 1px solid ${p => p.theme.backgroundPrimary};
            outline: 1px solid ${p => p.theme.blue400};
        }
    }
    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    /* .box {
       
        display: flex;
        gap: 8px;
        padding: 16px;
        border-radius: 6px;
        background-color: ${props => props.theme.backgroundPrimary};
        flex-direction: column;
        box-shadow: 0 1px 4px 0 rgba(0, 8, 38, 0.05);
        border: 1px solid transparent;
        transition: 200ms;
        border: 1px solid ${p => p.theme.borderOpaque};

        padding: 24px;
        position: relative;
        min-width: 300px;
        min-height: 50px;
    } */

    .gov{
        background-color: ${p => p.theme.green400};
        align-items: center;
        justify-content: center;
        display: flex;
        gap: 4px;
        padding: 4px 12px;
        border-radius: 4px;
    }
`;


const GovbrPage = ({ modal }: any) => {

    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const code = searchParams.get("code");
    const navigate = useNavigate();
    const auth = useAuthDispatch();

    const loginWithGov = async (code: string) => {
        setLoading(true);
        const res = await auth.loginGovbr(code);
        if (res) {
            navigate("/");
        }
        setLoading(false);
    }

    useEffect(() => {
        if (code)
            loginWithGov(code);
    }, [code])

    if (loading)
        return <StartingPage />

    return <Container>

    </Container>;
}

export default GovbrPage;