import styled from 'styled-components';
import TextEditor from '../../components/TextEditor';
import { colors } from '../../config';

const Container = styled.div`
    background-color: ${colors.gray100};
    height: 100%;
    padding: 40px;
    
    > .editable {
        outline: none;
        padding: 20px;
        background-color: ${colors.background};
        border-radius: 6px;
    }
`;


const ExampleEditorPage = () => {

    return <Container>
        <div className='editable'>
            <TextEditor />
        </div>
    </Container>;
}



export default ExampleEditorPage;