
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import UserPicture from '../../components/user/UserPicture';
import { colors } from '../../config';
import Button from '../../elements/Button';
import Loading from '../../elements/Loading';
import Text from '../../elements/Text';
import { useApi } from '../../hooks/useAuth';
import { useModal } from '../../hooks/useModal';


const Container = styled.div`
    border: 1px solid ${colors.gray200};
    border-radius: 4px;
    background-color: ${colors.background};
    box-shadow: 0px 0px 15px 0px #0001;
    overflow: hidden;

    width: 300px;
    padding: 10px;

    > .items {
        > button {
            padding: 4px 6px;
            background-color: ${colors.background};
            border-radius: 6px;
            cursor: pointer;
            display: flex;
            gap: 5px;

            align-items: center;

            &.selected{
              border: 2px solid ${p => p.theme.blue400};
            }

            &.disabled {
              opacity: 0.4;
            }

            &:not(.disabled):hover{
                background-color: ${colors.gray100};
            }
        }
    }
`;

const SelectUserModal = ({
  blackList = [],
  onConfirm = () => { }
}: any) => {
  const [data, setData] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>(null);
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const modal = useModal();

  const get = async () => {
    setLoading(true);

    const res = await api.get("/users");
    if (res.status == 200)
      setData(res.data)

    setLoading(false);
  }

  const selectItem = (index: any) => {

  }

  useEffect(() => { get() }, [])

  if (loading)
    return <Loading />

  return (
    <Container>
      {/* <Flex style={{ padding: 6 }} gap={2} alignItems="center">
              <Icon name="serves2" size={20} />
              <Text fontSize={14}>Mencionar: </Text>
          </Flex> */}
      <div className="items" style={{ display: 'flex', flexDirection: "column" }}>
        {
          data.map((item: any, index: any) => (
            <button
              className={`item 
              ${selected == item.id ? "selected" : ""}
              ${blackList.includes(item.id) ? "disabled" : ""}
              `}
              key={index}
              onClick={() => {
                if (!blackList.includes(item.id)) {
                  if (selected != item.id)
                    setSelected(item.id)
                  else
                    setSelected(null)
                }
              }}
            >
              <UserPicture data={item} />
              <Text numberOfLines={1} fontSize={14} bold align='left'>{item.name}</Text>
            </button>
          ))

        }
      </div>
      <div style={{ height: 10 }} />
      <Button onClick={() => {
        onConfirm({ selected });
        modal.close();
      }} title="Confirmar" />
    </Container>
  )
}

export default SelectUserModal;