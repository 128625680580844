import styled from 'styled-components';

export const Container = styled.div`


    @keyframes onhover {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    position: relative;

    > .content {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    > .popup {

        display: none;
        opacity: 0;

      

        top: calc( 100% + 0px );
        left: 50%;
        transform: translateX(-20%);

        position: absolute;
        z-index: 1000;
        
        min-width: 400px;
        max-width: 500px;
        min-height: 50px;
        
        background-color: ${p => p.theme.backgroundPrimary} ;
        padding: 16px;
        border-radius: 6px;
        box-shadow: 0 1px 10px 0 rgba(0, 8, 38, 0.15);
        border: 1px solid transparent;
        transition: 200ms;

        > div {
            width: 100%;
            flex: 1;
        }
    }


    &:hover > .popup{
        display: flex;
        opacity: 1;
        animation-fill-mode: both;
        animation-delay: 1s;
        animation-name: onhover;
        animation-duration: 150ms;
    }

     .roles {

        padding: 6px 0;

        > .role {
            padding: 2px 6px;
            border-radius: 4px;
            display: inline-flex;
            margin-right: 4px;
            margin-bottom: 4px;
            align-items: center;
            gap: 4px;
            background-color: ${p => p.theme.backgroundSecondary};

            > .circle{
                width: 12px;
                height: 12px;
                border-radius: 6px;
            }
            > .icon{
               
            }
        }
    }


`;
