import { forwardRef } from 'react';

import styled from 'styled-components';

import Flex from '../elements/Flex';
import Text from '../elements/Text';
import { useAuth } from '../hooks/useAuth';
import { useModal } from '../hooks/useModal';
import { useThemeSwitch } from '../hooks/useTheme';
import NotificationsModal from '../pages/modals/NotificationsModal';
// import { useAuth, useAuthDispatch } from '../hooks/useAuth';



const Container = styled.div`

    background-color: ${p => p.theme.backgroundInverseSecondary};
    border-bottom: 2px solid ${p => p.theme.borderTransparent};

> footer {
    margin: 0 auto;
    max-width: 1080px;

    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    > .left {
        align-items: center;
        display: flex;
    }

    
}

  
  
`;


const Footer = forwardRef(({ }: any, ref) => {

    const { user } = useAuth();
    const modal = useModal();
    const themeSwitch = useThemeSwitch();

    const showNotifications = (e: React.MouseEvent<HTMLDivElement>) => {
        modal.context({
            children: <NotificationsModal />,
            target: e.currentTarget
        })
    }

    return <Container>

        <footer>
            <div className='left' style={{ padding: 20 }}>
                <Flex direction='vertical' alignItems="center">
                    <Text fontSize={36} bold color={"backgroundSecondary"}>DBSETI</Text>
                    <Text nowrap fontSize={18} color={"backgroundSecondary"}>S I S T E M A S</Text>
                </Flex>
            </div>
            <div className='right'>
                <Text fontSize={15} color='backgroundSecondary'>
                    © DBSistemas - Brasília - Asa Sul, Bloco C, Quadra 4 - CEP 70070-000 - CNPJ 12.345.678/0001-00
                </Text>
                <Text fontSize={15} color='backgroundSecondary'>
                    © DBSistemas - Tupi Paulista - Rua arcebispo Lemieux, 811 - CEP 17930-000 -  CNPJ 12.345.678/0001-00
                </Text>
                <Text fontSize={15} color='backgroundTertiary'>
                    © Todos os diretos reservados 2023
                </Text>
            </div>
        </footer>

    </Container >;
})


export default Footer;