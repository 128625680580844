import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { shadeColor } from '../services/functions';
import Icon from './Icon';
import Text from './Text';

const Container = styled.div<any>`
  
    display: flex;
    margin-top: 10px;
    gap: 2px;
    
   
    > button, > .dropdown > button {
        background-color: ${p => p["attr-color"]};
        border: 1px solid ${p => shadeColor(p["attr-color"], -10)};
        box-shadow: inset 0 0 0 1px ${p => shadeColor(p["attr-color"], -10)};
        border-width: 0;
        padding: 4px 12px;
       // background-color: transparent;
        //box-shadow: inset 0 0 0 1px #bfbfc3;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        transition: 200ms;
        height: 30px;

        &:hover{
            
            box-shadow: inset 0 0 2px 3px ${p => shadeColor(p["attr-color"], -20)};
        }
    }

    > .dropdown {

        position: relative;

        > button{
          
            padding: 4px 4px;
          
        }

        >.dropdown-panel {
            left: 0px;
            bottom: 100%;
            position: absolute;

            background-color: #fff;
            border-width: 1px;
            border-style: solid;
            border-color: #bfbfc3;
            border-radius: 0.5rem;
            box-shadow: 0 2px 8px rgba(31,30,36,0.16), 0 0 2px rgba(31,30,36,0.16);
      
            min-width: 15.5rem; 
            max-width: 28.5rem;
            z-index: 1000;
            width: 15.5rem !important; 
            margin: 4px 0;

            > ul {
            //    max-height: 579.9px;
                padding: 0;
             //   position: relative;
                flex-grow: 1;
                overflow-y: auto;
                padding-left: 0;
                margin-bottom: 0;
                padding: 6px;
                list-style-type: none;


                 li {
                    border-radius: 4px;
                    background-color: #ddd;
                    padding: 8px;
                    cursor: pointer;
                }

            }
        }
    }

`;

const Button2 = ({ title, onClick, align = "center", icon,
    color = "backgroundPrimary",
    options = [],
    dropdown = options.length > 0,
    foreColor,
    withBorder = true, showTitle = true }: any) => {

    const [show, setShow] = useState(false);

    const theme = useTheme();
    const colorHex = theme[color];

    return <Container attr-color={theme.blue400}>
        <button onClick={onClick} style={{ borderTopRightRadius: dropdown ? 0 : 4, borderBottomRightRadius: dropdown ? 0 : 4 }}>
            <span className="text">
                <Text fontSize={14} color={"backgroundPrimary"}>{title}</Text>
            </span>
        </button>
        {dropdown && <div className="dropdown">
            <button onClick={() => setShow(!show)} type="button" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                <Icon color={"backgroundPrimary"} name="arrow-down" size={18} />
            </button>
            {show && <div className="dropdown-panel" >
                <ul>
                    {options.map((opt: any) => (
                        <li>
                            <div className='left'></div>
                            <div className='right'>
                                <Text bold>{opt.title}</Text>
                                <Text>Description</Text>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>}
        </div>}
    </Container >;
}

export default Button2;