import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Box from '../../elements/Box';
import Button from '../../elements/Button';
import Flex from '../../elements/Flex';
import Heading from '../../elements/Heading';
import { useApi } from '../../hooks/useAuth';
import { useModal } from '../../hooks/useModal';
import Table from '../../libraries/table';
import LabelRow from './components/LabelRow';

const Container = styled.div`

    padding: 20px 60px;

`;


const LabelGridPage = ({ }) => {

    const [data, setData] = useState<any[]>([]);
    const api = useApi();
    const modal = useModal();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const get = async () => {
        const res = await api.get<any[]>("/labels");
        setData(res.data);
    }

    const create = async () => {
        navigate("e");
    }

    useEffect(() => {
        get();
    }, [])


    return <Container>

        <Flex alignItems="center" justifyContent='space-between'>
            <Heading size={1}>Marcadores</Heading>
            <Flex style={{ padding: "20px 0px ", gap: 10 }}>
                <Button title="Novo marcador" onClick={create} />
            </Flex>
        </Flex>

        <Box>



            <Table
                viewPage="/admin/marcadores/{{id}}"
                data={data}
                columns={[]}
                renderRow={(data) => <LabelRow {...data} />}
                definition={[
                    {
                        key: "name",
                        name: "Marcador",
                        width: 3,
                        type: "text",
                        // format: "string"
                    },
                    {
                        key: "description",
                        name: "Descrição",
                        width: 3,
                        type: "text",
                        // format: "string"
                    }
                ]}
                actions={[]}
            />
        </Box>

    </Container>;

}

export default LabelGridPage;