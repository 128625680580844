import { Route, Routes } from 'react-router-dom';
import { useEntityTable } from '../../hooks/useEntity';
import LabelFormPage from './form';
import LabelGridPage from './grid';

const LabelMainPage = ({ }: any) => {

    const Entity = useEntityTable("users");

    return <Routes>
        <Route index element={<LabelGridPage />} />
        <Route path="/e/:id?" element={<LabelFormPage EntityTable={Entity} />} />
    </Routes>


}

export default LabelMainPage;