import styled, { useTheme } from 'styled-components';
import Icon, { IconList } from '../Icon';
import Input from './Input';

const Container = styled.div`
   
   display: flex;
   flex-direction: column;
   gap: 6px;

   > .top {

        display: flex;

        > .selected {
            width: 66px;
            border-radius: 4px;
            height: 50px;
            border: 1px solid ${p => p.theme.contentTertiary};
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    > .popup{
        width: 100%;

        > .icons {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fill, 30px);

            > .icon {
                cursor: pointer;
                width: 24px;
                height: 24px;
                padding: 2px;
                border-radius: 4px;
                margin-right: 6px;
                margin-bottom: 6px;
                border-width: 1px;
                border-color: ${p => p.theme.backgroundPrimary};
                border-style: solid;
                outline-style: solid;
                outline-width: 1px;

                &.selected{
                    grid-column: 1 / span 3;
                    width: auto;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    grid-row: 1 / span 2;
                }
            }
        }
    }
     
`;

const IconInput = ({ title, required, numberOfLines = 1, password, icon, name, placeholder, showLimit = false, clearErrors, readonly = false, value = "", description, max, onInput, error = false, rows = 1, padding = 5, leftIcon, rightIcon }: any) => {

    const theme = useTheme();

    const colorList = [
        theme.green300,
        theme.blue300,
        theme.purple300,
        theme.pink300,
        theme.yellow300,
        theme.orange300,
        theme.red300,
        theme.gray400,
        theme.white,

        theme.green400,
        theme.blue400,
        theme.purple400,
        theme.pink400,
        theme.yellow400,
        theme.orange400,
        theme.red400,
        theme.gray600,
        theme.black,
    ]

    return <Input {...{ title, required, description }}><Container>

        {/* <div className='top'>

            <div className='selected'  >
                <Icon name={value} color="contentTertiary" size={32} />
            </div>

        </div> */}

        <div className='popup'>

            <div className='icons'>
                <div className=' icon selected'  >
                    <Icon name={value} color="contentTertiary" size={32} />
                </div>

                {Object.keys(IconList).map((icon: any) => <div onClick={() => onInput(icon)} style={{ outlineColor: icon == value ? theme.borderSelected : theme.borderOpaque }} className='icon' >
                    <Icon name={icon} color="contentTertiary" size={20} />
                </div>
                )}
            </div>

        </div>

    </Container> </Input>
}


export default IconInput