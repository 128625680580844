import pack from '../package.json';
const width = window.innerWidth
const height = window.innerHeight



export const colors = {
    //logo_color: "#00AA00",
    logo_color: "#2334FF",
    // primary_color: "#121212",
    primary_color: "#2334FF",
    link: "#004BFF",
    inverse_primary_color: "#fff",

    hightlight: "#ffa700",
    money: "#38B64A",

    red400: "#E11900",
    red300: "#e85c4a",
    red200: "#f1998e",
    red100: "#fed7d2",

    purple400: "#7356BF",
    purple300: "#957FCE",
    purple200: "#C1B5E3",
    purple100: "#E3DDF2",

    pink400: "#FF73FA",
    pink300: "#FF96FC",
    pink200: "#FFB9FD",
    pink100: "#FFDCFE",

    yellow400: "#FFC043",
    yellow300: "#FFCf70",
    yellow200: "#ffe3ac",
    yellow100: "#fff2d9",

    blue400: "#276EF1",
    blue300: "#5b91f5",
    blue200: "#a0bff8",
    blue100: "#d4e2fc",

    orange400: "#FF6937",
    orange300: "#fa9269",
    orange200: "#fabda5",
    orange100: "#ffe1d6",

    green400: "#05944F",
    green300: "#06C167",
    green200: "#66D19E",
    green100: "#ADDEC9",
    green50: "#CDFEE9",

    primary: "#000000",
    // secondary: "#276EF1",
    //secondary: "#2334FF",
    //secondary: "#3544fb",
    secondary: "#3e4df9",
    // secondary: "#003fff",
    gray900: "#141414",
    gray800: "#1F1F1F",
    gray700: "#333333",
    gray600: "#545454",
    gray500: "#757575",
    gray400: "#AFAFAF",
    //gray300:  "#CBCBCB",
    gray300: "#dbdee1",
    // gray200:  "#E2E2E2",
    gray200: "#E7E9EB",
    // gray200:  "#6a748028",
    gray100: "#EEEEEE",
    gray50: "#F6F6F6",


    black: "#000000",
    title: "#292831",
    subtitle: "#605F67",

    hover: "#6a748028",
    background: "#ffffff",
    background_secondary: "#f2f3f5",
    background_tertiary: "#e6e8e9",
    border: "#eaeaea",
    ripple: "#F0F0F0",
    shape: "#f9f9f9",
    white: "#FFFFFF",
    gray: "#CFCFCF",

    blue: "#3D7199",
    blue_light: "#EBF6FF",

    error: "#E83F5B",
    error_title: "#ffffff",

    success: "#38B64A",
    success_background: "#EBF6F1",
    success_title: "#ffffff",

    facebook: "#1877f2",


    warning_title: "#ffffff",


}

// export const themes = {
//     light: {
//         ...colors,
//         background: "#ffffff",
//         backgroundReverse: "#0A0C10",
//         backgroundSecondary: "#E5E5E6",
//         shadow: "#0001",
//         title900: "#14161D",
//         title800: "#282A36",
//         title700: "#434550",
//         title600: "#797B82",
//         title500: "#94959B",
//         title400: "#AFB0B4",
//         title300: "#CACACD",
//         title200: "#E5E5E6",
//         title100: "#F2F2F3",
//         // title900: "#141414",
//         // title800: "#1F1F1F",
//         // title700: "#333333",
//         // title600: "#545454",
//         // title500: "#757575",
//         // title400: "#AFAFAF",
//         // title300: "#dbdee1",
//         // title200: "#E7E9EB",
//         // title100: "#EEEEEE",
//         // title50: "#F6F6F6",
//     },
//     dark: {
//         ...colors,
//         background: "#0A0C10",
//         backgroundReverse: "#ffffff",
//         backgroundSecondary: "#05070A",
//         shadow: "#0001",
//         // background: "#282A36",
//         // backgroundReverse: "#ffffff",
//         // backgroundSecondary: "#14161D",
//         // title50: "#060606",
//         title100: "#14161D",
//         title200: "#282A36",
//         title300: "#434550",
//         title400: "#797B82",
//         title500: "#94959B",
//         title600: "#AFB0B4",
//         title700: "#CACACD",
//         title800: "#E5E5E6",
//         title900: "#F2F2F3",

//     }
// }

export const themes = {
    light: {
        ...colors,

        //BACKGROUND
        backgroundPrimary: colors.white,
        backgroundInversePrimary: colors.black,
        backgroundSecondary: colors.gray50,
        backgroundInverseSecondary: colors.gray800,
        backgroundTertiary: colors.gray100,
        //CONTENT
        contentPrimary: colors.black,
        contentInversePrimary: colors.white,
        contentSecondary: colors.gray600,
        contentInverseSecondary: colors.gray300,
        contentTertiary: colors.gray500,
        contentInverseTertiary: colors.gray400,
        //BORDER
        borderOpaque: "#E9E9E9",//colors.gray200,
        borderInverseOpaque: colors.gray700,
        borderTransparent: "rgba(0,0,0,.08)",
        borderInverseTransparent: "rgba(255,255,255,0.2)",
        borderSelected: colors.black,
        borderInverseSelecte: colors.white,
    },
    dark: {
        ...colors,

        //BACKGROUND
        backgroundPrimary: colors.gray900,
        backgroundInversePrimary: colors.gray200,
        backgroundSecondary: colors.gray800,
        backgroundInverseSecondary: colors.gray300,
        backgroundTertiary: colors.gray700,
        //CONTENT
        contentPrimary: colors.white,
        contentInversePrimary: colors.black,
        contentSecondary: colors.gray400,
        contentInverseSecondary: colors.gray600,
        contentTertiary: colors.gray500,
        contentInverseTertiary: colors.gray500,
        //BOIR
        borderOpaque: colors.gray700,
        borderInverseOpaque: colors.gray400,
        borderTransparent: "rgba(255,255,255,0.08)",
        borderInverseTransparent: "rgba(0,0,0,0.2)",
        borderSelected: colors.white,
        borderInverseSelecte: colors.black,
    },

    contrast: {
        ...colors,

        //BACKGROUND
        backgroundPrimary: colors.black,
        backgroundInversePrimary: colors.white,
        backgroundSecondary: colors.black,
        backgroundInverseSecondary: colors.white,
        backgroundTertiary: colors.black,
        //CONTENT
        contentPrimary: colors.white,
        contentInversePrimary: colors.black,
        contentSecondary: colors.white,
        contentInverseSecondary: colors.black,
        contentTertiary: colors.white,
        contentInverseTertiary: colors.black,
        //BOIR
        borderOpaque: colors.black,
        borderInverseOpaque: colors.white,
        borderTransparent: "rgba(255,255,255,0.08)",
        borderInverseTransparent: "rgba(0,0,0,0.2)",
        borderSelected: colors.white,
        borderInverseSelecte: colors.black,
    }
}

export const layout = {
    window: {
        width,
        height
    },
    version: pack.version,
    isSmallDevice: width < 900,
}

export const shadow = {
    "low": `0 1px 0 rgba(6,6,7,0.1),0 1.5px 0 rgba(6,6,7,0.025),0 2px 0 rgba(6,6,7,0.025)`,
    "medium": `0 4px 4px rgba(0,0,0,0.08)`,
    "high": `0 8px 16px rgba(0,0,0,0.16)`
}

const config = {

    mode: "development",

    "url": {
        api: "http://26.36.64.134:5000/",
        files: "http://26.36.64.134:5000/",//"https://files.entregakii.com.br/",
        paymentMethodsImagens: "https://files.entregakii.com.br/paymentMethods/",
        productsImagens: "https://files.entregakii.com.br/products/",
        garnishItemsImagens: "https://files.entregakii.com.br/garnishItems/",
        merchantsImagens: "https://files.entregakii.com.br/merchants/",
        adivertisementsImagens: "https://files.entregakii.com.br/advertisements/",
        campaignsImagens: "https://files.entregakii.com.br/campaigns/",
        promotionsImagens: "https://files.entregakii.com.br/promotions/",
        activityImagens: "https://files.entregakii.com.br/activities/",
        usersImage: "https://files.entregakii.com.br/users/"
    },
    layout,
    shadow,
    "font": {
        bold: "Whitney Bold",
        default: "Whitney",
    }
}

export const routes = [
    [
        // {
        //     title: "Início",
        //     route: "",
        //     exact: true,
        //     icon: "home",
        //     permissions: ["ADMIN","OWNER","STANDARD"]
        // },
        {
            title: "Avaliações",
            route: "avaliacoes",
            exact: true,
            icon: "star",
            permissions: ["ADMIN", "OWNER"]
        },
        {
            title: "Financeiro",
            route: "financeiro",
            exact: true,
            icon: "money",
            permissions: ["ADMIN", "OWNER"]
        },
        {
            title: "Pedidos",
            route: "pedidos",
            exact: true,
            icon: "order_outline",
            permissions: ["STANDARD", "ADMIN", "OWNER"]
        }
    ],


    [
        {
            title: "Cardápio",
            route: "categorias",
            exact: true,
            icon: "book",
            permissions: ["STANDARD", "ADMIN", "OWNER"]
        },
        {
            title: "Horários",
            route: "horarios",
            exact: true,
            icon: "stopwatch",
            permissions: ["STANDARD", "ADMIN", "OWNER"]
        },
        {
            title: "Áreas de entrega",
            route: "areas-de-entrega",
            exact: true,
            icon: "motocycle",
            permissions: ["STANDARD", "ADMIN", "OWNER"]
        },
        {
            title: "Formas de pagamento",
            route: "formas-de-pagamento",
            exact: true,
            icon: "wallet",
            permissions: ["STANDARD", "ADMIN", "OWNER"]
        },
        // {
        //     title: "Cupons de desconto",
        //     route: "cupons",
        //     exact: true,
        //     icon: "coupon",
        //     permissions: ["ADMIN","OWNER"]
        // },
        {
            title: "Promoções",
            route: "promocoes",
            exact: true,
            icon: "discount",
            permissions: ["ADMIN", "OWNER"]
        },
        {
            title: "Serviços",
            route: "servicos",
            exact: true,
            icon: "wrench",
            permissions: ["ADMIN", "OWNER"]
        }
    ],

    [
        {
            title: "Perfil",
            route: "perfil",
            exact: true,
            icon: "shop",
            permissions: ["ADMIN", "OWNER"]
        },
        // {
        //     title: "Plano",
        //     route: "plano",
        //     exact: true,
        //     icon: "shop",
        //     permissions: ["OWNER"]
        // },
        {
            title: "Funcionários",
            route: "funcionarios",
            exact: true,
            icon: "user",
            permissions: ["OWNER"]
        },
        {
            title: "Desempenho",
            route: "desempenho",
            exact: true,
            icon: "performance",
            permissions: ["ADMIN", "OWNER"]
        }
    ]
]

export default config