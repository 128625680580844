import { createContext, useContext, useState } from "react";
import { themes } from "../config";
// import { useAlert } from "./useAlert";
// import { getExpoToken } from "../services/notifications"; //ONLY MOBILE

export interface ContextProps {
    theme: any
    current: string,
    themeSwitch: (theme?: string) => void
};

const Context = createContext<ContextProps>({
    theme: {},
    current: "light",
    themeSwitch: () => { }
});

const UseThemeProvider = ({ children }: any) => {

    const allThemes = ["light", "dark", "contrast"];

    const [dark, setDark] = useState(true);
    const [current, setCurrent] = useState<any>("light");

    const themeSwitch = (theme?: string) => {

        if (theme)
            setCurrent(theme)

        else {
            const index = allThemes.indexOf(current);
            const newIndex = index < (allThemes.length - 1) ? index + 1 : 0
            setCurrent(allThemes[newIndex]);
        }
    }

    //@ts-ignore
    const theme = themes[current];//dark ? themes.dark : themes.light;

    return (
        <Context.Provider value={{ theme, current, themeSwitch }}>
            {children}
        </Context.Provider>
    )
}

export function useTheme() {
    const { theme, current } = useContext(Context);
    return { theme, current }
}

export function useThemeSwitch() {
    const { themeSwitch } = useContext(Context);
    return themeSwitch
}

export default UseThemeProvider