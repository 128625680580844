import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useApi } from '../../hooks/useAuth';
import { IEntityTable } from '../../hooks/useEntity';
import SolutionCard from './components/SolutionCard';

const Container = styled.div`

   > .grid {
    padding: 20px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    gap: 20px;
    background-color: ${props => props.theme.backgroundSecondary};
   }
    
`;

const SolutionGrid = ({ Entity }: { Entity: IEntityTable }) => {

    const navigate = useNavigate();
    const [data, setData] = useState<any>({ services: [] });
    const api = useApi();

    const get = async () => {
        const res = await api.get<any>("home");
        setData({
            services: [...res.data.services]
        });
    }

    useEffect(() => { get() }, [])


    return <Container>

        {/* <Flex style={{ padding: "20px 0px ", gap: 10 }}>
            <Button title="Novo team" onClick={create} />
            <Button title="Atualizar" onClick={get} />
        </Flex> */}

        {/* <Heading>Serviços</Heading> */}

        <div className='grid'>
            {data.services.map(({ color, icon, title, id, description }: any) => (
                <SolutionCard data={{
                    color, icon, title, description, id
                }} />
            ))}
        </div>

    </Container>;

}

export default SolutionGrid;