import styled from 'styled-components';

const Container = styled.div`
    border-bottom: 1px solid ${p => p.theme.borderTransparent};
    width: 100%;
    margin: 6px 0;
`;

const Separator = () => {
    return <Container />
}

export default Separator;