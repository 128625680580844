import { IColumnNumberVariants } from "../interfaces/IColumnNumber";
import { IColumnStringVariants } from "../interfaces/IColumnString";
import def from "./default";
import email from "./email";


type IVariants = {
    "string": IColumnStringVariants
    "number": IColumnNumberVariants
}

const variants: IVariants = {
    string: {
        string: def,
        email: email,
        text: def,
    },
    number: {
        decimal: def,
        int: def,
        money: def
    }
}

export default variants