import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import UseThemeProvider from './hooks/useTheme';
import Routes from './routes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <BrowserRouter>
      <UseThemeProvider>
        <Routes />
      </UseThemeProvider>
    </BrowserRouter>
  </>
);
