import { Route, Routes } from 'react-router-dom';
import { useEntityTable } from '../../hooks/useEntity';
import ServiceForm from './form';
import ServiceGrid from './grid';

const ServicePage = ({ }: any) => {

    const Entity = useEntityTable("services");

    if (!Entity)
        return <></>

    return <Routes>
        <Route index element={<ServiceGrid Entity={Entity} />} />
        <Route path=":id" element={<ServiceForm EntityTable={Entity} />} />
    </Routes>


}

export default ServicePage;