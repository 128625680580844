
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Text from '../../../elements/Text';
import LabelCard from './LabelCard';

const Container = styled.tr`

    > td {
        padding: 12px;
    }

    .label {
        padding: 0px 12px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        border-radius: 12px;
    }

    &:hover > td{
        background-color: ${p => p.theme.backgroundSecondary};
    }

`;

const LabelRow = ({ data }: any) => {

    const navigate = useNavigate();

    return <Container onClick={() => navigate("e/" + data.id)}>
        <td>
            <LabelCard data={data} />
        </td>
        <td>
            <Text color="contentTertiary">{data.description}</Text>
        </td>
    </Container>;
}

export default LabelRow;