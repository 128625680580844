
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../config';
import Text from '../../elements/Text';
import { useApi } from '../../hooks/useAuth';


const Container = styled.div`
    border: 1px solid ${colors.gray200};
    border-radius: 4px;
    background-color: ${colors.background};
    box-shadow: 0px 0px 15px 0px #0001;
    overflow: hidden;

    width: 300px;
    padding: 10px;

    > .items {
        > button {
            padding: 3px 6px;
            background-color: ${colors.gray100};
            border-radius: 6px;
            cursor: pointer;

            &:hover{
                background-color: ${colors.gray100};
            }
        }
    }
`;

const NotificationsModal = () => {
  const [data, setData] = useState<any[]>([]);
  const api = useApi();
  const navigate = useNavigate();

  const get = async () => {
    const res = await api.get("/users/notifications");
    if (res.status == 200)
      setData(res.data)
  }

  const selectItem = (index: any) => {

  }

  useEffect(() => { get() }, [])


  return (
    <Container>
      {/* <Flex style={{ padding: 6 }} gap={2} alignItems="center">
              <Icon name="serves2" size={20} />
              <Text fontSize={14}>Mencionar: </Text>
          </Flex> */}
      <div className="items" style={{ display: 'flex', flexDirection: "column" }}>
        {data.length
          ? data.map((item: any, index: any) => (
            <button
              className={`item`}
              key={index}
              onClick={() => navigate(item.url)}
            >
              <Text numberOfLines={1} fontSize={14} bold align='left'>{item.title}</Text>
              <Text numberOfLines={2} fontSize={14} align='left'>{item.description}</Text>
            </button>
          ))
          : <div className="item">Sem notificações</div>
        }
      </div>
    </Container>
  )
}

export default NotificationsModal;