import { colors } from '../config';

import styled from 'styled-components';

const Container = styled.div`

`

const H5 = styled.h4`
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  padding: 0;
  font-family: Roboto;//ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 12px;
  line-height: 1.3;
  margin: 0px;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: ${props => props.theme.contentSecondary};
`;

const H4 = styled.h4`
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: rgb(55, 53, 47);
  padding: 3px 2px;
  font-family: Roboto;//ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  margin: 0px;
  color: ${props => props.theme.contentPrimary};
`;

const H3 = styled.h3`
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: rgb(55, 53, 47);
  padding: 3px 2px;
  font-family: Roboto;//ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  margin: 6px 0px;
  color: ${props => props.theme.contentPrimary};
`

const H2 = styled.h2`
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: rgb(55, 53, 47);
  padding: 3px 2px;
  font-family: Roboto;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  margin: 0px;
  color: ${props => props.theme.contentPrimary};
`;


const H1 = styled.h1`
  max-width: 100%;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: rgb(55, 53, 47);
  padding: 3px 2px;
  font-family: Roboto;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.3;
  margin: 0px;
  color: ${props => props.theme.contentPrimary};
`;



const stylesContainers: { [index: number]: any } = {
  1: H1,
  2: H2,
  3: H3,
  4: H4,
  5: H5
}

export interface HeadingProps {
  title?: string,
  size?: 1 | 2 | 3 | 4 | 5;
  bold?: boolean,
  color?: string,
  align?: "center" | "left" | "right" | "justify",
  padding?: number,
  textDecoration?: any
  nowrap?: boolean
  font?: any
  numberOfLines?: number
  children?: any
}

const Heading = ({ children, title = "", numberOfLines, font = "Roboto", nowrap, textDecoration, size = 3, bold = false, color = colors.gray700, align, padding = 0 }: HeadingProps) => {

  const StyledContainer = stylesContainers[size]

  return <Container>
    <div style={{ color: "inherit", fill: "inherit", display: "flex", width: "100%" }}>
      <StyledContainer>
        {children || title}
      </StyledContainer>
    </div>
  </Container>;
}


export default Heading;