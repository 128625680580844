
import { Extension, Mark, Node, mergeAttributes } from '@tiptap/core';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { colors } from '../config';
import { Image } from '../textEditor/image';
import { Mention } from '../textEditor/mention';
import Suggestion from './Suggestion';

const Container = styled.div`

    width: 100%;
    /* border: 1px solid ${colors.gray300}; */
    border-radius: 8px;
    * {
        color: ${props => props.theme.contentPrimary};
    }
    /* padding: 10px; */

    .editable{
        outline: none;
        font-size: 18px;
        display: inline-block;
      //  padding: 20px;
        width: 100%;
       // min-height: 100px;

        input {
            padding: 0;
            margin: 0;
            display: inline-block;
        }

        h3 {
            font-weight: 400;
        }

        li {
            margin-left: 16px;

            &::marker{
                font-size: 16px;
                font-weight: 600;
            }
        }

        code {
            white-space: pre-wrap;
            word-wrap: break-word;
            overflow-wrap: break-word;
            word-break: keep-all;
            font-size: 0.875rem;
            padding: 2px 4px;
            color: ${p => p.theme.contentSecondary};
            background-color: ${p => p.theme.backgroundTertiary};
            border-radius: 4px;
        }

        .image-resizer {
            display: inline-flex;
            position: relative;
            flex-grow: 0;
            .resize-trigger {
            position: absolute;
            right: -6px;
            bottom: -9px;
            opacity: 0;
            transition: opacity .3s ease;
            color: #3259a5;
            }
            &:hover .resize-trigger {
            opacity: 1;
            }
        }

    }

    .variable {
        background-color: ${colors.purple400};
        padding: 2px 5px;
        color: ${colors.background};
        border-radius: 4px;
    }

    .mention {
        /* background-color: ${colors.pink100}; */
        /* padding: 2px 5px; */
        font-weight: 600;
        border-radius: 4px;

        &[data-target=team]{
            color: ${colors.purple400};
        }
        &[data-target=user]{
            color: ${colors.orange400};
        }
    }

    .bubble {
        height: 34px;
        min-width: 32px;
        background-color: ${colors.background};
        border-radius: 4px;
        border: 1px solid ${colors.gray200};
        border-bottom: 2px solid ${colors.gray200};
        display: flex;

        > .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 32px;
            height: 32px;
            padding: 0px 4px;
            border-radius: 4px;
            gap: 4px;
            cursor: pointer;

            >.counter {

            }

            &:hover{
                background-color: ${colors.gray100};
            }
        }
    }

    blockquote {
        background-color: ${colors.yellow100};
        padding: 6px 6px 6px 14px;
        position: relative;

        &::before{
            position: absolute;
            content: "";
            width: 4px;
            height: calc( 100% - 8px);
            background-color: ${p => p.theme.yellow400};
            left: 4px;
            top: 4px;
            bottom: 4px;
            border-radius: 1px;
        }
    }

    footer {

    }
`;

export interface VariableExample {
    keepAttributes: boolean,
}

const HighlightedText = Node.create<VariableExample>({
    name: "comment",
    group: 'inline',

    inline: true,

    selectable: false,

    atom: true,
    addOptions() {
        return {
            keepAttributes: true
        }
    },

    // addCommands() {
    //     return {
    //       toggleVariable: () => ({ commands, chain }:any) => {
    //         if (this.options.keepAttributes) {
    //           return chain().toggleList(this.name, this.options.itemTypeName, this.options.keepMarks).updateAttributes(ListItem.name, this.editor.getAttributes(TextStyle.name)).run()
    //         }
    //         return commands.toggleList(this.name, this.options.itemTypeName, this.options.keepMarks)
    //       },
    //     }
    //   },
    onUpdate() {
        return;
    },
    toDOM: () => {
        return ["span", { class: "variable", color: "#ff0000", id: "ADU8SYD8N7N87" }, 0];
    },
    renderHTML({ HTMLAttributes }) {
        return ["span", mergeAttributes({ class: "variable", color: "#ff0000" }, HTMLAttributes), "olá"];
    },
    parseHTML() {
        return [{ tag: "span.variable", context: "T" }];
    },
});


const Link = Mark.create({
    name: "link",
    onUpdate() {
        return;
    },
    toDOM: () => {
        return ["a", { class: "link", href: "https://google.com.br", id: "ADU8SYD8N7N87" }, 0];
    },
    renderHTML({ HTMLAttributes }) {
        return ["a", mergeAttributes({ class: "link", href: "https://google.com.br" }, HTMLAttributes), 0];
    },
    parseHTML() {
        return [{ tag: "a.link" }];
    },
});


interface TextAlignOptions {
    types: string[],
    alignments: string[],
    defaultAlignment: string,
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        textAlign: {
            /**
             * Set the text align attribute
             */
            setTextAlign: (alignment: string) => ReturnType,
            /**
             * Unset the text align attribute
             */
            unsetTextAlign: () => ReturnType,
        }
    }
}


const TextAlign = Extension.create<TextAlignOptions>({
    name: 'textAlign',

    addOptions() {
        return {
            types: [],
            alignments: ['left', 'center', 'right', 'justify'],
            defaultAlignment: 'left',
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    textAlign: {
                        default: this.options.defaultAlignment,
                        parseHTML: element => element.style.textAlign || this.options.defaultAlignment,
                        renderHTML: attributes => {
                            if (attributes.textAlign === this.options.defaultAlignment) {
                                return {}
                            }

                            return { style: `text-align: ${attributes.textAlign}` }
                        },
                    },
                },
            },
        ]
    },

    addCommands() {
        return {
            setTextAlign: (alignment: string) => ({ commands }) => {
                if (!this.options.alignments.includes(alignment)) {
                    return false
                }

                return this.options.types.every(type => commands.updateAttributes(type, { textAlign: alignment }))
            },

            unsetTextAlign: () => ({ commands }) => {
                return this.options.types.every(type => commands.resetAttributes(type, 'textAlign'))
            },
        }
    },

    addKeyboardShortcuts() {
        return {
            'Mod-Shift-l': () => this.editor.commands.setTextAlign('left'),
            'Mod-Shift-e': () => this.editor.commands.setTextAlign('center'),
            'Mod-Shift-r': () => this.editor.commands.setTextAlign('right'),
            'Mod-Shift-j': () => this.editor.commands.setTextAlign('justify'),
        }
    },
})


export interface UnderlineOptions {
    HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        underline: {
            setUnderline: () => ReturnType,
            toggleUnderline: () => ReturnType,
            unsetUnderline: () => ReturnType,
        }
    }
}

const Underline = Mark.create<UnderlineOptions>({
    name: 'underline',

    addOptions() {
        return {
            HTMLAttributes: {},
        }
    },

    parseHTML() {
        return [
            {
                tag: 'u',
            },
            {
                style: 'text-decoration',
                consuming: false,
                getAttrs: style => ((style as string).includes('underline') ? {} : false),
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['u', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    addCommands() {
        return {
            setUnderline: () => ({ commands }) => {
                return commands.setMark(this.name)
            },
            toggleUnderline: () => ({ commands }) => {
                return commands.toggleMark(this.name)
            },
            unsetUnderline: () => ({ commands }) => {
                return commands.unsetMark(this.name)
            },
        }
    },

    addKeyboardShortcuts() {
        return {
            'Mod-u': () => this.editor.commands.toggleUnderline(),
            'Mod-U': () => this.editor.commands.toggleUnderline(),
        }
    },
})

export const useTextEditor = ({ editable = false, content }: { editable?: boolean, content?: any }) => {
    const editor = useEditor({
        editable,
        extensions: [StarterKit, TextAlign.configure({
            alignments: ['left', 'right', 'center'],
            defaultAlignment: "left"
        }), Underline, HighlightedText.configure({}), Link,
            Mention.configure({
                HTMLAttributes: {
                    class: 'mention',
                },
                suggestion: Suggestion([]),
            }),
            // ImageResize.configure({
            //     inline: true
            //     // allowBase64: true,
            //     // resizeIcon: <>ResizeMe</>
            // })
            Image.configure({
                inline: true
            })
        ],
        content: {
            "type": "doc", content
        },
        editorProps: {
            attributes: {
                class: "editable"
            }
        }
    })
    return editor
}

const TextEditorContainer = forwardRef(({ editor, editable, content }: { editor?: Editor, editable?: boolean, content?: any }, ref: any) => {






    // const insertLink = () => {
    //     const url = prompt('Enter the URL:');
    //     if (editor) {
    //         editor.chain().focus().setMark("link");
    //         // editor.chain().focus().extendMarkRange("comment").run();
    //         // editor.chain().focus().toggleList("comment").run();
    //         // editor.commands.add("comment")
    //         // editor.commands.insertContent({
    //         //     type: 'comment',
    //         // })
    //         // editor.chain().focus().insertContent({
    //         //     type: "myCustomNode",
    //         //     content: [
    //         //         { type: 'text', text: 'Custom Node Content' },
    //         //     ]
    //         // }).run();
    //     }
    // };



    useImperativeHandle((ref), () => ({
        getJSON: () => editor?.getJSON(),
        getEditor: () => editor
    }))


    return <Container>

        {/* @ts-ignore */}
        <EditorContent placeholder='Comece a editar...' editor={editor} />
        {/* {editor && <BubbleMenu editor={editor}>
            <div className='bubble'>

                <div className='btn' onClick={() => editor.chain().focus().toggleBold().run()}>
                    <Icon name="bold" />
                </div>
                <div className='btn' onClick={() => editor.chain().focus().toggleItalic().run()}>
                    <Icon name="italic" />
                </div>
                <div className='btn' onClick={() => editor.chain().focus().toggleUnderline().run()}>
                    <Icon name="underline" />
                </div>
                <div className='btn' onClick={() => editor.chain().focus().toggleStrike().run()}>
                    <Icon name="strike" />
                </div>

            </div>
        </BubbleMenu>} */}







    </Container>

})

export const TextViewer = ({ content }: { editor?: Editor, editable?: boolean, content?: any }, ref: any) => {

    const editor = useTextEditor({ content, editable: false });


    return <Container>

        <EditorContent editor={editor} />

    </Container>

}

export const TextEditor = forwardRef(({ content }: { editor?: Editor, editable?: boolean, content?: any }, ref: any) => {

    const editor = useTextEditor({ content, editable: true });

    useImperativeHandle((ref), () => ({
        getJSON: () => editor?.getJSON(),
        getEditor: () => editor
    }))

    return <Container>

        <EditorContent editor={editor} />

    </Container>

})

export default TextEditorContainer;