import { colors } from "../config";


function componentToHex(c: any) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

export const classList = (classes: string[]) => {
    return classes.filter(Boolean).join(" ");
}

export const formatColor = (color: string = "") => {
    if (color.startsWith("#"))
        return color

    //@ts-ignore
    return colors[color] || colors.background
}

function separarArray(arr: any[], tamanho: number) {
    var novoArray = [];
    var i = 0;
    while (i < arr.length) {
        novoArray.push(arr.slice(i, i + tamanho));
        i += tamanho;
    }
    return novoArray;
}

export const inlineSwitch = (variable: any, args: any[], def: any = null) => {
    if (args.length % 2 != 0)
        throw new Error("inlineSwitch espera um número par de argumentos");

    const find = separarArray(args, 2).find(v => variable == v[0]);
    return find ? find[1] : def;

}


export function replaceVariablesString(string: string, valores: any) {
    try {
        return string.replace(/\{\{([\w\.]+)\}\}/g, (match: any, key: any) => {
            const keys = key.split('.'); // Separar as chaves aninhadas
            let value = valores;
            for (const k of keys) {
                value = value[k]; // Acessar a propriedade aninhada
                if (value === undefined) break; // Se alguma chave não existir, sair do loop
            }
            return value !== undefined ? value : match; // Substituir pelo valor correspondente ou manter o texto original
        });
    }
    catch {
        return ""
    }
}


export function rgbToHex(r: any, g: any, b: any) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
export function shadeColor(color: string = "#ffffff", percent: number) {

    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = Math.round(R * (100 + percent) / 100);
    G = Math.round(G * (100 + percent) / 100);
    B = Math.round(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));


    return "#" + RR + GG + BB;
}
export const getContrast = (hex: string) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result)
        return;

    let rgb = [
        parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)
    ]

    if ((rgb[0] * 0.299) + (rgb[1] * 0.587) + (rgb[2] * 0.114) > 186) {
        return '#000000';
    } else {
        return '#ffffff';
    }

}

export const getContrast2 = (hex: string) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result)
        return;

    let rgb = [
        parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)
    ]

    if ((rgb[0] * 0.299) + (rgb[1] * 0.587) + (rgb[2] * 0.114) > 186) {
        return 'contentPrimary';
    } else {
        return 'backgroundPrimary';
    }

}

export const asyncMap = (array: any[], fn: (value: any, index: number) => void) => {

    let promise = new Promise(function (resolve, reject) {

        let results: any[] = [];

        const execute = async (index: number) => {
            let value = array[index];

            try {

                let result = await fn(value, index);
                results.push(result);

                if (index < array.length - 1)
                    execute(index + 1);
                else
                    resolve(results);
            }
            catch {
                reject();
            }

        }

        execute(0);

    });

    return promise
}

let deliveryTypes: any = {
    "DELIVERY": "Delivery",
    "TAKEOUT": "Retirar no local",
    "INDOOR": "Na mesa"
}



export const toDeliveryType = (value: string) => {
    return deliveryTypes[value]
}

const weekDays = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado"
]


let months: any = [
    "Janeiro", "Fevereiro", "Março", "Abril",
    "Maio", "Junho", "Julho", "Agosto",
    "Setembro", "Outubro", "Novembro", "Dezembro"
]

export const toMonth = (value: any = 0) => {

    return months[value - 1]
}


export const arrayToWeekDay = (days: number[]) => {
    return days.reduce((acc: any, day: any) => {
        let weekDay = weekDays[day];
        if (weekDay)
            return [...acc, weekDay]
    }, [])?.join(", ")
}



export const decimalToMoney = (value: any = 0, withSeparator = false) => {
    let [number, decimal] = (parseFloat(value).toFixed(2)).toString().split(".");
    let numberFormatted = withSeparator ? number.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : number;
    return "R$ " + numberFormatted + "," + decimal
}

export const decimalToHour = (value: any = 0) => {
    let hour = Math.floor(value);
    let minute = value > 0 ? Math.round((value - hour) * 60) : 0

    return `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`
}

export const hourToDecimal = (value: string = "00:00") => {
    let [hourTxt, minuteTxt] = value.split(":")
    let hour = parseInt(hourTxt);
    let minute = parseInt(minuteTxt) / 60;

    return hour + minute;
}

export const decimalToTime = (value: any = 0) => {

    let hour = Math.floor(value);
    let minute = value > 0 ? Math.round((value - hour) * 60) : 0

    return `${hour.toString().padStart(2, "0")}h${minute.toString().padStart(2, "0")}`
}

export const downscaleImage = async (dataUrl: any, scaleX: number = 1, scaleY: number = 1, length: any = 1024) => {

    var resizedImage;
    var originalSize = (dataUrl.length * (3 / 4)) - 1


    var image = await getImage(dataUrl);



    // Resize the image
    var canvas = document.createElement('canvas'),
        max_size = length,
        width = image.width,
        height = image.height;

    console.log("width", width, height)

    console.log("size = ", ((width * height * 4) / 1024 / 1024) * 0.3)

    if (width > height) {
        if (width > max_size) {
            height *= max_size / width;
            width = max_size;
        }
    } else {
        if (height > max_size) {
            width *= max_size / height;
            height = max_size;
        }
    }
    canvas.width = width;
    canvas.height = height;
    let canvacontext = canvas.getContext('2d')!
    canvacontext.drawImage(image, 0, 0, width, height);
    resizedImage = canvas.toDataURL('image/jpeg');

    const image2 = await getImage(resizedImage);

    var scale = width > height ? height : width;

    canvas = document.createElement('canvas');

    var cw = length * scaleX;
    var ch = length * scaleY;

    canvas.width = cw;
    canvas.height = ch;

    let [x, y] = cw > ch ? [(width - cw) / 2, 0] : [0, (height - ch) / 2]

    canvacontext = canvas.getContext('2d')!
    canvacontext.drawImage(image2,
        0, 0,
        // scale, scale,
        // 0,0,
        length * scaleX, length * scaleY);

    let cuttedImage = canvas.toDataURL('image/jpeg', 0.6);

    var resizedSize = (cuttedImage.length * (3 / 4)) - 1

    console.log('resize: ', (originalSize / 1024 / 1024) + "/" + resizedSize / 1024 / 1024)

    return cuttedImage

}

export const formatDate = (value: any = 0) => {
    let date = new Date(value);
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    return day.toString().padStart(2, "0") + "/" + month.toString().padStart(2, "0") + "/" + year.toString()
}

export const formatHour = (value: any = 0, allowSeconds: boolean = false) => {
    let date = new Date(value);
    let hour = date.getHours();
    let minute = date.getMinutes();
    let seconds = date.getSeconds();
    return hour.toString().padStart(2, "0") + ":" + minute.toString().padStart(2, "0") + (allowSeconds ? ":" + seconds.toString().padStart(2, "0") : "")
}

export const formatDateTime = (value: any = 0) => {
    let date = new Date(value);
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    let hour = date.getHours();
    let minute = date.getMinutes();
    let seconds = date.getSeconds();
    return day.toString().padStart(2, "0") + "/" + month.toString().padStart(2, "0") + "/" + year.toString() + " " + hour.toString().padStart(2, "0") + ":" + minute.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0")
}

export function getImage(dataUrl: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = dataUrl;
        // image.crossOrigin = "something";
        image.onload = () => {
            resolve(image);
        };
        image.onerror = (el: any, err: any) => {
            console.log(el)
            reject(err.error);
        };
    });
}


export const nextStep = (order: any) => {

    const { status, type } = order


    if (status === "PDG")
        return "CFM"

    else if (status === "CFM" && type === "DELIVERY")
        return "DSP"

    else if (status === "CFM" && type === "TAKEOUT")
        return "RTP"

    else if (status === "CFM" && type === "INDOOR")
        return "DSP"

    else if ((status === "DSP" || status === "RTP"))
        return "CON"

    else
        return ""
}

export const urlOrBase = (data: string, prefix: string) => {
    if (!data)
        return "";

    let startsWith = data.substring(0, 4);
    if (startsWith === "base" || startsWith === "data")
        return data
    else
        return prefix + data;
}

export const formatPhoneNumber = (phone: number) => {
    return phone.toString().replace(/(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/, "+$1 ($2) $3 $4-$5");
}

export const formatTimeWithExtension = (value: any = 0) => {
    let date = new Date(value);
    let dateOnly = new Date(value).setHours(0, 0, 0, 0);

    // let now = new Date();

    let nowDateOnly = new Date().setHours(0, 0, 0, 0);

    let daysDiff = ((nowDateOnly - dateOnly) / 1000 / 60 / 60 / 24)


    let year = date.getFullYear().toString();
    let month = date.getMonth().toString();
    let day = date.getDate().toString();
    let hour = date.getHours().toString();
    let minute = date.getMinutes().toString().substring(0, 2);


    if (daysDiff < -365)
        return `${day.padStart(2, "0")} de ${months[parseFloat(month)]} às ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`
    else if (daysDiff <= -2 && daysDiff > -365) {
        return `${day.padStart(2, "0")} de ${months[parseFloat(month)]} às ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`
    }
    else if (daysDiff <= -1 && daysDiff > -2) {
        return `Amanhã às ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`
    }
    else if (daysDiff <= 0 && daysDiff > -1) {
        return `Hoje às ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`
    }
    else if (daysDiff <= 1 && daysDiff > 0)
        return `Ontem às ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`
    else if (daysDiff <= 2 && daysDiff > 1)
        return `Anteontem às ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`
    else if (daysDiff < 365)
        return `${day.padStart(2, "0")} de ${months[parseFloat(month)]} às ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`
    else {
        return `${day.padStart(2, "0")} de ${months[parseFloat(month)]} de ${year} às ${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`
    }

}


export const formatCityName = (city: string, state: string) => {
    city = city.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(" ", "-");
    return (city + "-" + state).toLowerCase()
}

export const getAverageRGB = async (src: any) => {

    const imgEl = await getImage(src);

    var blockSize = 5, // only visit every 5 pixels
        defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = { r: 0, g: 0, b: 0 },
        count = 0;

    if (!context) {
        return defaultRGB;
    }

    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

    context.drawImage(imgEl, 0, 0);

    try {
        data = context.getImageData(0, 0, width, height);
    } catch (e) {
        /* security error, img on diff domain */
        return defaultRGB;
    }

    length = data.data.length;

    while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    return rgb;

}

let value = {
    "name": "Gabriel",
    "pets.dog.name": "Thor",
    "pets.cat.name": "Tigrinho",
    "house.address": "Sitio",
    "house.number": 123,
    "others.0": "-",
    "others.1": "-",
    "others.2": "-"
}


export const checkIsOpen = (schedules: any) => {
    let datetime = new Date();
    let weekdays = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"]
    let dayOfWeek = weekdays[datetime.getDay()];
    let now = datetime.getHours() + (datetime.getMinutes() / 60)
    let closein = 0

    schedules.map((sc: any) => {


        if (sc.weekDay === dayOfWeek) {



            if (now > parseFloat(sc.startTime) && now < parseFloat(sc.endTime)) {
                closein = parseFloat(sc.endTime) - now;
                // setIsOpen(true) 
            }
        }
    })

    return closein > 0
}



export const cloneObject = (obj: any) => {
    var copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = cloneObject(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = cloneObject(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}


const extractFilterFromParams = (params: Object) => {

    const regex = /^(\w+)(?:\[(\w+)\])?$/;

    console.log(params);

    const filters: any = [];

    Object.entries(params).map(([key, value]) => {
        const match = key.match(regex);
        if (match) {
            console.log(match)
            const [, operator, column] = match;

            filters.push({
                column: column || operator,
                operator: column ? operator : "equal",
                value
            })
        }
    })

    return filters;
}

export const queryParamsToFilter = (queryParams: any) => {

    const params: any = {};

    for (let [key, value] of queryParams) {
        params[key] = value;
    }

    console.log(extractFilterFromParams(params)); // [["page", 5], ["pagesize", 25]]

    return queryParams;
}