import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../config';
import Flex from '../elements/Flex';
import Heading from '../elements/Heading';
import Icon, { iconNames } from '../elements/Icon';
import LoadingContent from '../elements/LoadingContent';
import Text from '../elements/Text';
import { useApi } from '../hooks/useAuth';

const Container = styled.div`
    .grid {

    }

    .status {

    }
    .tag{
        background-color: ${p => p.theme.backgroundTertiary};
        padding: 2px 8px;
        border-radius: 4px;
    }


    .task {
        
        border: 1px solid ${props => props.theme.borderTransparent};
        border-bottom: 3px solid ${attr => attr.theme.borderTransparent};
        /* box-shadow: 0px 0px 20px 0px ${attr => attr.theme.shadow}; */
        border-radius: 6px;
        padding: 8px  12px ;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        background-color: ${attr => attr.theme.backgroundPrimary};
        cursor: pointer;

        gap: 12px;
    
    }
`;

function removerTagsHTML(content: any = []) {
    if (!content)
        return ""
    return content.reduce((str: string, c: any) => c.content ? removerTagsHTML(c.content) + str : str + c.text, "")
}

const TaskSchedulePage = () => {

    const navigate = useNavigate();
    const [data, setData] = useState<any[]>([]);
    const api = useApi();
    const [loading, setLoading] = useState(false);

    const get = async () => {
        setLoading(true);

        try {
            const res = await api.get("/tasks");
            if (res.status == 200) setData(res.data);
        }
        catch {

        }
        setLoading(false);
    }

    useEffect(() => {
        get();
    }, [])

    const statusToIcon: { [key: string]: iconNames } = {
        "PDG": "status-paused",
        "ERR": "status-fail",
        "SUC": "status-success",
        "RUN": "play"
    }
    const statusToColor: { [key: string]: string } = {
        "PDG": colors.gray500,
        "ERR": colors.red400,
        "SUC": colors.green400,
        "RUN": colors.blue400
    }

    const startTask = async (code: string) => {
        const res = await api.post<any>("services/startTask/" + code);
        alert(res.status);
    }

    if (loading)
        return <LoadingContent />


    return <Container>

        <Flex direction='vertical'>

            <Heading>Tarefas</Heading>

            {data.map((data) => {
                const last = data.TaskScheduleHistory[0];

                return <div className='task' onClick={() => startTask(data.code)}>

                    {data.isRunning
                        ? <div className='animation-loading'>
                            <Icon name="status-running" color="blue400" />
                        </div>
                        : <div >
                            <Icon name="status-paused" color="gray500" />
                        </div>
                    }

                    <div>
                        <Text color="contentTertiary" bold fontSize={15}>#A84J</Text>
                    </div>

                    <div className='tag'>
                        <Text bold fontSize={15}>{data.code}</Text>
                    </div>

                    <div >
                        <Text bold fontSize={15}>Ultima execução: {last.id} - {last.duration}ms</Text>
                    </div>

                    <div style={{ flex: 1 }} >
                        <Flex justifyContent='flex-end' gap={4}>
                            {data.TaskScheduleHistory.slice(0, 10).map((history: any) => <div>
                                <Icon color={statusToColor[history.status]} name={statusToIcon[history.status]} />
                            </div>)}
                        </Flex>
                    </div>

                    {/* <Flex direction='vertical'> */}


                    {/* <Flex gap={5}>
                            <Text bold fontSize={18}>Tarefa: {data.code}</Text>
                            {data.isRunning ? <div style={{ backgroundColor: colors.blue100 }} className='isRunning'>
                                <Text bold fontSize={14}>Executando</Text>
                            </div> :
                                <div className='isRunning'>
                                    <Text bold fontSize={14}>Parado</Text>
                                </div>}
                        </Flex> */}
                    {/* <Flex direction='vertical'>
                    {data.TaskScheduleHistory.map((history: any) => (
                        <Flex gap={5}>
                            <div style={{ width: 10 }} />
                            <Icon color={statusToColor[history.status]} name={statusToIcon[history.status]} />
                            <Text>{formatDateTime(history.startsAt)}</Text>
                            <Text bold>{(history.duration)} ms</Text>
                        </Flex>
                    ))}
                </Flex> */}
                    {/* </Flex> */}
                </div>
            })}
        </Flex>

    </Container>;

}

export default TaskSchedulePage;