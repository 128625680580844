
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SecurityArea from '../../../components/SecurityArea';
import Flex from '../../../elements/Flex';
import Heading from '../../../elements/Heading';
import Icon from '../../../elements/Icon';
import IconButton from '../../../elements/IconButton';
import Text from '../../../elements/Text';
import { IEntityBaseClass } from '../../../hooks/useEntity';
import { useModal } from '../../../hooks/useModal';
import { getContrast2 } from '../../../services/functions';
import IEntityService from '../interfaces/service';

const Container = styled.div`
  
    line-height: 1;
    user-select: none;
    text-rendering: optimizeLegibility;
    margin: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    font-size: 100%;
    vertical-align: baseline;
    outline: 0;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background:  ${p => p.theme.backgroundSecondary};
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 20px;
    border-color: ${p => p.theme.borderTransparent};
    cursor: pointer;

    &:hover {
        background:  ${p => p.theme.backgroundTertiary};
    }

    > header {
        flex-direction: row;
        display: flex;
        margin-bottom: 20px;

        > .details {
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            gap: 10px;

            >.icon {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }

            > img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
        }
    }

    ul {
        li {
            list-style: none;
            display: flex;
            align-items: center;
            gap: 6px;
            margin-bottom: 6px;
        }
    }
`;

interface IServiceCard {
    Entity: IEntityBaseClass<IEntityService>
}

const ServiceCard = ({
    Entity
}: IServiceCard) => {

    const navigate = useNavigate();
    const modal = useModal();

    const permissions = [
        {
            name: "allowReply",
            description: "Responder documento"
        },
        {
            name: "allowForward",
            description: "Encaminhar documento"
        },
        {
            name: "allowAttachment",
            description: "Anexar arquivos"
        }
    ]

    const handleDelete = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        await modal.dialog({
            title: "Deseja mesmo deletar este serviço ?"
        });
        Entity.Delete().Save();
    }

    return <Container onClick={() => navigate(Entity.id || "")}>

        <header >
            <div className="details" >
                <div className='icon' style={{ backgroundColor: Entity.data.color }}>
                    <Icon name={Entity.data.icon} size={20} color={getContrast2(Entity.data.color)} />
                </div>
                <Heading size={4} >{Entity.data.title}</Heading>
            </div>
            <Flex direction='horizontal'>
                <SecurityArea permission="entity::services::delete">
                    <IconButton size={18} icon="trash" color="red400" onClick={handleDelete} />
                </SecurityArea>
            </Flex>
        </header>

        <div>
            <Heading size={5} >Sobre este serviço</Heading>
            <Text fontSize={14}>{Entity.data.description}</Text>
        </div>

        <div style={{ marginTop: 20 }}>
            <Heading size={5} >Permissões</Heading>
            <ul>
                {permissions.map(perm => {
                    if (Object.entries(Entity.data).find(([key, value]) => key == perm.name && Boolean(value)))
                        return <li>
                            <Icon name={"check_circle"} size={20} color={"green400"} />
                            <Text color="contentSecondary" fontSize={14}>{perm.description}</Text>
                        </li>
                })}
            </ul>
        </div>

    </Container>;
}

export default ServiceCard;