import { useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../config';
import Icon from '../Icon';
import Input from './Input';

const Container = styled.div`
   
     flex: 1;
        width: 100%;
        /* height:42px; */
        border-radius: 4px;
        background-color: ${colors.background};
        /* margin-bottom: 10px; */
         height: auto; 
       
        border-bottom: 1px solid ${colors.gray100};
        outline: none;

        border-width: 1px;
        border-style: solid;
        border-color: ${colors.gray300};
        transition:200ms;
       // box-shadow: 0px 0px 12px 0px ${colors.gray200}99;\

        background-color: ${p => p.theme.backgroundTertiary};

      
    
        .left-icon {
            margin-right: 8px;
            height:42px;
            /* width: 42px; */
            display: flex;
            align-items: center;
            justify-content: center;
        }

        input,textarea {
            padding: 0 8px;
            outline: none;
            flex: 1;
            width: 100%;
            padding-left: 10px;

            /* &:focus {
                   border-width: 1px;
                    border-style: solid;
               border-color: ${colors.gray200};
               border-radius: 6px;
                box-shadow: 0px 0px 12px 0px ${colors.gray200};
            } */
          
        }

        input {
            height: 33px;
        }
        textarea {
            height: auto;
            padding: 8px;
        }

        &:focus {
            border-width: 2px;
            border-color: ${colors.blue400};
        }

`;

const TextInput = ({ title, required, numberOfLines = 1, password, icon, name, placeholder, showLimit = false, clearErrors, readonly = false, value = "", description, max, onInput, error = false, rows = 1, padding = 5, leftIcon, rightIcon }: any) => {

    const [focused, setFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(!password);
    const ref = useRef<any>(null);
    const onChangeText = (e: any) => {

        const text = e.currentTarget.value;

        if (max && text.length > max) {
            onInput(text.toString().substring(0, max))
        }
        else if (!max || text.length <= max) {
            onInput(text)
        }

    }

    console.log(value)

    return <Input {...{ title, required, description }}><Container>



        {[
            !!icon && <div className="left-icon"><Icon size={18} name={icon} /></div>,
            numberOfLines == 1 ? <input
                type={!showPassword ? "password" : "text"}
                placeholder={placeholder}
                name={name}
                readOnly={readonly}
                ref={ref}
                onFocus={() => setFocused(true)}
                value={value || ""}

                onChange={onChangeText}
                onBlur={() => setFocused(false)} /> :
                <textarea
                    placeholder={placeholder}
                    name={name}
                    readOnly={readonly}
                    ref={ref}
                    onFocus={() => setFocused(true)}
                    value={value || ""}
                    rows={numberOfLines}
                    onChange={onChangeText}
                    onBlur={() => setFocused(false)} />
        ]}

    </Container> </Input>
}


export default TextInput