

import styled from 'styled-components';

const Container = styled.td`
  
`;

const Column = ({ children }: any) => {
    return <Container>{children}</Container>;
}

export default Column;