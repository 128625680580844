import styled from 'styled-components';
import MailCard from '../../components/MailCard';
import { colors } from '../../config';
import Heading from '../../elements/Heading';
import Icon from '../../elements/Icon';
import { useAuth } from '../../hooks/useAuth';
import { useTheme, useThemeSwitch } from '../../hooks/useTheme';

const Container = styled.div`
    padding: 40px;
    width: 100%;

    .demo {
        border: 1px solid ${p => p.theme.borderTransparent};
        background-color: ${p => p.theme.backgroundSecondary};
        padding: 16px;
        border-radius: 8px;
        width: 100%;
        margin: 20px 0;
    }

    .themes {
        display: flex;
        gap: 12px;

        .theme {
            width: 200px;
            height: 100px;
            border: 1px solid ${p => p.theme.borderTransparent};
            background-color: ${p => p.theme.backgroundSecondary};
            border-radius: 6px;
            position: relative;
            cursor: pointer;

            &:hover {
                box-shadow: 0px 0px 10px 0px #0002;
            }

            &.selected {
                border: 2px solid ${p => p.theme.blue400};
            }

            .selected {
                width: 24px;
                height: 24px;
                border-radius: 12px;
                background-color: ${p => p.theme.blue400};
                padding: 3px;
                position: absolute;
                bottom: -6px;
                right: 12px;
            }
        }
    }
`;


const AppearancePage = () => {

    const auth = useAuth();
    const { current } = useTheme();
    const themeSwitch = useThemeSwitch();

    return <Container>

        <Heading size={3}>Aparência</Heading>

        <div className='demo'>
            <MailCard data={{
                title: "Demonstração",
                code: "",
                _count: {},
                content: [{ "type": "paragraph", "content": [{ "type": "text", "text": "Demonstração de tema" }] }],
                id: "123",
                createdAt: new Date(),
                receivers: [],
                sender: { ...auth.user, roles: [] }
            }} />
        </div>

        <Heading size={4}>TEMA</Heading>

        <div className='themes'>
            <div onClick={() => themeSwitch("light")} className={'theme ' + (current == "light" ? "selected" : "")}>
                {current == "light" && <div className='selected'><Icon color="backgroundPrimary" size={18} name="check" /></div>}
            </div>
            <div onClick={() => themeSwitch("dark")} className={'theme ' + (current == "dark" ? "selected" : "")} style={{ backgroundColor: colors.gray700 }}>
                {current == "dark" && <div className='selected'><Icon color="backgroundPrimary" size={18} name="check" /></div>}
            </div>
            <div onClick={() => themeSwitch("contrast")} className={'theme ' + (current == "contrast" ? "selected" : "")} style={{ backgroundColor: colors.black }}>
                {current == "contrast" && <div className='selected'><Icon color="backgroundPrimary" size={18} name="check" /></div>}
            </div>
        </div>

    </Container>;

}

export default AppearancePage;