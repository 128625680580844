
import styled, { useTheme } from 'styled-components';
import { TextViewer } from '../../../components/TextEditor';
import UserPicture from '../../../components/user/UserPicture';
import Flex from '../../../elements/Flex';
import Icon from '../../../elements/Icon';


const Container = styled.div`

    display: flex;
  

    > .left {
        position: relative;
        width: 42px;

        > .box {

            width: 42px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;

        > .dot {

            position: relative;
            //margin: 8px auto;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            border: 2px solid ${p => p.theme.borderOpaque};
            background-color: ${p => p.theme.contentTertiary};
        }

        > .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 22px;
            height: 22px;
            border-radius: 11px;
           // border: 2px solid ${p => p.theme.borderOpaque};
            background-color: ${p => p.theme.contentTertiary};
        }
        > .user {
            border: 2px solid ${p => p.theme.borderOpaque};
            position: relative;
          //  margin: 8px auto;
            width: 32px;
            height: 32px;
            border-radius: 16px;
            background-color: ${p => p.theme.contentTertiary};
        }
    }

        > .line-in, > .line-out {
            width: 2px;
            left: 20px;
            position: absolute;
            height: 100%;
            background-color: ${p => p.theme.borderOpaque};
        }
        > .line-in {
            height: 21px;
        }
        > .line-out {

            top: 21px;
            height: calc( 100% - 21px );
        }
    }


    > .right {

        flex: 1;

        > .broadcast {
            min-height: 42px;
            display: flex;
            align-items: center;
        }
        > .comment {
            border-radius: 8px;
            padding: 12px;
            border: 1px solid ${props => props.theme.borderTransparent};
            //box-shadow: 0px 0px 30px 0px #0001;
            background-color: ${props => props.theme.backgroundSecondary};
            margin-bottom: 12px;

            > .info {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &:first-child > .left > .line-in {display: none}
    &:last-child  > .left > .line-out {display: none}

`

const DiscussionCard = ({ data: { system, author, resolved, noteHtml, attachment, resolvable }, index }: any) => {

    const theme = useTheme();

    return <Container>
        <div className='left'>
            <div className='line-in' />
            <div className='line-out' />
            <div className='box'>
                {system
                    ? <div className='dot'></div>
                    : <>
                        {attachment == "IA"
                            ? <div className='icon' style={{ backgroundColor: theme["blue100"] }}>
                                <Icon name="sparkles" size={18} color="blue400" />
                            </div>
                            : <div className='user'>
                                <UserPicture data={author} />
                            </div>
                        }
                    </>
                }
            </div>
        </div>
        <div className='right'>

            {system
                ? <div className='broadcast'>
                    <div className='info'><TextViewer content={JSON.parse(noteHtml)} /></div>
                </div>
                : <div className='comment'>
                    <div className='info'>
                        <div>
                            <a href="#"><b>{author.name}</b></a>
                        </div>
                        <Flex gap={4}>
                            {resolvable && <Icon name={resolved ? "check_circle" : "close_circle"} color={resolved ? "green400" : "red400"} size={18} />}
                        </Flex>
                    </div>

                    <div style={{ height: 4 }} />
                    <TextViewer content={JSON.parse(noteHtml)} />
                </div>
            }

        </div>
    </Container>
}



export default DiscussionCard;