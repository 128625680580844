import { createGlobalStyle } from 'styled-components';
import WHITNEYBOLD from './assets/fonts/whitney-bold.woff';
import WHITNEY from './assets/fonts/whitney.woff';

import BARLOWBOLD from './assets/fonts/barlow-bold.ttf';
import BARLOW from './assets/fonts/barlow-medium.ttf';


import GOTHAMBOLD from './assets/fonts/gotham/Gotham-Bold.otf';
import GOTHAM from './assets/fonts/gotham/Gotham-Book.otf';
import GOTHAMLIGHT from './assets/fonts/gotham/Gotham-Light.otf';
import GOTHAMMEDIUM from './assets/fonts/gotham/Gotham-Medium.otf';
import { colors } from './config';


export default createGlobalStyle`


  @keyframes animation-loading{
      from {

      }
      to {
          transform: rotate(360deg);
      }
  }

  .animation-loading {
    animation: animation-loading infinite linear 1000ms;
  }

/* 
  @media print{
      .noprint{
        display: none;
      }
      @page { margin: 0; }
      
  } */
/* 
  @font-face {
    font-family: "San Francisco";
    font-weight: 400;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
  } */

    /* @font-face{ 
      font-family:gotham;
      font-style:normal;
      font-weight:400;
      src:url(${GOTHAM});
    }
    @font-face{ 
      font-family:gotham;
      font-style:normal;
      font-weight:300;
      src:url(${GOTHAMLIGHT}) ;
    }
    @font-face{ 
      font-family:gotham;
      font-style:normal;
      font-weight:500;
      src:url(${GOTHAMMEDIUM});
    }
    @font-face{ 
      font-family:gotham; 
      font-style:normal;
      font-weight:700;
      src:url(${GOTHAMBOLD});
    } */
      /* @font-face{font-family:gotham;font-style:italic;font-weight:400;src:local('Gotham'),url(https://fonts.cdnfonts.com/s/62851/GothamBookItalic.woff) format('woff')} */
      /* @font-face{font-family:gotham;font-style:italic;font-weight:300;src:local('Gotham'),url(https://fonts.cdnfonts.com/s/62851/GothamLightItalic.woff) format('woff')} */
      /* @font-face{font-family:gotham;font-style:normal;font-weight:500;src:local('Gotham'),url(https://fonts.cdnfonts.com/s/62851/GothamMedium.woff) format('woff')} */
      /* @font-face{font-family:gotham;font-style:normal;font-weight:500;src:local('Gotham'),url(https://fonts.cdnfonts.com/s/62851/GothamMedium_1.woff) format('woff')} */
      /* @font-face{font-family:gotham;font-style:italic;font-weight:500;src:local('Gotham'),url(https://fonts.cdnfonts.com/s/62851/GothamMediumItalic.woff) format('woff')} */
      /* @font-face{font-family:gotham;font-style:normal;font-weight:700;src:local('Gotham'),url(https://fonts.cdnfonts.com/s/62851/GothamBold.woff) format('woff')} */
      /* @font-face{font-family:gotham;font-style:normal;font-weight:700;src:local('Gotham'),url(https://fonts.cdnfonts.com/s/62851/GothamBold.woff) format('woff')} */
      /* @font-face{font-family:gotham;font-style:italic;font-weight:700;src:local('Gotham'),url(https://fonts.cdnfonts.com/s/62851/GothamBoldItalic.woff) format('woff')} */
     
/* 
    @font-face {
        font-family: Whitney;
        src: url(${WHITNEY});
    }  
    @font-face {
        font-family: Barlow;
        src: url(${BARLOW});
    } 
    @font-face {
        font-family: Barlow Bold;
        src: url(${BARLOWBOLD});
    } 
    @font-face {
        font-family: Whitney Bold;
        src: url(${WHITNEYBOLD});
    }      */

  * {
    margin: 0;
    padding: 0;
    color: ${colors.title};
    box-sizing: border-box;

  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
   
  }


  html, body, #root {
    max-height: 100vh;
    max-width: 100vw;
    width: 100%;
    height: 100%;
  }
  .draggable{
    cursor: grab;
  }
  *, button, input {
    border: 0;
    background: none;

    font-family: 'Roboto', sans-serif;
    /* font-family: Barlow, -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Ubuntu, Arial, sans-serif; */
  }
  a{
    font-family: Whitney Bold;
    color: ${colors.link};
    text-decoration: none;
  }
  html {
    background: var(--primary);
  }
  :root {
    --primary_color: #121212;
  --inverse_primary_color: #fff;
  --money: #38B64A;

  --title: #2E3A59;
  --subtitle: #7884A2;

  --background: #FFFFFF;
  --background_secondary: #F0F0F0;
  --ripple: #F0F0F0;
  --shape: #F0F0F0;
  --white: #FFFFFF;
  --gray: #CFCFCF;

  --blue: #3D7199;
  --blue_light: #EBF6FF;

  --error: #E83F5B;
  --error_title: #ffffff;

  --success: #38B64A;
  --success_title: #ffffff;

  --fontBold: Whitney Bold;
  --font: Whitney;

  --facebook: #1877f2;
  }

  /* width */
  ::-webkit-scrollbar {
    background: #888;
    height: 15px;
    width: 15px;
    border-radius: 5px;
    border: 5px solid ${colors.background};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.background};
    border-radius: 10px;
 
    
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
     border-radius: 10px;
     border: 3px solid ${colors.background};
     background: #ddd;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #bbb
    
  }


  @media (max-width: 975px) {
    /* .max975{
      display: block;
    } */
    .min975{
      display: none;
    }
  }
  @media (min-width: 976px) {
    /* .min975{
      display: block;
    } */
    .max975{
      display: none;
    }
  }


  

  @media (max-width: 850px) {
    /* .max850{
      display: block;
    } */
    .min850{
      display: none;
    }
  }

  @media (min-width: 851px) {
    /* .min850{
      display: block;
    } */
    .max850{
      display: none;
    }
  }


  @media (max-width: 350px) {
    /* .max350{
      display: block;
    } */
    .min350{
      display: none;
    }
  }

  @media (min-width: 351px) {
    /* .min350{
      display: block;
    } */
    .max350{
      display: none;
    }
  }



  @media (max-width: 450px) {

    .min450{
      display: none;
    }
  }

  @media (min-width: 451px) {

    .max450{
      display: none;
    }
  }


  @media (max-width: 550px) {
    .min550{
      display: none;
    }
  }

  @media (min-width: 551px) {
    .max550{
      display: none;
    }
  }


  @media (max-width: 650px) {
    .min650{
      display: none;
    }
  }

  @media (min-width: 651px) {
    .max650{
      display: none;
    }
  }


  @media (max-width: 750px) {
    .min750{
      display: none;
    }
  }

  @media (min-width: 751px) {
    .max750{
      display: none;
    }
  }


`;