import { Route, Routes } from 'react-router-dom';
import { useEntityTable } from '../../hooks/useEntity';
import UserForm from './form';
import ServiceGrid from './grid';

const UserPage = ({ }: any) => {

    const Entity = useEntityTable("users");

    return <Routes>
        <Route index element={<ServiceGrid Entity={Entity} />} />
        <Route path=":id" element={<UserForm EntityTable={Entity} />} />
    </Routes>


}

export default UserPage;