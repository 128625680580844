import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import Button from '../../elements/Button';
import TabControl from '../../elements/TabControl';
import TextInput from '../../elements/inputs/TextInput';
import { useApi, useAuth, useAuthDispatch } from '../../hooks/useAuth';
import { useForm } from '../../hooks/useForm';
import AppearancePage from '../settings/appearance';

const Container = styled.div`

    padding: 20px;

`;


const ProfilePage = ({ }) => {

    const { id } = useParams();
    const { logout } = useAuthDispatch();
    const navigate = useNavigate();
    const theme = useTheme()
    const api = useApi();

    const auth = useAuth();
    const form = useForm(auth.user);

    const get = async () => {
        const res = await api.get("/")
    }


    return <Container>

        <TabControl

            tabs={[
                {
                    name: 'user',
                    title: "Usuário",
                    component: <>

                        <TextInput title="Nome" {...form.registerField("name")} />
                        <TextInput title="email" {...form.registerField("email")} />

                        <Button onClick={logout} title="Desconectar" />

                    </>
                },
                {
                    name: 'appearance',
                    title: "Aparência",
                    component: <AppearancePage />
                },
            ]}

        />


    </Container>;

}

export default ProfilePage;