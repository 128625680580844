import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import Box from '../../elements/Box';
import Button from '../../elements/Button';
import Heading from '../../elements/Heading';
import Text from '../../elements/Text';
import BooleanInput from '../../elements/inputs/BooleanInput';
import ColorInput from '../../elements/inputs/ColorInput';
import IconInput from '../../elements/inputs/IconInput';
import SelectInput from '../../elements/inputs/SelectInput';
import TextInput from '../../elements/inputs/TextInput';
import { useApi } from '../../hooks/useAuth';
import { IEntityTable, useSingleEntity } from '../../hooks/useEntity';
import { useForm } from '../../hooks/useForm';

const Container = styled.div`

    padding: 20px;
    display: flex;
   justify-content: center;

    > .content {
        width: 100%;
        max-width: 900px;
        gap: 20px;
        display: flex;

        > .left, > .right {
            flex: 1;
        }
    }

    .step {
        display: flex;
        gap: 4px;
        align-items: center;
    }

`;


const ServiceForm = ({ EntityTable }: { EntityTable: IEntityTable }) => {

    const { id } = useParams();

    const Entity = useSingleEntity("services", { id });
    // const EntityTeams = useSingleEntity("teams", { id });

    const form = useForm(Entity.data);
    const navigate = useNavigate();
    const theme = useTheme();

    const [teams, setTeams] = useState([]);

    const api = useApi();

    const formSteps = useForm();

    const [steps, setSteps] = useState<any[]>([]);

    const get = async () => {
        await Entity.Get();

        const res = await api.get("/teams");
        setTeams(res.data);

        form.setInitialData(Entity.data)
        setSteps(Entity.data.steps || [])
        //await EntityTeams.Get();
    }

    const submit = async () => {
        await api.put("/services/" + id, {
            service: { ...form.getFields() },
            steps
        })
    }

    const newStep = async () => {
        setSteps([...steps, { id: undefined, name: "Novo" }])
    }

    console.log(formSteps.fields.steps)

    useEffect(() => {
        get();
    }, [])



    return <Container>

        <div className='content'>

            <div className='left'>
                <Box>

                    <Heading>Serviço - {form.fields.title}</Heading>

                    <TextInput title="Nome" {...form.registerField("title")} />
                    <TextInput numberOfLines={4} title="Descrição" {...form.registerField("description")} />

                    <ColorInput
                        title="Cor do serviço"
                        description="Selecione uma cor para representar o serviço"
                        {...form.registerField("color")}
                    />

                    <IconInput
                        title="Icone do serviço"
                        description="Os icones ficaram viziveis no card de soluçoes."
                        {...form.registerField("icon")}
                    />

                    <Button title="Salvar" onClick={submit} />
                </Box>
            </div>

            <div className='right'>
                <Box>

                    <Heading>Comportamento</Heading>

                    <BooleanInput
                        title="Permitir encaminhar"
                        {...form.registerField("allowForward")}
                    />

                    <BooleanInput
                        title="Permitir responder"
                        {...form.registerField("allowReply")}
                    />

                    <BooleanInput
                        title="Permitir anexar"
                        {...form.registerField("allowAttachment")}
                    />

                </Box>
                <Box>
                    <Heading>Etapas</Heading>

                    {steps.map((step: any, i: number) => <li className='step'>
                        <Text bold>{i + 1}.</Text>
                        <TextInput onInput={(v: any) => setSteps(s => {
                            s[i].name = v
                            return [...s]
                        })} value={steps[i].name} />
                        <SelectInput options={teams.map((team: any) => ({
                            name: team.id,
                            title: team.title
                        }))} onInput={(v: any) => setSteps(s => {
                            s[i].teamId = v
                            return [...s]
                        })} value={steps[i].teamId} />


                    </li>)}

                    <Button title="Novo" onClick={newStep} />
                </Box>
            </div>

        </div>
    </Container>;

}

export default ServiceForm;