import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Button2 from '../../elements/Button2';
import Flex from '../../elements/Flex';
import Heading from '../../elements/Heading';
import TabControl from '../../elements/TabControl';
import { IEntityTable } from '../../hooks/useEntity';
import { useModal } from '../../hooks/useModal';
import TeamCard from './components/TeamCard';
import CreateTeamModal from './modals/create';

const Container = styled.div`

    section.grid {
        padding: 20px 0px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 224px);
        gap: 20px;
        
        .card {
            padding: 16px;
            width: 200px;
            height: 200px;
            border: 1px solid ${props => props.theme.borderTransparent};
            border-bottom: 3px solid ${props => props.theme.borderTransparent};
            background-color: ${props => props.theme.backgroundPrimary};
            border-radius: 6px;

            &.button {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    background-color: ${props => props.theme.backgroundSecondary};
                }
            }
        }
    }

    section.flow {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .group {

            display: flex;
            flex-direction: column;
            align-items: center;
           
        
            > .father {
                width: 200px;
                height: 100px;
                background-color: #bbb;
                border-radius: 6px;
                position: relative;

                .after{
                    content: "";
                    display: block;
                    width: 4px;
                    height: 20px;
                    background-color: #777;
                    position: absolute;
                    top: 100%;
                    left: calc(50% - 2px);
                }
                .after-left{
                    content: "";
                    display: block;
                    width: 50%;
                    height: 4px;
                    background-color: #777;
                    position: absolute;
                    top: calc(100% + 16px);
                    left: 0px;
                }.after-right{
                    content: "";
                    display: block;
                    width: 50%;
                    height: 4px;
                    background-color: #777;
                    position: absolute;
                    top: calc(100% + 16px);
                    right: 0px;
                }

                .before-right{
                    content: "";
                    display: block;
                    width: 50%;
                    height: 4px;
                    background-color: #777;
                    position: absolute;
                    bottom: calc(100% + 16px);
                    right: 0px;
                }
                .before-left{
                    content: "";
                    display: block;
                    width: 50%;
                    height: 4px;
                    background-color: #777;
                    position: absolute;
                    bottom: calc(100% + 16px);
                    left: 0px;
                }


                .before{
                    content: "";
                    display: block;
                    width: 4px;
                    height: 20px;
                    background-color: #777;
                    position: absolute;
                    bottom: 100%;
                    left: calc(50% - 2px);
                }

            }
            > .childrens {
                display: flex;
                justify-content: center;
                padding-top: 36px;
            }
        }

    }
`;

const TeamGrid = ({ Entity }: { Entity: IEntityTable }) => {


    const modal = useModal();
    const navigate = useNavigate();


    const [searchParams] = useSearchParams();

    const get = async () => {
        if (Entity)
            Entity.GetMany();
    }

    const create = async () => {
        Entity.Create({
            title: "Novo grupo",
            code: "NOVOGRUPO-" + new Date().getTime().toString(36),
        }).Save();
    }

    useEffect(() => {
        console.log("get");
        if (Entity.data.length == 0)
            get();
    }, [Entity])


    return <Container>

        {/* <Flex style={{ padding: "20px 0px ", gap: 10 }}>
            <Button title="Novo team" onClick={create} />
            <Button title="Atualizar" onClick={get} />
        </Flex> */}

        <Flex style={{ paddingTop: 20 }} alignItems="center" justifyContent='space-between'>
            <Heading size={1}>Equipes</Heading>
            <Button2
                title="Criar equipe"
                onClick={() => modal.open(<CreateTeamModal callback={get} />)}

            />
        </Flex>

        <TabControl
            tabs={[
                {
                    component: <section className='grid'>
                        {Entity.data.map(entity => (<TeamCard entity={entity} />))}
                    </section>,
                    name: "grid",
                    icon: "vertical-grid",
                    title: "Vizualizar grupos"
                },
                {
                    component: <FlowTab />,
                    icon: "diagram",
                    name: "flow",
                    title: "Vizualizar hierarquia"
                }
            ]}
        />



    </Container >;

}

const FlowTab = () => {
    return <section className='flow'>
        <div className='group'>
            <div className='father'>
                <div className='after' />
                <div className='after-left' />
                <div className='after-right' />
            </div>
            <div className='childrens'>
                <div className='group'>
                    <div className='father'>
                        <div className='before' />
                        <div className='before-right' />
                        <div className='after' />
                    </div>
                    <div className='childrens'>
                        <div className='group'>
                            <div className='father'>
                                <div className='before' />
                                <div className='before-right' />
                            </div>
                        </div>
                        <div className='group'>
                            <div className='father'>
                                <div className='before' />
                                <div className='before-left' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='group'>
                    <div className='father'>
                        <div className='before' />
                        <div className='before-right' />
                        <div className='before-left' />
                    </div>
                </div>
                <div className='group'>
                    <div className='father'>
                        <div className='before' />
                        <div className='before-left' />
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default TeamGrid;