import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import ConnectionGrid from '../../components/ConnectionGrid';
import Button from '../../elements/Button';
import TabControl from '../../elements/TabControl';
import BooleanInput from '../../elements/inputs/BooleanInput';
import ColorInput from '../../elements/inputs/ColorInput';
import TextInput from '../../elements/inputs/TextInput';
import { useApi } from '../../hooks/useAuth';
import { IEntityTable } from '../../hooks/useEntity';
import { useForm } from '../../hooks/useForm';

const Container = styled.div`

    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        width: 100%;
        max-width: 800px;
    }

`;


const RoleForm = ({ EntityTable }: { EntityTable: IEntityTable }) => {

    const { id } = useParams();
    const Entity = id && EntityTable.GetEntity(id);
    const form = useForm();
    const navigate = useNavigate();
    const theme = useTheme()
    const api = useApi();
    console.log(Entity);

    // const initialize = async () => {
    //     console.log("initializing");
    //     if (id && id == "novo") {
    //         Entity.Create({});
    //         form.setInitialData(Entity.data);
    //     }
    //     else if (id) {
    //         await Entity.Get();
    //         form.setInitialData(Entity.data);
    //     }
    //     // else {
    //     //     Entity.Create();
    //     // }
    //     // await Entity.Get();
    //     // form.setInitialData(Entity.data)
    // }

    const submit = async () => {
        if (id == "novo") {
            await api.post("/roles", form.getFields())
        } else {
            await api.put("/roles/" + id, form.getFields())
        }
        navigate("../")
    }

    const get = async () => {
        if (id != "novo") {
            const res = await api.get("/roles/" + id);
            form.setInitialData(res.data);
        }
    }


    useEffect(() => {
        get()
    }, [id])

    // useEffect(() => {
    //     if (Entity)
    //         Entity.Update(form.getFields())
    // }, [form])

    useEffect(() => {
        if (Entity)
            form.setInitialData(Entity.data)
        // initialize();
    }, [Entity])

    console.log(form.fields)


    return <Container>

        <div className='content'>

            <TabControl

                tabs={[
                    {
                        name: 'role',
                        title: "Cargo",
                        component: <>
                            <TextInput title="Nome" {...form.registerField("name")} />
                            <TextInput numberOfLines={4} title="Descrição" {...form.registerField("description")} />
                            <ColorInput title="Cor" {...form.registerField("color")} />
                            <TextInput title="Icone" {...form.registerField("icon")} />
                            <BooleanInput title="Mencionavel" {...form.registerField("mentionable")} />
                        </>
                    },
                    {
                        name: 'users',
                        count: form.fields.users?.length,
                        title: "Usuários",
                        component: <>
                            <ConnectionGrid
                                key="dsdddddd"
                                url="/users"
                                labelKey='name'
                                {...form.registerField("users")}
                            />
                        </>
                    },
                    {
                        name: 'teams',
                        title: "Setores",
                        count: form.fields.teams?.length,
                        component: <>
                            <ConnectionGrid
                                key="dasdasd"
                                url="/teams"
                                keyPath='teamId'
                                keyObject='team'
                                labelKey='title'
                                {...form.registerField("teams")}
                            />
                        </>
                    }
                ]}

            />


            <Button title="Salvar" onClick={submit} />

        </div>


    </Container>;

}

export default RoleForm;