import { forwardRef } from 'react';

import styled from 'styled-components';

import { NavLink } from 'react-router-dom';
import Button from '../elements/Button';
import Flex from '../elements/Flex';
import Icon from '../elements/Icon';
import Text from '../elements/Text';
import { useAuth } from '../hooks/useAuth';
import { useModal } from '../hooks/useModal';
import { useThemeSwitch } from '../hooks/useTheme';
import LoginPage from '../pages/Login';
import NotificationsModal from '../pages/modals/NotificationsModal';
import SecurityArea from './SecurityArea';
import UserPicture from './user/UserPicture';
// import { useAuth, useAuthDispatch } from '../hooks/useAuth';



const Container = styled.div`

    background-color: ${p => p.theme.backgroundPrimary};
    border-bottom: 2px solid ${p => p.theme.borderTransparent};

> header {
    margin: 0 auto;
    max-width: 1080px;

    height: 60px;
    padding: 0 10px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > .left,.center,.right {
        align-items: center;
        display: flex;
    }

    > .center{
         flex: 1;
         justify-content: center;
    }

    
}

  
  
`;


const NavItem = styled.div`

    cursor: pointer;

    > a {
        padding: 0px 20px;
        position: relative;
        background-color: ${props => props.theme.backgroundPrimary};
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        /* margin-bottom: 3px; */

        > .icon{
            margin-right: 12px;
         
            display: flex;
            flex-direction: column;
            align-items: center;

            svg{
                fill: ${props => props.theme.title500};
            }
        }

        > .picture {
            width: 32px;
            height: 32px;
            background-size: cover;
            margin-right: 8px;
        }

        >.alerts{
            .alert{
                background-color: ${props => props.theme.red400};
                padding: 0 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                box-sizing: border-box;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                flex: 0 0 auto;
            }
        }
        
        > .text {
            flex: 1;
            color: ${props => props.theme.title500};
        }

        &.active::after{
            content: "";
            height: 2px;
            width: 100%;
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            background-color: ${props => props.theme.backgroundInverseSecondary};
        }

        &:hover{
            background-color: ${props => props.theme.title200}
        }

    }

   

    
  


  
`;

const Header = forwardRef(({ }: any, ref) => {

    const { user } = useAuth();
    const modal = useModal();
    const themeSwitch = useThemeSwitch();

    const showNotifications = (e: React.MouseEvent<HTMLDivElement>) => {
        modal.context({
            children: <NotificationsModal />,
            target: e.currentTarget
        })
    }

    const login = () => {
        modal.open(<LoginPage />);
    }

    return <Container>

        <header>

            <div className="left">
                <img style={{
                    height: 24
                }} src={"dbseti.png"} />
            </div>

            <div className="center">
                <NavItem>
                    <NavLink to={"/"} >
                        {({ isActive }) => <Flex alignItems='center' gap={8}>
                            <Icon color={isActive ? "contentPrimary" : "contentTertiary"} name={"home"} size={18} />
                            <Text nowrap color={isActive ? "contentPrimary" : "contentTertiary"} fontSize={16} >Início</Text>
                        </Flex>}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink to={"/documentos"} >
                        {({ isActive }) => <Flex alignItems='center' gap={8}>
                            <Icon color={isActive ? "contentPrimary" : "contentTertiary"} name={"inbox"} size={18} />
                            <Text nowrap color={isActive ? "contentPrimary" : "contentTertiary"} fontSize={16} >Documentos</Text>
                        </Flex>}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink to={"/solucoes"} >
                        {({ isActive }) => <Flex alignItems='center' gap={8}>
                            <Icon color={isActive ? "contentPrimary" : "contentTertiary"} name={"vertical-grid"} size={18} />
                            <Text nowrap color={isActive ? "contentPrimary" : "contentTertiary"} fontSize={16} >Soluções</Text>
                        </Flex>}
                    </NavLink>
                </NavItem>

                <SecurityArea permission="admin::*">
                    <NavItem>
                        <NavLink to={"/admin"} >
                            {({ isActive }) => <Flex alignItems='center' gap={8}>
                                <Icon color={isActive ? "contentPrimary" : "contentTertiary"} name={"shield_lock"} size={18} />
                                <Text nowrap color={isActive ? "contentPrimary" : "contentTertiary"} fontSize={16} >Admin</Text>
                            </Flex>}
                        </NavLink>
                    </NavItem>
                </SecurityArea>

            </div>

            {/* <div className="right" >
                <IconButton onClick={themeSwitch} alerts={0} icon="arrow-in-out" color={colors.blue400} />
            </div>
            <div className="right" >
                <IconButton onClick={showNotifications} alerts={0} icon="bell" color={colors.blue400} />
            </div> */}
            {user && <div className="right">
                <NavItem>
                    <NavLink to={"/perfil"} >
                        <UserPicture data={user} size={32} />
                    </NavLink>
                </NavItem>
            </div>}
            {!user && <div className="right">
                {/* <NavItem>
                    <NavLink to={"/login"} > */}
                <Button title="Entrar" onClick={login} />
                {/* </NavLink>
                </NavItem> */}
            </div>}
        </header>

    </Container >;
})


export default Header;