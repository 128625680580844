import styled from 'styled-components';

const Container = styled.div`
    //border: 1px solid ${p => p.theme.borderTransparent} ;
    //border-bottom: 3px solid ${p => p.theme.borderTransparent} ;
    background-color: ${p => p.theme.backgroundPrimary} ;

    display: flex;
    gap: 8px;
    padding: 16px;
    border-radius: 6px;
    background-color: ${props => props.theme.backgroundPrimary};
    flex-direction: column;
    box-shadow: 0 1px 4px 0 rgba(0, 8, 38, 0.04);
    border: 1px solid transparent;
    transition: 200ms;
    
    padding: 24px;
    position: relative;
    min-width: 300px;
    width: 100%;
    min-height: 50px;
`;

const Box = ({ children }: any) => {
    return <Container >
        {children}
    </Container>;
}

export default Box;