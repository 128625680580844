import { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import TextEditor, { useTextEditor } from '../../components/TextEditor';
import Icon from '../../elements/Icon';
import Loading from '../../elements/Loading';
import DocumentAside from './components/Aside';
import { useDocumentEntity } from './hooks/useDocumentEntity';

const Container = styled.div`

    padding: 20px 0;


    >header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;

        > .left, .right{
            align-items: center;
            display: flex;
            gap: 6px;
        }
        > .left{
            align-items: flex-start;
            flex-direction: column;
            gap: 0px;
        }
    }

    > main {
        position: relative;
        display: flex;
        gap: 20px;

        > section {

            flex: 2;

            > .header{
                display: inline-flex;
                align-items: center;
                gap: 6px;
                padding: 10px 0;
            }
            > .document {

                border-radius: 8px;
                border: 1px solid ${props => props.theme.borderTransparent};
                box-shadow: 0px 0px 30px 0px #0001;
                min-height: 200px;
                background-color: ${props => props.theme.backgroundPrimary};
                > .editor {
                    padding: 20px;
                }

                > .tools {

                    border-bottom: 1px solid ${p => p.theme.borderOpaque};
                    padding: 6px;

                    >.left{
                        display: flex;

                        > button {
                            width: 26px;
                            height: 26px;
                            padding: 4px;
                            cursor: pointer;

                            &:hover{
                                background-color: ${p => p.theme.backgroundSecondary};
                            }

                            &.active {
                                background-color: ${p => p.theme.backgroundTertiary};
                            }
                        }
                    }
                }
            }
        }
    }
    .title {
        font-size: 20px;
        font-weight: 900;
    }
      
`;




const DocumentEditPage = () => {

    const ref = useRef<any>(null);
    const { id } = useParams();

    const entity = useDocumentEntity(id);

    const editor = useTextEditor({
        editable: true, content: entity.data?.content || [
            { type: "paragraph", content: [{ type: "text", text: " " }] }
        ]
    });



    const ToolButton = useCallback(({ tag, action, icon }: any) => {
        if (editor?.isActive(tag))
            return <button className='tool-button active' onClick={action}>
                <Icon name={icon} size={18} />
            </button>

        return <button className='tool-button' onClick={action}>
            <Icon name={icon} size={18} color="contentSecondary" />
        </button>

    }, [editor]);



    if (entity.loading)
        return <Loading />

    if (!entity.data)
        return <></>;

    return <Container>
        <header>
            <div className='left'>
                <input className="title" value={entity.data.title} />
            </div>
            <div className='right'>


            </div>
        </header>
        <main>
            <section>

                <div className='document'>
                    {editor && <div className='tools'>
                        <div className='left'>
                            <ToolButton tag="bold" icon="bold" action={() => editor.chain().focus().toggleBold().run()} />
                            <ToolButton tag="italic" icon="italic" action={() => editor.chain().focus().toggleItalic().run()} />
                            <ToolButton tag="underline" icon="underline" action={() => editor.chain().focus().toggleUnderline().run()} />
                            <ToolButton tag="strike" icon="strike" action={() => editor.chain().focus().toggleStrike().run()} />
                            <ToolButton tag="quote" icon="quote" action={() => editor.chain().focus().toggleBlockquote().run()} />
                            <ToolButton tag="code" icon="code" action={() => editor.chain().focus().toggleCode().run()} />
                            <ToolButton tag="link" icon="link" />
                            <ToolButton tag="bulletList" icon="list-bulleted" action={() => editor.chain().focus().toggleBulletList().run()} />
                            <ToolButton tag="orderedList" icon="list-numbered" action={() => editor.chain().focus().toggleOrderedList().run()} />

                            {/* <ToolButton tag="img" icon="no_image" action={() => editor?.commands.setImage({
                                src: "https://st4.depositphotos.com/14431644/22076/i/450/depositphotos_220767694-stock-photo-handwriting-text-writing-example-concept.jpg"
                            })} />

                            <div style={{ position: 'relative' }}>
                                <input onInput={handleFileInput} type='file' style={{ opacity: 0, zIndex: 2, position: "absolute", width: 32, height: 32 }} />
                                <ToolButton
                                    icon="attach"
                                    tag="file"
                                />
                            </div> */}

                        </div>
                    </div>}

                    <div className='editor'>
                        {editor && <TextEditor editor={editor} />}
                    </div>
                </div>

            </section>

            <DocumentAside
                // sender={data.sender}
                // receivers={data.receivers}
                // labels={data.labels}
                // actions={{ lstLabelsOnInput: entity.actions.lstLabelsOnInput }}
                {...entity.data}
            />
        </main>
    </Container>;
}



export default DocumentEditPage;