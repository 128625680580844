import { Route, Routes } from 'react-router-dom';
import { useEntityTable } from '../../hooks/useEntity';
import RoleForm from './form';
import RoleGrid from './grid';

const RolePage = ({ }: any) => {

    const Entity = useEntityTable("users");

    return <Routes>
        <Route index element={<RoleGrid Entity={Entity} />} />
        <Route path=":id" element={<RoleForm EntityTable={Entity} />} />
    </Routes>


}

export default RolePage;