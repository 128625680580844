
import styled from 'styled-components';
import PeopleCard from '../../../components/PeopleCard';
import SelectionButton from '../../../components/SelectionButton';
import Heading from '../../../elements/Heading';
import Separator from '../../../elements/Separator';
import LabelCard from '../../labels/components/LabelCard';
import DocumentServiceCard from './DocumentServiceCard';
import ReceiversList from './ReceiverList';

const Container = styled.aside`
    flex: 1;
    > .content {
        top: 20px;
        position: sticky;
    
    }
`;


const DocumentAside = ({ labels, sender, receivers, actions, service, ...data }: any) => {

    return <Container>


        <div className='content'>

            <DocumentServiceCard service={service} />
            <Separator />
            <Heading size={4}>Solicitante</Heading>
            {sender && <PeopleCard data={sender} />}
            <Separator />
            <Heading size={4}>Marcadores</Heading>

            {labels?.map(({ label }: any) => <LabelCard data={label} />)}
            <SelectionButton
                key="dasdasd"
                url="/labels"
                keyPath='labelId'
                keyObject='label'
                labelKey='name'
                value={labels}
                onInput={actions?.lstLabelsOnInput}
            />
            <Separator />
            <Heading size={4}>Destinatários</Heading>
            <ReceiversList receivers={receivers} />

        </div>
    </Container>;
}


export default DocumentAside;