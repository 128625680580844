import { AxiosInstance } from "axios";
import { createContext, useContext, useState } from "react";
import { useApi } from "./useAuth";
// import { useAlert } from "./useAlert";
// import { getExpoToken } from "../services/notifications"; //ONLY MOBILE

export interface ContextProps {
    entities: { [entity: string]: IEntityStore }
    initialize: any
    entity: any
    initializeSingle: any
};

interface IEntity {
    id: string
}


interface IEntityStore {
    count: number
    page: number
    data: IEntity[]
}


const Context = createContext<ContextProps>({
    entities: {},
    entity: {},
    initialize: null,
    initializeSingle: null
});

type IEntityBaseConstructor<T> = {
    table?: any
    initialData: T
    name: string
    id?: string
    api: AxiosInstance
    setState?: React.Dispatch<any>
}

class EntityBase<T> {

    private api: AxiosInstance;
    private EntityTable?: IEntityTable;
    private name: string

    public setState?: React.Dispatch<any>

    id?: string;
    data: T;

    private deleted = false
    private updated = false
    private created = false;

    constructor({
        table,
        initialData,
        id,
        api,
        name,
        setState
    }: IEntityBaseConstructor<T>) {
        this.EntityTable = table;
        this.data = initialData;
        this.api = api;
        this.name = name;
        if (setState)
            this.setState = setState;
        if (id)
            this.id = id;
        if (id == "novo")
            this.created = true;
    }

    private UpdateState() {
        // if (this.setState)
        //     this.setState(this);
        if (this.EntityTable)
            this.EntityTable.UpdateState(this);
    }

    public async Get() {

        const res = await this.api.get<any>(this.name + "/" + this.id);
        this.data = res.data;
        this.UpdateState();

    }


    public SetData(newData: any) {

        this.data = { ...newData }

        if (!this.created)
            this.updated = true;

        this.UpdateState();

        return this;

    }

    public Update(newData: any) {

        this.data = { ...this.data, ...newData }

        if (!this.created)
            this.updated = true;

        this.UpdateState();



        return this;

    }

    public Delete() {

        this.deleted = true;
        return this;

    }

    public Create(initialData: any = {}) {
        this.data = { ...initialData, id: "novo" };
        this.created = true;
        this.UpdateState();
        return this;
    }

    public async Save() {
        if (this.updated) {
            console.log("Atualizando...")
            const res = await this.api.put(this.name + "/" + this.id, this.data);
        } else if (this.deleted) {
            console.log("Deletando...")
            const res = await this.api.delete(this.name + "/" + this.id);
            this.id = undefined;
        }
        else if (this.created) {
            console.log("Criando...")
            const res = await this.api.post(this.name, this.data);
            this.data = res.data;
            this.UpdateState();
        }

        if (this.EntityTable) {
            this.EntityTable.Refresh();
        }

    }

}

export type IEntityBaseClass<T> = InstanceType<typeof EntityBase<T>>;

class EntityTable {

    setState: React.Dispatch<any>
    api: AxiosInstance

    count = 0;
    page = 1;
    take = 20;
    data: EntityBase<any>[] = []
    name: string = ""

    constructor(entity: string, api: any, setState: any) {
        this.name = entity;
        this.api = api;
        this.setState = setState
    }

    public UpdateState() {
        this.setState((s: any) => ({ ...s, [this.name]: this }));
    }

    public async GetMany() {
        const res = await this.api.get<any[]>(this.name);
        this.data = res.data.map(d => new EntityBase({
            table: this,
            initialData: d,
            id: d.id,
            api: this.api,
            name: this.name,
        }));
        this.UpdateState();
    }

    public async Get(id: string) {

        const res = await this.api.get<any>(this.name + "/" + id);
        this.data = [new EntityBase({
            table: this,
            initialData: res.data,
            id: res.data.id,
            api: this.api,
            name: this.name,
        }), ...this.data];
        this.UpdateState();

    }


    // public async Get(id:string) {
    //     const res = await this.api.get<any[]>(this.name);
    //     this.data = res.data.map(d => new EntityBase({
    //         table: this,
    //         initialData: d,
    //         id: d.id,
    //         api: this.api,
    //         name: this.name,
    //     }));
    //     this.UpdateState();
    // }

    public Refresh() {
        this.GetMany();
    }

    public GetEntity(id: string) {
        const find = this.data.find(s => s.id == id);
        return find;
    }

    // protected Update(newData: IEntity) {

    //     if (!newData.id)
    //         return;

    //     const find = this.data.find(s => s.id == newData.id);

    //     if (!find)
    //         return;

    //     const index = this.data.indexOf(find);
    //     this.data[index] = { ...find, ...newData };
    //     this.UpdateState()
    //     return this;
    // }

    // protected Delete(id: string) {
    //     const find = this.data.find(s => s.id == id);
    //     if (!find) return;

    //     const index = this.data.indexOf(find);
    //     delete this.data[index];
    //     this.UpdateState()
    //     return this;
    // }

    public Create(initialData: any = {}) {
        const createdEntity = new EntityBase<any>({
            table: this,
            id: "novo",
            initialData,
            api: this.api,
            name: this.name
        });
        this.data.push(createdEntity)
        this.UpdateState();
        return createdEntity;
    }



}

export type IEntityTableClass = InstanceType<typeof EntityTable>;

const UseEntityProvider = ({ children }: any) => {

    const [entities, setEntities] = useState<any>({});
    const [entity, setEntity] = useState<any>({});
    const api = useApi();


    const initialize = (entity: string) => {

        if (!entities[entity])
            setEntities({
                [entity]: new EntityTable(entity, api, setEntities)
            })

        return entities[entity];
    }

    const initializeSingle = (entityName: string, { id, table }: any) => {

        const key = entity + "-" + id;

        if (!entity[key])
            setEntity({
                [key]: new EntityBase({
                    api,
                    initialData: {},
                    name: entityName,
                    id,
                    setState: setEntity,
                    table
                })
            })

        return entity[key];
    }


    return (
        <Context.Provider value={{ entities, entity, initialize, initializeSingle }}>
            {children}
        </Context.Provider>
    )

}

export interface IEntityTable {
    Create: any
    GetEntity: any
    entity?: IEntityBaseClass<any>
    Get: any
    GetMany: any
    Refresh: any
    UpdateState: any
    data: any[]
}

export interface IEntityBase {
    Create: any
    Get: any
    UpdateState: any
    data: any
}

export function useEntityTable(name: string) {

    const [state, setState] = useState<EntityBase<any>[]>([]);
    const [entity, setEntity] = useState<EntityBase<any>>();
    const api = useApi();

    const [data, setData] = useState({
        count: 0,
        page: 1,
        take: 20
    })

    const UpdateState = (entity: IEntityBaseClass<any>) => {
        setState((s) => {
            s.forEach(e => {
                if (e.id == entity.id)
                    return entity

                return e;
            })
            return [...s];
        })
    }

    async function GetMany() {
        const res = await api.get<any[]>(name);
        setState(res.data.map(d => new EntityBase({
            table: ({ Refresh, UpdateState }),
            initialData: d,
            id: d.id,
            api,
            name,
        })));
    }

    // async function Get(id: string) {
    //     const res = await api.get<any>(name + "/" + id);
    //     setState((s) => {
    //         s.unshift(new EntityBase<any>({
    //             table: ({ Refresh, UpdateState }),
    //             initialData: res.data,
    //             id: res.data.id,
    //             api,
    //             name,
    //         }))
    //         return [...s]
    //     })
    // }

    async function Get(id: string) {
        const res = await api.get<any>(name + "/" + id);
        setEntity(new EntityBase<any>({
            table: ({ Refresh, UpdateState: (e: any) => setEntity(e) }),
            initialData: res.data,
            id,
            api,
            name,
        }));
    }


    function Refresh() {
        GetMany();
    }

    function GetEntity(id: string) {
        const find = state.find(s => s.id == id);
        return find;
    }

    function Create(initialData: any = {}) {
        const createdEntity = new EntityBase<any>({
            table: ({ Refresh, UpdateState }),
            initialData,
            id: "novo",
            api,
            name,
        });
        setState((s) => {
            s.unshift(createdEntity)
            return [...s]
        })
        return createdEntity;
    }

    const EntityTable: IEntityTable = {
        Create, GetEntity, Get, GetMany, Refresh, data: state, UpdateState, entity
    }

    return EntityTable;

    // const { initialize } = useContext(Context);
    // const res: EntityTable = initialize(entity);
    // return res;
}

// export function useEntity(name: string) {

//     const [state, setState] = useState<any>();
//     const api = useApi();


//     const UpdateState = (entity: IEntityBaseClass<any>) => {
//         setState(entity);
//     }

//     async function Get(id: string) {
//         const res = await api.get<any>(name + "/" + id);
//         setState(res.data);
//         // setState(new EntityBase<any>({
//         //     table: ({ UpdateState }),
//         //     initialData: res.data,
//         //     id: res.data.id,
//         //     api,
//         //     name,
//         // }))
//     }



//     function Create(initialData: any = {}) {
//         setState(initialData);
//         // const createdEntity = new EntityBase<any>({
//         //     table: ({ UpdateState }),
//         //     initialData,
//         //     id: "novo",
//         //     api,
//         //     name,
//         // });
//         // setState(createdEntity)
//         return initialData;
//     }

//     const EntityTable: IEntityBase = {
//         Create, Get, data: state, UpdateState
//     }

//     return EntityTable;

//     // const { initialize } = useContext(Context);
//     // const res: EntityTable = initialize(entity);
//     // return res;
// }

// export function useEntity(name: string) {

//     const Entities = useEntityTable();
//     return Entities.data[0];

//     // const { initialize } = useContext(Context);
//     // const res: EntityTable = initialize(entity);
//     // return res;
// }

export function useSingleEntity(entity: string, props: { id?: string, table?: any } = {}) {

    const { initializeSingle } = useContext(Context);
    const res: EntityBase<any> = initializeSingle(entity, { id: props.id, table: props.table });
    return res;

}


export default UseEntityProvider